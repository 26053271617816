export default [
  {
    path: 'action-plans',
    name: 'action-plans',
    component: () => import('@/modules/education/action-plans/index.vue')
  },
  {
    path: 'webinars',
    name: 'webinars',
    component: () => import('@/modules/education/materials/index.vue')
  },
  {
    path: 'qa-sessions',
    name: 'qa-sessions',
    component: () => import('@/modules/education/materials/index.vue')
  },
  {
    path: 'master-classes',
    name: 'master-classes',
    component: () => import('@/modules/education/materials/index.vue')
  },
  {
    path: 'bonuses',
    name: 'bonuses',
    component: () => import('@/modules/education/materials/index.vue')
  },
  {
    path: 'streams',
    name: 'streams',
    component: () => import('@/modules/education/streams/pages/index.vue')
  },
  {
    path: 'tariffs',
    name: 'tariffs',
    component: () => import('@/modules/education/tariffs/pages/index.vue')
  }
]
