import Vue from 'vue'
import { $http } from '@/utils/https'
import { map } from 'lodash';

import {
  ADD_LIST_ITEM,
  CHANGE_LIST_LOADING,
  CHANGE_LOADING,
  CHANGE_SKIP, REMOVE_LIST_ITEM,
  REPLACE_LIST_ITEM,
  SET_LIST,
  SET_LIST_LENGTH
} from './types/mutation-types';

import { CREATE, DELETE, GET_LIST, UPDATE } from './types/action-types';

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: [],
  isLoading: false,
  isListLoading: false,
  limit: 10,
  skip: 0,
  listLength: 0,

  checkList: [],
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  skip: state => state.skip,

  checkList: checkList => state.checkList
}

const actions = {
  [GET_LIST]: async ({ commit }) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}course-types?include=translations&limit=${state.limit}&skip=${state.skip}`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}course-types/${payload.get('id')}?include=translations`, payload)
      commit(REPLACE_LIST_ITEM, response.data.data)
      dispatch(GET_LIST)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}course-types?include=translations`, payload)
      commit(ADD_LIST_ITEM, response.data.data)
      dispatch(GET_LIST)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },

  DELETE: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      let resp = await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}course-types/${payload.id}`)
      commit('REMOVE_LIST_ITEM', payload);
      dispatch(GET_LIST);

      return resp;
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },

  CHECK_ITEM_IN_COURSES: async ({ commit }, id) => {
    try {
      let response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}course-types/${id}/courses`);
      if (response.data.data.length > 0) {
        let resultList = map(response.data.data, item => {
          return {
            name: item.name,
            slug: item.slug
          }
        });
        commit('ADD_TO_CHECK_LIST', resultList);
      }

      return response.data.data;
    } catch (e) {
      throw e;
    } finally {
    }
  },
}

const mutations = {
  [SET_LIST](state, list) {
    state.list = list
  },
  [REPLACE_LIST_ITEM](state, data) {
    const index = state.list.findIndex(e => e.id === data.id)
    Vue.set(state.list, index, data)
  },
  [ADD_LIST_ITEM](state, data) {
    state.list.unshift(data)
  },
  [CHANGE_LOADING](state, status) {
    state.isLoading = status
  },
  [CHANGE_LIST_LOADING](state, status) {
    state.isListLoading = status
  },
  [SET_LIST_LENGTH](state, length) {
    state.listLength = length
  },
  [CHANGE_SKIP](state, count) {
    state.skip = count
  },

  REMOVE_LIST_ITEM(state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  },

  ADD_TO_CHECK_LIST(state, checkList) {
    state.checkList = state.checkList.concat(checkList)

  },
  RESET_CHECK_LIST(state) {
    state.checkList = []
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}
