import Vue from 'vue'
import { $http } from '@/utils/https'
import {
  GENERAL_CHANGE_CATEGORY_LOADING,
  GENERAL_CHANGE_TAG_LOADING,
  GENERAL_CREATE_DATA_LOADING,
  GENERAL_GET_DATA_LOADING,
  GENERAL_REMOVE_CATEGORY_TO_LIST,
  GENERAL_REMOVE_TAG_TO_LIST,
  GENERAL_SET_ADDED_CATEGORY,
  GENERAL_SET_ADDED_TAG,
  GENERAL_SET_DATA,
  GENERAL_UPDATE_DATA_LOADING,
  UPDATE_IMAGE_LOADING,
  FRESH_IMAGE
} from './types/mutation-types'

import {
  GENERAL_ADD_CATEGORY,
  GENERAL_ADD_TAG,
  GENERAL_CREATE_DATA,
  GENERAL_GET_DATA,
  GENERAL_UPDATE_DATA,
  GENERAL_REMOVE_CATEGORY,
  GENERAL_REMOVE_TAG,
  UPDATE_IMAGE
} from './types/action-types'

import { selectedModule } from '@/mixins'

const state = {
  newsGeneral: null,
  newsGeneralLoading: false,
  newsGeneralCreateLoading: false,
  newsGeneralUpdateLoading: false,
  changeTagsLoading: false,
  changeCategoriesLoading: false,
  updateImageLoading: false
}

const getters = {
  newsGeneral: state => {
    if (state.newsGeneral) {
      return {
        id: state.newsGeneral.id,
        picture: state.newsGeneral.picture,
        cover: state.newsGeneral.cover,
        country: state.newsGeneral.country_id,
        slug: state.newsGeneral.slug,
        date: state.newsGeneral.published_at,
        pinned: state.newsGeneral.pinned,
        locale: state.newsGeneral.default_locale,
        country_id: state.newsGeneral.country_id,
        name: state.newsGeneral.name,
        // eslint-disable-next-line no-prototype-builtins
        type: state.newsGeneral.hasOwnProperty('categories') ? state.newsGeneral.categories.data : '',
        status: state.newsGeneral.status,
        // eslint-disable-next-line no-prototype-builtins
        tags: state.newsGeneral.hasOwnProperty('tags') ? state.newsGeneral.tags.data : '',
        // eslint-disable-next-line no-prototype-builtins
        categories: state.newsGeneral.hasOwnProperty('categories') ? state.newsGeneral.categories.data : ''
      }
    }
  },
  newsGeneralLoading: state => state.newsGeneralLoading,
  newsGeneralCreateLoading: state => state.newsGeneralCreateLoading,
  changeTagsLoading: state => state.changeTagsLoading,
  changeCategoriesLoading: state => state.changeCategoriesLoading,
  newsGeneralUpdateLoading: state => state.newsGeneralUpdateLoading,
  updateImageLoading: state => state.updateImageLoading
}

const actions = {
  [GENERAL_GET_DATA]: async ({ commit }, payload) => {
    try {
      commit(GENERAL_GET_DATA_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_NEWS}news/${payload.id}?include=categories,tags,picture,picture.seo,cover,cover.seo}`)
      commit(GENERAL_SET_DATA, response.data.data)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(GENERAL_GET_DATA_LOADING, false)
    }
  },
  [GENERAL_UPDATE_DATA]: async ({ commit }, payload) => {
    try {
      commit(GENERAL_UPDATE_DATA_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_NEWS}news/${payload.id}?include=categories,tags,picture,picture.seo,cover,cover.seo}`, payload)
      commit(GENERAL_SET_DATA, response.data.data)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(GENERAL_UPDATE_DATA_LOADING, false)
    }
  },
  [GENERAL_CREATE_DATA]: async ({ commit }, payload) => {
    try {
      commit(GENERAL_CREATE_DATA_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_NEWS}news?include=picture,picture.seo,cover,cover.seo`, payload)
      commit(GENERAL_SET_DATA, response.data.data)
      return response.data.data
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(GENERAL_CREATE_DATA_LOADING, false)
    }
  },
  [GENERAL_ADD_TAG]: async ({ commit }, payload) => {
    try {
      commit(GENERAL_CHANGE_TAG_LOADING, true)

      await $http.post(`${process.env.VUE_APP_ROOT_NEWS}news/${payload.newsId}/tag/${payload.id}`)
      commit(GENERAL_SET_ADDED_TAG, payload.item)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(GENERAL_CHANGE_TAG_LOADING, false)
    }
  },
  [GENERAL_REMOVE_TAG]: async ({ commit }, payload) => {
    try {
      commit(GENERAL_CHANGE_TAG_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_NEWS}news/${payload.newsId}/tag/${payload.id}`)
      commit(GENERAL_REMOVE_TAG_TO_LIST, payload)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(GENERAL_CHANGE_TAG_LOADING, false)
    }
  },
  [GENERAL_ADD_CATEGORY]: async ({ commit }, payload) => {
    try {
      commit(GENERAL_CHANGE_CATEGORY_LOADING, true)

      await $http.post(`${process.env.VUE_APP_ROOT_NEWS}news/${payload.newsId}/category/${payload.id}`)
      commit(GENERAL_SET_ADDED_CATEGORY, payload.item)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(GENERAL_CHANGE_CATEGORY_LOADING, false)
    }
  },
  [GENERAL_REMOVE_CATEGORY]: async ({ commit }, payload) => {
    try {
      commit(GENERAL_CHANGE_CATEGORY_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_NEWS}news/${payload.newsId}/category/${payload.id}`)
      commit(GENERAL_REMOVE_CATEGORY_TO_LIST, payload)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(GENERAL_CHANGE_CATEGORY_LOADING, false)
    }
  },
  [UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_NEWS}news/${payload.get('news_id')}/images/${payload.get('id')}?include=seo`, payload)
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_NEWS}news/${payload.get('news_id')}/images?include=seo`, payload)
      }
      response.data.data.type_id = parseInt(payload.get('type_id'))
      commit(FRESH_IMAGE, response.data.data)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_IMAGE_LOADING, false)
    }
  }
}

const mutations = {
  [GENERAL_SET_DATA] (state, data) {
    state.newsGeneral = data
  },
  [GENERAL_GET_DATA_LOADING] (state, status) {
    state.newsGeneralLoading = status
  },
  [GENERAL_SET_ADDED_TAG] (state, tag) {
    state.newsGeneral.tags.data.push(tag)
  },
  [GENERAL_REMOVE_TAG_TO_LIST] (state, tag) {
    const index = state.newsGeneral.tags.data.findIndex(e => e.id === tag.id)
    Vue.delete(state.newsGeneral.tags.data, index)
  },
  [GENERAL_CHANGE_TAG_LOADING] (state, status) {
    state.changeTagsLoading = status
  },
  [GENERAL_SET_ADDED_CATEGORY] (state, category) {
    state.newsGeneral.categories.data.push(category)
  },
  [GENERAL_REMOVE_CATEGORY_TO_LIST] (state, category) {
    const index = state.newsGeneral.categories.data.findIndex(e => e.id === category.id)
    Vue.delete(state.newsGeneral.categories.data, index)
  },
  [GENERAL_CHANGE_CATEGORY_LOADING] (state, status) {
    state.changeCategoriesLoading = status
  },
  [GENERAL_UPDATE_DATA_LOADING] (state, status) {
    state.newsGeneralUpdateLoading = status
  },
  [GENERAL_CREATE_DATA_LOADING] (state, status) {
    state.newsGeneralCreateLoading = status
  },
  [UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [FRESH_IMAGE] (state, data) {
    if (data.type_id === 2) {
      state.newsGeneral.picture.data = data
    } else {
      state.newsGeneral.cover.data = data
    }
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
