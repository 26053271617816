import Vue from 'vue'
import { $http } from '@/utils/https'
import {
  ADD_LIST_ITEM,
  CHANGE_LIST_LOADING,
  CHANGE_LOADING,
  REMOVE_LIST_ITEM,
  REPLACE_LIST_ITEM,
  SET_LIST,
  SET_COURSE_SKILLS
} from './types/mutation-types'

import { CREATE, DELETE, GET_LIST, UPDATE } from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  courseSkills: [],
  list: [],
  isLoading: false,
  isListLoading: false
}

const getters = {
  courseSkills: state => camelcaseObjectDeep(state.courseSkills),
  list: state => camelcaseObjectDeep(state.list),
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}skills?include=&filter[keywords]=${payload.keywords}`)

      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/skills/${payload.id}?include=skill`, payload)
      commit(REPLACE_LIST_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/skills?include=skill`, payload)
      commit(ADD_LIST_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/skills/${payload.id}`)
      commit(REMOVE_LIST_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [REPLACE_LIST_ITEM] (state, data) {
    const index = state.courseSkills.findIndex(e => e.id === data.id)
    Vue.set(state.courseSkills, index, data)
  },
  [ADD_LIST_ITEM] (state, data) {
    state.courseSkills.unshift(data)
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [SET_COURSE_SKILLS] (state, list = []) {
    state.courseSkills = list
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [REMOVE_LIST_ITEM] (state, params) {
    const index = state.courseSkills.findIndex(e => e.id === params.id)
    Vue.delete(state.courseSkills, index)
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
