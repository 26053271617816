export const GET_LIST = 'GET_LIST'
export const DELETE = 'DELETE'
export const UPDATE = 'UPDATE'
export const CREATE = 'CREATE'
export const GET_ITEM = 'GET_ITEM'
export const UPDATE_SORT = 'UPDATE_SORT'
export const GET_CONTENT = 'GET_CONTENT'
export const GET_SEO = 'GET_SEO'
export const UPDATE_SEO = 'UPDATE_SEO'
export const GET_LIST_ITEM = 'GET_LIST_ITEM'
export const GET_CURRENT_EDUCATION = 'GET_CURRENT_EDUCATION'
export const CHECK_EDUCATION_LIST_ITEM = 'CHECK_EDUCATION_LIST_ITEM'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'
export const GET_SETTINGS = 'GET_SETTINGS'
export const CREATE_CONTENT_FILE = 'CREATE_CONTENT_FILE'
export const GET_LIST_MATERIALS = 'GET_LIST_MATERIALS'
export const UPDATE_CONTENT_FILE = 'UPDATE_CONTENT_FILE'
export const DELETE_CONTENT_FILE = 'DELETE_CONTENT_FILE'
export const SET_COURSES_LIST_LOADING = 'SET_COURSES_LIST_LOADING'
export const SET_COURSES_LIST = 'SET_COURSES_LIST'
export const COPY = 'COPY'
export const UPDATE_IMAGE_LOADING = 'UPDATE_IMAGE_LOADING'
export const FRESH_IMAGE = 'FRESH_IMAGE'
