export const CHANGE_LIST_LOADING = 'CHANGE_LIST_LOADING'
export const GET_COURSE_LOADING = 'GET_COURSE_LOADING'
export const SET_GRADUATE_WORK = 'SET_GRADUATE_WORK'
export const SET_HOME_WORKS = 'SET_HOME_WORKS'
export const SET_LIST = 'SET_LIST'
export const SET_COURSE = 'SET_COURSE'
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const CHANGE_SHOW_HOME_WORK_DIALOG_LESSON = 'CHANGE_SHOW_HOME_WORK_DIALOG_LESSON'
export const CHANGE_SKIP = 'CHANGE_SKIP'
export const CHANGE_SORT = 'CHANGE_SORT'
export const REPLACE_CANCELED_ITEM_FROM_LIST = 'REPLACE_CANCELED_ITEM_FROM_LIST'
export const REPLACE_LIST_ITEM = 'REPLACE_LIST_ITEM'
export const SET_COURSE_LIST = 'SET_COURSE_LIST'
export const SET_ITEM = 'SET_ITEM'
export const SET_COURSE_MATERIALS = 'SET_COURSE_MATERIALS'
