import { CHANGE_ERROR, FIX_BODY } from '@/store/mutation-types'

const state = {
  //List of constants
  KEY_SELECT_ALL: 'select_all',

  POSITION_TYPE_AUTHORS: 1,
  POSITION_TYPE_SPEAKERS: 2,

  fixBody: false,
  error: {
    id: Date.now(),
    show: false,
    message: ''
  }
}

const getters = {
  fixBody: state => state.fixBody,
  error: state => state.error,
}

const mutations = {
  [FIX_BODY] (state, param) {
    const body = document.querySelector('body')
    if (param) {
      body.classList.add('fix')
    } else {
      body.classList.remove('fix')
    }
    state.fixBody = param
  },
  [CHANGE_ERROR] (state, { message, show }) {
    state.error.id = Date.now()
    state.error.show = true
    state.error.message = message
    this._vm.$toasted.error(message)
    setTimeout(() => {
      state.error.show = false
    }, 3000)
  }
}

export default {
  state,
  getters,
  mutations,
}
