import Vue from 'vue'
import { $http } from '@/utils/https'
import {
    CHANGE_LIST_LOADING,
    CHANGE_LOADING,
    CHANGE_SKIP,
    SET_ITEM,
    SET_LIST,
    SET_LIST_LENGTH
} from './types/mutation-types'

import { GET_LIST } from './types/action-types'
import { CHANGE_LIST_FILTER } from '@/store/modules/tests/types/mutation-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
    list            : [],
    isLoading       : false,
    isListLoading   : false,
    listLength      : 0
}

const getters = {
    list                : state => camelcaseObjectDeep(state.list),
    isLoading           : state => state.isLoading,
    isListLoading       : state => state.isListLoading,
    listLength          : state => state.listLength
}

const actions = {
    [GET_LIST]: async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LIST_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_PAYMENTS}merchants/data`, payload)
            
            commit(SET_LIST         , response.data.data)
            commit(SET_LIST_LENGTH  , response.data.meta.total)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LIST_LOADING, false)
        }
    }
}

const mutations = {
    [SET_LIST] (state, list) {
        state.list = list
    },
    [CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
    [CHANGE_LIST_LOADING] (state, status) {
        state.isListLoading = status
    },
    [SET_LIST_LENGTH] (state, length) {
        state.listLength = length
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
