import Vue from 'vue'
import { $http } from '@/utils/https'
import {
    ADD_LIST_ITEM,
    ADD_TRANSLATION_ITEM,
    CHANGE_DIALOG_VIEW,
    CHANGE_EDIT_ID,
    CHANGE_LIST_LOADING,
    CHANGE_LOADING,
    CHANGE_SKIP,
    FRESH_TRANSLATION_ITEM,
    REMOVE_LIST_ITEM,
    REPLACE_LIST_ITEM,
    SET_ITEM,
    SET_LIST,
    SET_LIST_LENGTH
} from './types/mutation-types'

import { CREATE, DELETE, GET_LIST, GET_LIST_ITEM, UPDATE } from './types/action-types'
import { CHANGE_LIST_FILTER } from '@/store/modules/tests/types/mutation-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
    list            : [],
    isLoading       : false,
    isListLoading   : false,
    currentItem     : null,
    limit           : 100,
    skip            : 0,
    listLength      : 0,
    openDialogView  : false,
    editId          : '',
    filterKeywords  : ''
}

const getters = {
    list                : state => camelcaseObjectDeep(state.list),
    isLoading           : state => state.isLoading,
    currentItem         : state => camelcaseObjectDeep(state.currentItem),
    isListLoading       : state => state.isListLoading,
    listLength          : state => state.listLength,
    limit               : state => state.limit,
    skip                : state => state.skip,
    openDialogView      : state => state.openDialogView,
    editId              : state => state.editId,
    filterKeywords      : state => state.filterKeywords
}

const actions = {
    [GET_LIST]: async ({ commit }) => {
        try {
            commit(CHANGE_LIST_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_SETTING}getaway-providers?filters[keywords]=${state.filterKeywords || ''}&limit=${state.limit}&skip=${state.skip}`)
            
            commit(SET_LIST         , response.data.data)
            commit(SET_LIST_LENGTH  , response.data.meta.total)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LIST_LOADING, false)
        }
    },
    [GET_LIST_ITEM]: async ({ commit }) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_SETTING}getaway-providers/${state.editId}`)
            
            commit(SET_ITEM, response.data.data)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [UPDATE]: async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_SETTING}getaway-providers/${state.currentItem.id}`, payload)
            
            commit(REPLACE_LIST_ITEM, response.data.data)
            commit(SET_ITEM         , response.data.data)
            
            dispatch(GET_LIST)
            
            return response.data.data
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [CREATE]: async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_SETTING}getaway-providers`, payload)
            
            commit(ADD_LIST_ITEM, response.data.data)
            commit(SET_ITEM     , response.data.data)
            
            dispatch(GET_LIST)
            
            return response.data.data
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [DELETE]: async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.delete(`${process.env.VUE_APP_ROOT_SETTING}getaway-providers/${payload.id}`)
            
            commit(REMOVE_LIST_ITEM, payload)
            
            return response.data.data
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    }
}

const mutations = {
    [SET_LIST] (state, list) {
        state.list = list
    },
    [SET_ITEM] (state, data) {
        state.currentItem = data
    },
    [REPLACE_LIST_ITEM] (state, data) {
        const index = state.list.findIndex(e => e.id === data.id)
        
        Vue.set(state.list, index, data)
    },
    [ADD_LIST_ITEM] (state, data) {
        state.list.unshift(data)
    },
    [CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
    [CHANGE_LIST_FILTER] (state, params) {
        switch (params.type) {
            default: {
                state.filterKeywords = params.value
            }
        }
    },
    [CHANGE_LIST_LOADING] (state, status) {
        state.isListLoading = status
    },
    [SET_LIST_LENGTH] (state, length) {
        state.listLength = length
    },
    [CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [CHANGE_DIALOG_VIEW] (state, status) {
        state.openDialogView = status
    },
    [CHANGE_EDIT_ID] (state, id) {
        state.editId = id
    },
    [REMOVE_LIST_ITEM] (state, data) {
        const index = state.list.findIndex(e => e.id === data.id)
        
        Vue.delete(state.list, index)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
