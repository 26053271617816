import { $http } from '@/utils/https'
import {
  SET_COMMENTS,
  SET_COMMENT,
  REMOVE_COMMENT,
  UPDATE_SET_COMMENT
} from './types/mutation-types'
import {
  GET_COMMENTS_HOME_WORK,
  GET_COMMENTS_GRADUATE_WORK,
  SEND_COMMENT_HOME_WORK,
  SEND_COMMENT_GRADUATE_WORK,
  UPDATE_COMMENT_HOME_WORK,
  UPDATE_COMMENT_GRADUATE_WORK,
  DELETE_COMMENT_HOME_WORK,
  DELETE_COMMENT_GRADUATE_WORK,
  CLEAR_COMMENTS_LIST,
} from './types/action-types'

const state = {
  comments: []
}

const getters = {
  comments: state => state.comments
}

const actions = {
  [GET_COMMENTS_HOME_WORK]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}users/home-works/${payload}/comments?include=user`)
      commit(SET_COMMENTS, response.data.data)
    } catch (e) {
      throw e
    } finally {}
  },
  [GET_COMMENTS_GRADUATE_WORK]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}users/graduate-works/${payload}/comments?include=user,type`)
      commit(SET_COMMENTS, response.data.data)
    } catch (e) {
      throw e
    } finally {}
  },
  [SEND_COMMENT_HOME_WORK]: async ({ commit }, payload) => {
    try {
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/home-works/${payload.workId}/comments?include=user`, payload.comment)
      commit(SET_COMMENT, response.data.data)
    } catch (e) {
      throw e
    } finally {}
  },
  [SEND_COMMENT_GRADUATE_WORK]: async ({ commit }, payload) => {
    try {
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/graduate-works/${payload.workId}/comments?include=user,type`, payload.comment)
      commit(SET_COMMENT, response.data.data)
    } catch (e) {
      throw e
    } finally {}
  },
  [UPDATE_COMMENT_HOME_WORK]: async ({ commit }, payload) => {
    try {
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/home-works/${payload.workId}/comments/${payload.id}`, {
        comment: payload.comment,
        '_method': 'patch'
      })
      commit(UPDATE_SET_COMMENT, response.data.data)
    } catch (e) {
      throw e
    } finally {}
  },
  [UPDATE_COMMENT_GRADUATE_WORK]: async ({ commit }, payload) => {
    try {
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/graduate-works/${payload.workId}/comments/${payload.id}`, {
        comment: payload.comment,
        '_method': 'patch'
      })
      commit(UPDATE_SET_COMMENT, response.data.data)
    } catch (e) {
      throw e
    } finally {}
  },
  [DELETE_COMMENT_HOME_WORK]: async ({ commit }, payload) => {
    try {
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/home-works/${payload.workId}/comments/${payload.id}`, {
        '_method': 'delete'
      })
      return response
    } catch (e) {
      throw e
    } finally {}
  },
  [DELETE_COMMENT_GRADUATE_WORK]: async ({ commit }, payload) => {
    try {
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/graduate-works/${payload.workId}/comments/${payload.id}`, {
        '_method': 'delete'
      })
      return response
    } catch (e) {
      throw e
    } finally {}
  },
  [CLEAR_COMMENTS_LIST]: async ({ commit }) => {
    commit(SET_COMMENTS, [])
  },
}

const mutations = {
  [SET_COMMENTS] (state, comments) {
    state.comments = comments
  },
  [SET_COMMENT] (state, comment) {
    state.comments.push(comment)
  },
  [UPDATE_SET_COMMENT] (state, comment) {
    state.comments = state.comments.map(item => item.id === comment.id ? { ...item, ...comment } : item)
  },
  [REMOVE_COMMENT] (state, comment) {
    state.comments.splice(comment, 1)
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
