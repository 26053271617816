import camelCaseKeys from "camelcase-object-deep";
import {
  COURSES_FORMS_DELETE_FORM,
  COURSES_FORMS_GET_LIST,
  COURSES_FORMS_SAVE_FORM
} from "@/store/modules/courses/types/action-types";
import {$http} from "@/utils/https";
import {GET_COURSE_FORMS_LOADING, SET_COURSE_FORMS} from "@/store/modules/courses/types/mutation-types";

const state = {
  list: [],
  listLoading: false,
}

const getters = {
  list: state => camelCaseKeys(state.list),
  ListLoading: state => state.listLoading,
}

const actions = {
  [COURSES_FORMS_DELETE_FORM]: async (context, {id}) => {
    return await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}course-forms/${id}`)
  },
  [COURSES_FORMS_SAVE_FORM]: async (context, payload) => {
    return await $http.post(`${process.env.VUE_APP_ROOT_COURSE}course-forms/`, payload)
  },
  [COURSES_FORMS_GET_LIST]: async ({ commit }, {id}) => {
    try {
      commit(GET_COURSE_FORMS_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}course-forms/${id}`, {})

      commit(SET_COURSE_FORMS, response.data.data)

      return camelCaseKeys(response.data.data);
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(GET_COURSE_FORMS_LOADING, false)
    }
  },
}

const mutations = {
  [SET_COURSE_FORMS] (state, list) {
    state.formsList = list
  },
  [GET_COURSE_FORMS_LOADING] (state, loading) {
    state.formsListLoading = loading
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
