import { $http } from '@/utils/https'
import { CHECK_SLUG_LOADING, SET_CHECKED_SLUG } from './types/mutation-types'

import { CHECK_SLUG } from './types/action-types'

import camelcaseObjectDeep from 'camelcase-object-deep'

const state = {
  slugChecking: null,
  isSlugCheckingLoading: false
}

const getters = {
  slugChecking: state => camelcaseObjectDeep(state.slugChecking),
  isSlugCheckingLoading: state => state.isSlugCheckingLoading
}

const actions = {
  [CHECK_SLUG]: async ({ commit }, payload) => {
    try {
      let response = null
      commit(CHECK_SLUG_LOADING, true)
      if (payload.service === 'course') {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}helpers/slug/check`, payload)
      } else if (payload.service === 'education') {
        response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}helpers/slug/check`, payload)
      } else if (payload.service === 'event') {
        response = await $http.post(`${process.env.VUE_APP_ROOT_EVENT}helpers/slug/check`, payload)
      } else if (payload.service === 'news') {
        response = await $http.post(`${process.env.VUE_APP_ROOT_NEWS}helpers/slug/check`, payload)
      }
      commit(SET_CHECKED_SLUG, response?.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHECK_SLUG_LOADING, false)
    }
  }
}

const mutations = {
  [SET_CHECKED_SLUG] (state, list) {
    state.slugChecking = list
  },
  [CHECK_SLUG_LOADING] (state, data) {
    state.isSlugCheckingLoading = data
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
