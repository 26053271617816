import Vue from 'vue'
import { $http } from '@/utils/https'
import { transformerSeo } from '@/transformers';
import {map} from 'lodash';

import {
  ADD_COUNTRY,
  ADD_LIST_ITEM,
  ADD_SEO_ITEM,
  ADD_TRANSLATION_ITEM,
  CHANGE_DIALOG_VIEW,
  CHANGE_EDIT_ID,
  CHANGE_LIST_LOADING,
  CHANGE_LOADING,
  CHANGE_SKIP,
  FRESH_COUNTRY,
  FRESH_SEO_ITEM,
  FRESH_TRANSLATION_ITEM, REMOVE_LIST_ITEM,
  REPLACE_LIST_ITEM,
  SEO_LOADING,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH,
  SET_SEO,
  UPDATE_COUNTRIES_LOADING,
  UPDATE_SEO_LOADING,
  UPDATE_TRANSLATION_LOADING
} from './types/mutation-types'

import {
  CREATE, DELETE,
  GET_LIST,
  GET_LIST_ITEM,
  GET_SEO,
  UPDATE,
  UPDATE_COUNTRIES,
  UPDATE_SEO,
  UPDATE_TRANSLATION
} from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: [],
  isLoading: false,
  isListLoading: false,
  currentItem: null,
  limit: 13,
  skip: 0,
  listLength: 0,
  openDialogView: false,
  editId: '',
  isCountriesLoading: false,
  isTranslationLoading: false,

  seo: null,
  seoLoading: false,
  updateSeoLoading: false,

  checkList: [],
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  isLoading: state => state.isLoading,
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  isListLoading: state => state.isListLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  skip: state => state.skip,
  openDialogView: state => state.openDialogView,
  editId: state => state.editId,
  seo: state => transformerSeo(state.seo),
  seoLoading: state => state.seoLoading,
  updateSeoLoading: state => state.updateSeoLoading,

  isCountriesLoading: state => state.isCountriesLoading,
  isTranslationLoading: state => state.isTranslationLoading,

  checkList: state => state.checkList
}

const actions = {
  [GET_LIST]: async ({ commit }) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}categories?include=translations&limit=${state.limit}&skip=${state.skip}`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}categories/${state.editId}?include=parent,children,seo,translations,countries.country`)

      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}categories/${payload.get('id')}?include=parent,children,seo,translations,countries.country`, payload)
      commit(REPLACE_LIST_ITEM, response.data.data)
      commit(SET_ITEM, response.data.data)
      dispatch(GET_LIST)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}categories?include=parent,children,seo,translations,countries.country`, payload)
      commit(ADD_LIST_ITEM, response.data.data)
      commit(SET_ITEM, response.data.data)
      dispatch(GET_LIST)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}categories/${payload.id}`)
      commit(REMOVE_LIST_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [GET_SEO]: async ({ commit }, payload) => {
    try {
      commit(SEO_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}categories/${payload.id}/seo`)
      commit(SET_SEO, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SEO_LOADING, false)
    }
  },
  [UPDATE_SEO]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_SEO_LOADING, true)
      if (payload.hasOwnProperty('_method')) {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}categories/${payload.category_id}/seo/${payload.id}`, payload)
        commit(FRESH_SEO_ITEM, response.data.data)
      } else {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}categories/${payload.category_id}/seo`, payload)
        commit(ADD_SEO_ITEM, response.data.data)
      }
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_SEO_LOADING, false)
    }
  },
  [UPDATE_COUNTRIES]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_COUNTRIES_LOADING, true)
      if (payload.get('_method')) {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}categories/${payload.get('category_id')}/countries/${payload.get('id')}`, payload)
        commit(FRESH_COUNTRY, response.data.data)
      } else {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}categories/${payload.get('category_id')}/countries`, payload)
        commit(ADD_COUNTRY, response.data.data)
      }
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_COUNTRIES_LOADING, false)
    }
  },
  [UPDATE_TRANSLATION]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_TRANSLATION_LOADING, true)
      if (payload.get('_method')) {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}categories/${payload.get('category_id')}/translations/${payload.get('id')}`, payload)
        commit(FRESH_TRANSLATION_ITEM, response.data.data)
      } else {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}categories/${payload.get('category_id')}/translations`, payload)
        commit(ADD_TRANSLATION_ITEM, response.data.data)
      }
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_TRANSLATION_LOADING, false)
    }
  },

  CHECK_ITEM_IN_COURSES: async ({commit}, id) => {
      try {
          let response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}categories/${id}/courses `);
          if (response.data.data.length > 0){
              let resultList = map(response.data.data, item => {
                  return item.name
              });

              commit('ADD_TO_CHECK_LIST', resultList);
          }

          return response.data.data;
      } catch (e) {
          throw e;
      } finally {
      }
  },
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [REPLACE_LIST_ITEM] (state, data) {
    const index = state.list.findIndex(e => e.id === data.id)
    Vue.set(state.list, index, data)
  },
  [ADD_LIST_ITEM] (state, data) {
    state.list.unshift(data)
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [CHANGE_DIALOG_VIEW] (state, status) {
    state.openDialogView = status
  },
  [CHANGE_EDIT_ID] (state, id) {
    state.editId = id
  },
  [UPDATE_SEO_LOADING] (state, status) {
    state.updateSeoLoading = status
  },
  [SEO_LOADING] (state, status) {
    state.seoLoading = status
  },
  [SET_SEO] (state, content) {
    state.seo = content
  },
  [FRESH_SEO_ITEM] (state, data) {
    const index = state.seo.findIndex(e => e.locale === data.locale)
    Vue.set(state.seo, index, data)
  },
  [ADD_SEO_ITEM] (state, content) {
    state.seo.push(content)
  },
  [ADD_COUNTRY] (state, content) {
    state.currentItem.countries.data.push(content)
  },
  [FRESH_COUNTRY] (state, data) {
    const index = state.currentItem.countries.data.findIndex(e => e.id === data.id)
    Vue.set(state.currentItem.countries.data, index, data)
  },
  [UPDATE_COUNTRIES_LOADING] (state, status) {
    state.isCountriesLoading = status
  },
  [ADD_TRANSLATION_ITEM] (state, content) {
    state.currentItem.translations.data.push(content)
  },
  [FRESH_TRANSLATION_ITEM] (state, data) {
    const index = state.currentItem.translations.data.findIndex(e => e.id === data.id)
    Vue.set(state.currentItem.translations.data, index, data)
  },
  [UPDATE_TRANSLATION_LOADING] (state, status) {
    state.isTranslationLoading = status
  },
  [REMOVE_LIST_ITEM] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  },

  ADD_TO_CHECK_LIST (state, checkList) {
      state.checkList = state.checkList.concat(checkList)

  },
  RESET_CHECK_LIST (state) {
      state.checkList = []
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}
