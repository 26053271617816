import { $http } from '@/utils/https'
import Vue from 'vue'

import {
  CREATE,
  CREATE_CONTENT_FILE,
  DELETE,
  GET_LIST,
  UPDATE,
  UPDATE_CONTENT_FILE,
  UPDATE_IMAGE,
  UPDATE_SORT,
  DELETE_CONTENT_FILE
} from './types/action-types'
import {
  CHANGE_EDIT_ID,
  CONTENT_FILE_LOADING,
  DELETE_LIST_ITEM,
  FRESH_IMAGE,
  FRESH_LOADING,
  LIST_LOADING,
  REPLACE_CONTENT_FILE,
  RESET,
  SET_CONTENT_FILE,
  SET_LIST,
  SET_NEW_ITEM,
  UPDATE_IMAGE_LOADING,
  UPDATE_LIST_ITEM,
  DELETE_CONTENT_FILE_LOADING,
  REMOVE_CONTENT_FILE
} from './types/mutation-types'

const state = {
  listLoading: false,
  contentFileLoading: false,
  list: [],
  loading: false,
  updateImageLoading: false,
  removeFileLoading: false,
  editId: ''
}

const getters = {
  list: state => state.list,
  listLoading: state => state.listLoading,
  contentFileLoading: state => state.contentFileLoading,
  updateImageLoading: state => state.updateImageLoading,
  removeFileLoading: state => state.removeFileLoading,
  loading: state => state.loading,

  editId: state => state.editId
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}action-plans/${payload.parentId}/contents?include=status,type,contents`)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(LIST_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_EDUCATION}action-plans/${payload.parentId}/contents/${payload.id}`)
      commit(DELETE_LIST_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [CREATE]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}action-plans/${payload.get('parent_id')}/contents?include=status,type,contents`, payload)
      commit(SET_NEW_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [CREATE_CONTENT_FILE]: async ({ commit }, payload) => {
    try {
      commit(CONTENT_FILE_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}action-plans/${payload.get('action_plan_id')}/contents/${payload.get('content_id')}/contents?include=status,type`, payload)
      commit(SET_CONTENT_FILE, {
        ...response.data.data,
        contentId: parseInt(payload.get('content_id'))
      })
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CONTENT_FILE_LOADING, false)
    }
  },
  [UPDATE_CONTENT_FILE]: async ({ commit }, payload) => {
    try {
      commit(CONTENT_FILE_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}action-plans/${payload.get('action_plan_id')}/contents/${payload.get('content_id')}/contents/${payload.get('id')}?include=status,type`, payload)
      commit(REPLACE_CONTENT_FILE, {
        ...response.data.data,
        contentId: parseInt(payload.get('content_id'))
      })
    } catch (e) {
      throw e
    } finally {
      commit(CONTENT_FILE_LOADING, false)
    }
  },
  [DELETE_CONTENT_FILE]: async ({ commit }, payload) => {
    try {
      commit(DELETE_CONTENT_FILE_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_EDUCATION}action-plans/${payload.actionPlanId}/contents/${payload.contentId}/contents/${payload.id}`)
      commit(REMOVE_CONTENT_FILE, payload)
    } catch (e) {
      throw e
    } finally {
      commit(DELETE_CONTENT_FILE_LOADING, false)
    }
  },
  [UPDATE]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}action-plans/${payload.get('parent_id')}/contents/${payload.get('id')}?include=status,type`, payload)
      commit(UPDATE_LIST_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [UPDATE_SORT]: async ({ commit }, payload) => {
    try {
      commit(LIST_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_EDUCATION}action-plans/${payload.parentId}/contents/sort?include=status,type`, payload)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(LIST_LOADING, false)
    }
  },
  [UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}action-plans/${payload.get('action_plan_id')}/contents/${payload.get('content_id')}/images/${payload.get('id')}`, payload)
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}action-plans/${payload.get('action_plan_id')}/contents/${payload.get('content_id')}/images`, payload)
      }
      response.data.data.actionPlanId = payload.get('action_plan_id')
      response.data.data.type_id = parseInt(payload.get('type_id'))
      commit(FRESH_IMAGE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_IMAGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [LIST_LOADING] (state, status) {
    state.listLoading = status
  },
  [CONTENT_FILE_LOADING] (state, status) {
    state.contentFileLoading = status
  },
  [SET_CONTENT_FILE] (state, data) {
    const contentIndex = state.list.findIndex(e => e.id === data.contentId)
    state.list[contentIndex].contents.data.push(data)
  },
  [REPLACE_CONTENT_FILE] (state, data) {
    const contentIndex = state.list.findIndex(e => e.id === data.contentId)
    const fileIndex = state.list[contentIndex].contents.data.findIndex(e => e.id === data.id)
    Vue.set(state.list[contentIndex].contents.data, fileIndex, data)
  },
  [REMOVE_CONTENT_FILE] (state, data) {
    const contentIndex = state.list.findIndex(e => e.id === data.contentId)
    const fileIndex = state.list[contentIndex].contents.data.findIndex(e => e.id === data.id)
    Vue.delete(state.list[contentIndex].contents.data, fileIndex)
  },
  [UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [SET_NEW_ITEM] (state, item) {
    state.list.push(item)
  },
  [UPDATE_LIST_ITEM] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.set(state.list, index, item)
  },
  [DELETE_LIST_ITEM] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.delete(state.list, index)
  },
  [CHANGE_EDIT_ID] (state, id) {
    state.editId = id
  },
  [FRESH_LOADING] (state, status) {
    state.loading = status
  },
  [DELETE_CONTENT_FILE_LOADING] (state, status) {
    state.removeFileLoading = status
  },
  [RESET] (state) {
    state.list = []
    state.editId = ''
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
