export default [
  {
    path: 'statistics',
    name: 'statistics',
    component: () => import('@/modules/statistics/pages/main/index.vue'),
    meta: {}
  },
  {
    path: 'statistics/course/:courseId',
    name: 'statistics.course',
    component: () => import('@/modules/statistics/pages/module-statistics/index.vue')
  },
  {
    path: 'statistics/students/:courseId',
    name: 'statistics.students',
    component: () => import('@/modules/statistics/pages/students/index.vue')
  },
  {
    path: 'statistics/students/:courseId/:packageId',
    name: 'statistics.students.package',
    component: () => import('@/modules/statistics/pages/students/index.vue')
  },
  {
    path: 'statistics/student/:courseId/users/:userId',
    name: 'statistics.details',
    component: () => import('@/modules/statistics/pages/details/index.vue')
  },
  {
    path: 'statistics/materials-details/:courseId/module/:moduleId/lesson/:materialId',
    name: 'statistics.materials.details.lesson',
    component: () => import('@/modules/statistics/pages/materials-details-lesson/index.vue')
  },
  {
    path: 'statistics/materials-details/:courseId/module/:moduleId/homeWork/:materialId',
    name: 'statistics.materials.details.home_work',
    component: () => import('@/modules/statistics/pages/materials-details-home-work/index.vue')
  },
  {
    path: 'statistics/materials-details/:courseId/graduate',
    name: 'statistics.materials.details.graduate_work',
    component: () => import('@/modules/statistics/pages/materials-details-graduate-work/index.vue')
  },
  {
    path: 'statistics/questions/:courseId/module/:moduleId/homeWork/:materialId',
    name: 'statistics.questions',
    component: () => import('@/modules/statistics/pages/questions/index.vue')
  }
]
