import Vue from 'vue'
import { $http } from '@/utils/https'
import { transformerSeo } from '@/transformers'

import {
  SET_LIST,
  SET_LIST_LENGTH,
  SET_ITEM,
  CHANGE_LIST_LOADING,
  CHANGE_SKIP,
  CHANGE_LOADING,
  CHANGE_SHOW_DIALOG,
  CHANGE_EDIT_ITEM,
  UPDATE_SEO_LOADING,
  FRESH_SEO_ITEM,
  ADD_SEO_ITEM,
  SEO_LOADING,
  SET_SEO,
  UPDATE_IMAGE_LOADING,
  FRESH_IMAGE,
  CHANGE_LIST_FILTER
} from './types/mutation-types'

import {
  GET_LIST,
  GET_LIST_ITEM,
  UPDATE,
  CREATE,
  UPDATE_SEO,
  UPDATE_IMAGE
} from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: [],
  currentItem: null,
  editedItem: null,
  isLoading: false,
  isListLoading: false,
  showDialogView: false,
  limit: 20,
  skip: 0,
  listLength: 0,
  updateImageLoading: false,

  filterStatus: '',
  filterCategories: '',
  filterCountries: '',
  filterKeywords: '',

  seo: null,
  seoLoading: false,
  updateSeoLoading: false
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  listLength: state => state.listLength,
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  limit: state => state.limit,
  skip: state => state.skip,
  showDialogView: state => state.showDialogView,
  updateImageLoading: state => state.updateImageLoading,
  editedItem: state => state.editedItem,

  filterStatus: state => state.filterStatus,
  filterCategories: state => state.filterCategories,
  filterCountries: state => state.filterCountries,
  filterKeywords: state => state.filterKeywords,

  seo: state => transformerSeo(state.seo),
  seoLoading: state => state.seoLoading,
  updateSeoLoading: state => state.updateSeoLoading
}

const actions = {
  [GET_LIST]: async ({ commit }) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}tests?include=status,category,picture,picture.seo,country&filters[countries]=${state.filterCountries || ''}&filters[categories]=${state.filterCategories || ''}&filters[status]=${state.filterStatus || ''}&filters[keywords]=${state.filterKeywords || ''}&limit=${state.limit}&skip=${state.skip}`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}tests/${payload.id}?include=status,category,picture,picture.seo,country,seo,authors,questions,questions.answerVariants`)

      commit(CHANGE_EDIT_ITEM, response.data.data)
      commit(SET_ITEM, response.data.data)
      commit(SET_SEO, response.data.data.seo.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tests/${payload.id}`, payload)

      commit(CHANGE_EDIT_ITEM, response.data.data)
      dispatch(GET_LIST_ITEM, response.data.data)
      dispatch(GET_LIST)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tests?include=status,category,picture,picture.seo,country`, payload)

      commit(CHANGE_EDIT_ITEM, response.data.data)
      dispatch(GET_LIST_ITEM, response.data.data)
      dispatch(GET_LIST)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },

  [UPDATE_SEO]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_SEO_LOADING, true)

      if (payload.hasOwnProperty('_method')) {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tests/${payload.parentId}/seo/${payload.id}`, payload)
        commit(FRESH_SEO_ITEM, response.data.data)
      } else {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tests/${payload.parentId}/seo`, payload)
        commit(ADD_SEO_ITEM, response.data.data)
      }
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_SEO_LOADING, false)
    }
  },
  [UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tests/${payload.get('test_id')}/images/${payload.get('id')}?include=seo`, payload)
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tests/${payload.get('test_id')}/images?include=seo`, payload)
      }
      response.data.data.testId = payload.get('test_id')
      commit(FRESH_IMAGE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_IMAGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_EDIT_ITEM] (state, data) {
    state.editedItem = data
  },
  [CHANGE_SHOW_DIALOG] (state, status) {
    state.showDialogView = status
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },

  [UPDATE_SEO_LOADING] (state, status) {
    state.updateSeoLoading = status
  },
  [SEO_LOADING] (state, status) {
    state.seoLoading = status
  },
  [SET_SEO] (state, content) {
    state.seo = content
  },
  [FRESH_SEO_ITEM] (state, data) {
    const index = state.seo.findIndex(e => e.locale === data.locale)
    Vue.set(state.seo, index, data)
  },
  [CHANGE_LIST_FILTER] (state, params) {
    switch (params.type) {
      case 'status': {
        state.filterStatus = params.value
        break
      }
      case 'categories': {
        state.filterCategories = params.value
        break
      }
      case 'countries': {
        state.filterCountries = params.value
        break
      }
      default: {
        state.filterKeywords = params.value
      }
    }
  },
  [ADD_SEO_ITEM] (state, content) {
    state.seo.push(content)
  },
  [UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [FRESH_IMAGE] (state, data) {
    const index = state.list.findIndex(e => e.id === parseInt(data.testId))
    state.currentItem.picture.data = data
    state.list[index].picture.data = data
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
