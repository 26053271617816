const camelcaseObjectDeep = require('camelcase-object-deep')
export function transformerScoredPointsList (list) {
  return list.map(item => {
    return {
      userFullName: item.user.data.id !== undefined ? item.user.data.personalData.data.full_name : 'Deleted user',
      adminFullName: item.admin.data.id !== undefined ? item.admin.data.personalData.data.full_name : 'Deleted admin',
      ...camelcaseObjectDeep(item)
    }
  })
}
