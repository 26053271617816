<template>
  <div class="app" id="app">
    <div class="wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>
<style lang="scss" src="./assets/scss/main.scss"></style>
<script>
import { isMobile } from '@/helpers'

export default {
  name: 'App',
  mounted () {
    if (!isMobile.any()) {
      document.querySelector('body').classList.add('hover')
    }
  }
}
</script>
