import Vue from 'vue'
import { $http } from '@/utils/https'
import {
  SET_SETTINGS,
  GET_SETTINGS_LOADING
} from './types/mutation-types'

import {
  GET_SETTINGS
} from './types/action-types'

const state = {
  settings: null,
  isSettingsLoading: false
}

const getters = {
  settings: state => state.settings,
  isSettingsLoading: state => state.isSettingsLoading
}

const actions = {
  [GET_SETTINGS]: async ({ commit }) => {
    try {
      commit(GET_SETTINGS_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}setting`)
      commit(SET_SETTINGS, response.data.data)
      return response
    } catch (e) {
      throw e
    } finally {
      commit(GET_SETTINGS_LOADING, false)
    }
  }
}

const mutations = {
  [SET_SETTINGS] (state, data) {
    state.settings = data
  },
  [GET_SETTINGS_LOADING] (state, status) {
    state.isSettingsLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
