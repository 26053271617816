import { $http } from '@/utils/https'
import Vue from 'vue'

import {
    CHANGE_SKIP,
    USER_CARDS_REMOVE_ITEM,
    CHANGE_LOADING,
    SET_USER_CARDS,
    SET_USER_CARDS_LENGTH
} from './types/mutation-types'

import {
    GET_USER_CARDS,
    DELETE
} from './types/action-types'

const state = {
    cards               : [],
    cardsLength         : 0,
    isLoading           : false,
    skip                : 0,
    limit               : 15
}

const getters = {
    cards               : state => state.cards,
    cardsLength         : state => state.cardsLength,
    isLoading           : state => state.isLoading,
    skip                : state => state.skip,
    limit               : state => state.limit
}

const actions = {
    [GET_USER_CARDS]  : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_PAYMENTS}users/${payload.userId}/cards?limit=${(payload.limit ? payload.limit : state.limit)}&skip=${(payload.skip ? payload.skip : state.skip)}`)
            
            if(response && response.data && 'data' in response.data){
                commit(SET_USER_CARDS         , response.data.data)
                commit(SET_USER_CARDS_LENGTH  , response.data.meta.total)
            }else{
                commit(SET_USER_CARDS         , [])
                commit(SET_USER_CARDS_LENGTH  , 0)
            }
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [DELETE]            : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.delete(`${process.env.VUE_APP_ROOT_PAYMENTS}users/${payload.userId}/cards/${payload.id}`)
            
            commit(USER_CARDS_REMOVE_ITEM, payload)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    }
}

const mutations = {
    [SET_USER_CARDS] (state, list) {
        state.cards = list
    },
    [SET_USER_CARDS_LENGTH] (state, length) {
        state.cardsLength = length
    },
    [CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
    [USER_CARDS_REMOVE_ITEM] (state, params) {
        const index = state.cards.findIndex(e => e.card_id === params.id)
        
        Vue.delete(state.cards, index)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
