import { $http } from '@/utils/https'
import Vue from 'vue'

import {
  EVENTS_FETCH_LIST_LOADING,
  EVENTS_CHANGE_FILTER,
  EVENTS_FRESH_LIST,
  EVENTS_LIST_CHANGE_SKIP,
  EVENTS_REMOVE_LIST_ITEM_LOADING,
  EVENTS_SET_LIST,
  EVENTS_SET_LIST_LENGTH
} from './types/mutation-types'

import {
  EVENTS_FETCH_LIST,
  EVENTS_LIST_DELETE_ITEM
} from './types/action-types'

const state = {
  isListLoading: false,
  list: [],
  listLength: 0,
  limit: 30,
  skip: 0,

  filterKeywords: '',
  filterStatus: '',
  filterCountries: '',

  removeListItemLoading: false
}

const getters = {
  list: state => state.list.map(e => {
    return {
      id: e.id,
      name: e.name,
      createdAt: e.created_at,
      country: e?.country.data ? e?.country.data?.slug : '',
      status: e.status,
      type: e.type
    }
  }),
  isListLoading: state => state.isListLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  skip: state => state.skip,

  filterKeywords: state => state.filterKeywords,
  filterStatus: state => state.filterStatus,
  filterCountries: state => state.filterCountries,

  removeListItemLoading: state => state.removeListItemLoading
}

const actions = {
  [EVENTS_FETCH_LIST]: async ({ commit }, payload) => {
    try {
      commit(EVENTS_FETCH_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_EVENT}events/?include=country&filter[byCountries]=${state.filterCountries || ''}&filter[status]=${state.filterStatus || ''}&filter[keywords]=${state.filterKeywords || ''}&limit=${state.limit}&skip=${state.skip}`)

      commit(EVENTS_SET_LIST, response.data.data)
      commit(EVENTS_SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(EVENTS_FETCH_LIST_LOADING, false)
    }
  },
  [EVENTS_LIST_DELETE_ITEM]: async ({ commit }, payload) => {
    try {
      commit(EVENTS_REMOVE_LIST_ITEM_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_EVENT}events/${payload.id}`)
      commit(EVENTS_FRESH_LIST, payload)
    } catch (e) {
      throw e
    } finally {
      commit(EVENTS_REMOVE_LIST_ITEM_LOADING, false)
    }
  }
}

const mutations = {
  [EVENTS_SET_LIST] (state, list) {
    state.list = list
  },
  [EVENTS_REMOVE_LIST_ITEM_LOADING] (state, status) {
    state.removeListItemLoading = status
  },
  [EVENTS_FRESH_LIST] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    const obj = state.list[index]
    obj.status = 'deprecated'

    Vue.set(state.list, index, obj)
  },
  [EVENTS_SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [EVENTS_CHANGE_FILTER] (state, params) {
    switch (params.type) {
      case 'type': {
        state.filterType = params.value
        break
      }
      case 'country': {
        state.filterCountries = params.value
        break
      }
      case 'status': {
        state.filterStatus = params.value
        break
      }
      default: {
        state.filterKeywords = params.value
      }
    }
  },
  [EVENTS_FETCH_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [EVENTS_LIST_CHANGE_SKIP] (state, count) {
    state.skip = count
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
