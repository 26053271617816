import Vue from 'vue'
import { $http } from '@/utils/https'
import { transformerSeo } from '@/transformers'

import {
  SET_LIST,
  SET_LIST_LOADING,
  FRESH_LIST,
  CHANGE_FILTER,
  SET_LIST_LENGTH,
  SET_ITEM,
  SET_NEW_ITEM,
  CHANGE_LOADING,
  UPDATE_SEO_LOADING,
  SEO_LOADING,
  SET_SEO,
  FRESH_SEO_ITEM,
  ADD_SEO_ITEM,
  CHANGE_MATERIAL_TYPE, UPDATE_IMAGE_LOADING, FRESH_IMAGE
} from './types/mutation-types'

import {
  GET_LIST,
  UPDATE_SORT,
  DELETE,
  CREATE,
  UPDATE,
  GET_SEO,
  UPDATE_SEO,
  GET_LIST_ITEM, UPDATE_IMAGE
} from './types/action-types'
import { RESET } from '../content/types/mutation-types'

const state = {
  list: [],
  isListLoading: false,
  isLoading: false,
  currentItem: null,
  selectedMaterialType: 'webinars',
  updateImageLoading: false,

  filterCountries: '',
  filterStatus: '',
  filterKeywords: '',

  listLength: 0,
  limit: 30,
  skip: 0,

  seo: null,
  seoLoading: false,
  updateSeoLoading: false

}

const getters = {
  list: state => state.list,
  isListLoading: state => state.isListLoading,
  isLoading: state => state.isLoading,
  selectedMaterialType: state => state.selectedMaterialType,
  filterCountries: state => state.filterCountries,
  filterStatus: state => state.filterStatus,
  filterKeywords: state => state.filterKeywords,
  listLength: state => state.listLength,
  currentItem: state => state.currentItem,
  limit: state => state.limit,
  skip: state => state.skip,
  updateImageLoading: state => state.updateImageLoading,

  seo: state => transformerSeo(state.seo),
  seoLoading: state => state.seoLoading,
  updateSeoLoading: state => state.updateSeoLoading
}

const actions = {
  [GET_LIST]: async ({ commit }) => {
    try {
      commit(SET_LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}${state.selectedMaterialType}?include=category,country,${state.selectedMaterialType !== 'bonuses' ? 'speakers' : 'authors'},tariffs,status,picture,picture.seo,cover,cover.seo&filter[byCountries]=${state.filterCountries}&filter[status]=${state.filterStatus}&filter[keywords]=${state.filterKeywords}&limit=${state.limit}&skip=${state.skip}`)
      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
      return response
    } catch (e) {
      throw e
    } finally {
      commit(SET_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}${state.selectedMaterialType}/${payload.id}?include=category,country,${state.selectedMaterialType !== 'bonuses' ? 'speakers' : 'authors'},tariffs,status,picture,picture.seo,cover,cover.seo`)
      commit(SET_ITEM, response.data.data)
      return response
    } catch (e) {
      throw e
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}${state.selectedMaterialType}?include=category,country,${state.selectedMaterialType !== 'bonuses' ? 'speakers' : 'authors'},tariffs,status`, payload)
      dispatch(GET_LIST)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}${state.selectedMaterialType}/${payload.get('id')}`, payload)
      dispatch(GET_LIST)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_EDUCATION}${state.selectedMaterialType}/${payload.id}`)
      commit(FRESH_LIST, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_SORT]: async ({ commit }, payload) => {
    try {
      commit(SET_LIST_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}${state.selectedMaterialType}/sort`, payload)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SET_LIST_LOADING, false)
    }
  },
  [GET_SEO]: async ({ commit }, payload) => {
    try {
      commit(SEO_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}${state.selectedMaterialType}/${payload.parentId}/seo`)
      commit(SET_SEO, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SEO_LOADING, false)
    }
  },
  [UPDATE_SEO]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_SEO_LOADING, true)

      if (payload.hasOwnProperty('_method')) {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}${state.selectedMaterialType}/${payload.parentId}/seo/${payload.id}`, payload)
        commit(FRESH_SEO_ITEM, response.data.data)
      } else {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}${state.selectedMaterialType}/${payload.parentId}/seo`, payload)
        commit(ADD_SEO_ITEM, response.data.data)
      }
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_SEO_LOADING, false)
    }
  },
  [UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}${state.selectedMaterialType}/${payload.get('material_id')}/images/${payload.get('id')}`, payload)
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}${state.selectedMaterialType}/${payload.get('material_id')}/images`, payload)
      }
      response.data.data.materialId = payload.get('material_id')
      response.data.data.type_id = parseInt(payload.get('type_id'))
      commit(FRESH_IMAGE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_IMAGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, data) {
    state.list = data
  },
  [SET_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [FRESH_LIST] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [SET_NEW_ITEM] (state, item) {
    state.list.push(item)
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [CHANGE_FILTER] (state, params) {
    switch (params.type) {
      case 'country': {
        state.filterCountries = params.value
        break
      }
      case 'status': {
        state.filterStatus = params.value
        break
      }
      default: {
        state.filterKeywords = params.value
      }
    }
  },
  [RESET] (state) {
    state.list = []
    state.listLength = 0
    state.skip = 0
    state.currentItem = null
  },
  [UPDATE_SEO_LOADING] (state, status) {
    state.updateSeoLoading = status
  },
  [SEO_LOADING] (state, status) {
    state.seoLoading = status
  },
  [SET_SEO] (state, content) {
    state.seo = content
  },
  [FRESH_SEO_ITEM] (state, data) {
    const index = state.seo.findIndex(e => e.locale === data.locale)
    Vue.set(state.seo, index, data)
  },
  [ADD_SEO_ITEM] (state, content) {
    state.seo.push(content)
  },
  [CHANGE_MATERIAL_TYPE] (state, type) {
    state.selectedMaterialType = type
  },
  [UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [FRESH_IMAGE] (state, data) {
    const index = state.list.findIndex(e => e.id === parseInt(data.materialId))
    if (data.type_id === 2) {
      state.currentItem.picture.data = data
      state.list[index].picture.data = data
    } else {
      state.currentItem.cover.data = data
    }
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
