import { $http } from '@/utils/https'
import Vue from 'vue'

import {
    CHANGE_SKIP,
    USER_TARIFFS_REMOVE_ITEM,
    CHANGE_LOADING,
    GET_TARIFFS_LOADING,
    SET_TARIFFS,
    SET_USER_TARIFFS,
    SET_USER_TARIFFS_LENGTH,
    GET_USER_TARIFFS_LOADING
} from './types/mutation-types'

import {
    GET_TARIFFS,
    GET_USER_TARIFFS,
    DELETE,
    CREATE,
    UPDATE
} from './types/action-types'

const state = {
    tariffs             : [],
    isTariffsLoading    : false,
    userTariffs         : [],
    userTariffsLength   : 0,
    isLoadingUserTariffs: false,
    skip                : 0,
    limit               : 5,
    isLoading           : false
}

const getters = {
    tariffs             : state => state.tariffs,
    isTariffsLoading    : state => state.isTariffsLoading,
    userTariffs         : state => state.userTariffs,
    userTariffsLength   : state => state.userTariffsLength,
    isLoadingUserTariffs: state => state.isLoadingUserTariffs,
    skip                : state => state.skip,
    limit               : state => state.limit,
    isLoading           : state => state.isLoading
}

const actions = {
    [GET_TARIFFS]       : async ({ commit }, payload) => {
        commit(GET_TARIFFS_LOADING, true)
        
        var list = [];
        
        try {
            if(payload && 'loadAll' in payload && payload.loadAll){
                payload.limit   = payload.limit ? payload.limit : 5;
                
                for(let i = 0; i < 100; i++){
                    payload.skip = (i == 0 ? 0 : payload.skip + payload.limit);
                    
                    const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs?include=type,status&filters[countries]=&filters[status]=2&filters[keywords]=${payload.keywords}&limit=${(payload.limit ? payload.limit : state.limit)}&skip=${(payload.skip ? payload.skip : state.skip)}`)
                    
                    if(response && response.data && 'data' in response.data && response.data.data.length ){
                        list = list.concat(response.data.data);
                    }else{
                        commit(SET_TARIFFS, list)
                        
                        break;
                    }
                }
            }else{
                const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs?include=filters[keywords]=${payload.keywords}&limit=${(payload.limit ? payload.limit : state.limit)}&skip=${(payload.skip ? payload.skip : state.skip)}`)
                
                if(response && response.data && 'data' in response.data){
                    commit(SET_TARIFFS, response.data.data)
                }else{
                    commit(SET_TARIFFS, [])
                }
            }
        } catch (e) {
            if(list.length){
                commit(SET_TARIFFS, list)
            }
            
            commit(GET_TARIFFS_LOADING, false)
            
            //throw e
        } finally {
            commit(GET_TARIFFS_LOADING, false)
        }
    },
    [GET_USER_TARIFFS]  : async ({ commit }, payload) => {
        try {
            commit(GET_USER_TARIFFS_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}users/${payload.userId}/tariffs?include=tariff&limit=${(payload.limit ? payload.limit : state.limit)}&skip=${(payload.skip ? payload.skip : state.skip)}`)
            
            if(response && response.data && 'data' in response.data){
                commit(SET_USER_TARIFFS         , response.data.data)
                commit(SET_USER_TARIFFS_LENGTH  , response.data.meta.total)
            }else{
                commit(SET_USER_TARIFFS         , [])
                commit(SET_USER_TARIFFS_LENGTH  , 0)
            }
        } catch (e) {
            throw e
        } finally {
            commit(GET_USER_TARIFFS_LOADING, false)
        }
    },
    [DELETE]            : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.delete(`${process.env.VUE_APP_ROOT_EDUCATION}users/${payload.userId}/tariffs/${payload.id}`)
            
            commit(USER_TARIFFS_REMOVE_ITEM, payload)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [CREATE]            : async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}users/${payload.get('userId')}/tariffs?include=tariff`, payload)
            
            dispatch(GET_USER_TARIFFS, { userId: payload.get('userId') })
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [UPDATE]            : async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}users/${payload.get('userId')}/tariffs/${payload.get('id')}?include=tariff`, payload)
            
            dispatch(GET_USER_TARIFFS, { userId: payload.get('userId') })
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    }
}

const mutations = {
    [SET_TARIFFS] (state, list) {
        state.tariffs = list
    },
    [GET_TARIFFS_LOADING] (state, status) {
        state.isTariffsLoading = status
    },
    [SET_USER_TARIFFS] (state, list) {
        state.userTariffs = list
    },
    [SET_USER_TARIFFS_LENGTH] (state, length) {
        state.userTariffsLength = length
    },
    [GET_USER_TARIFFS_LOADING] (state, status) {
        state.isLoadingUserTariffs = status
    },
    [CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
    [USER_TARIFFS_REMOVE_ITEM] (state, params) {
        const index = state.userTariffs.findIndex(e => e.tariff_id === params.id)
        
        Vue.delete(state.userTariffs, index)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
