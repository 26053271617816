import { $http } from '@/utils/https'
import { GET_SETTING_LOADING, SET_SETTING } from './types/mutation-types'

import { GET_SETTING } from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  settings: null,
  isSettingsLoading: false
}

const getters = {
  settings: state => camelcaseObjectDeep(state.settings),
  isSettingsLoading: state => state.isSettingsLoading
}

const actions = {
  [GET_SETTING]: async ({ commit }) => {
    try {
      commit(GET_SETTING_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_TEMPLATE}setting`)
      commit(SET_SETTING, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_SETTING_LOADING, false)
    }
  }
}

const mutations = {
  [SET_SETTING] (state, list) {
    state.settings = list
  },
  [GET_SETTING_LOADING] (state, status) {
    state.isSettingsLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
