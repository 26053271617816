// utils
import './utils/plugins'
import './utils/translations'

import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from '@/store'

import { vuetify } from './utils/plugins'

// vue configs
Vue.config.productionTip = false
// set token after refresh page
if (localStorage.getItem('user_token')) {
  store.state.auth.isAuthenticated = true;
}

if (localStorage.getItem('is_admin')) {
  store.state.profile.isAdmin = true;
}

Vue.prototype.$bus = new Vue;

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
})

Vue.initI18nManager().then(() => {
  app.$mount('#app')
})
