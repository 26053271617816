export const EVENTS_FETCH_LIST_LOADING = 'NEWS_FETCH_LIST_LOADING'
export const EVENTS_CHANGE_FILTER = 'EVENTS_CHANGE_FILTER'
export const EVENTS_FRESH_LIST = 'NEWS_FRESH_LIST'
export const EVENTS_LIST_CHANGE_SKIP = 'NEWS_LIST_CHANGE_SKIP'
export const EVENTS_REMOVE_LIST_ITEM_LOADING = 'NEWS_REMOVE_LIST_ITEM_LOADING'
export const EVENTS_SET_LIST = 'NEWS_SET_LIST'
export const EVENTS_SET_LIST_LENGTH = 'NEWS_SET_LIST_LENGTH'
export const FRESH_IMAGE = 'FRESH_IMAGE'
export const UPDATE_IMAGE_LOADING = 'UPDATE_IMAGE_LOADING'

// general
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const SET_DATA = 'SET_DATA'

// content
export const CONTENT_GET_DATA_LOADING = 'CONTENT_GET_DATA_LOADING'
export const CONTENT_SET_DATA = 'CONTENT_SET_DATA'
export const CONTENT_UPDATE_DATA_LOADING = 'CONTENT_UPDATE_DATA_LOADING'
export const CONTENT_SET_NEW_ITEM = 'CONTENT_SET_NEW_ITEM'
export const CONTENT_UPDATE_ITEM = 'CONTENT_UPDATE_ITEM'

// seo
export const SEO_GET_DATA_LOADING = 'SEO_GET_DATA_LOADING'
export const SEO_SET_DATA = 'SEO_SET_DATA'
export const SEO_UPDATE_DATA_LOADING = 'SEO_UPDATE_DATA_LOADING'
export const SEO_SET_NEW_ITEM = 'SEO_SET_NEW_ITEM'
export const SEO_UPDATE_ITEM = 'SEO_UPDATE_ITEM'
