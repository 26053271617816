import { $http } from '@/utils/https'
import {
  CHANGE_DIALOG_VIEW,
  CHANGE_FILTER,
  CHANGE_LOADING,
  CHANGE_SKIP,
  FRESH_IMAGE,
  GET_LIST_LOADING,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH,
  UPDATE_IMAGE_LOADING
} from './types/mutation-types'

import { CREATE, GET_LIST, GET_LIST_ITEM, UPDATE, UPDATE_IMAGE } from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: [],
  isListLoading: false,
  currentItem: null,
  isLoading: false,
  limit: 10,
  skip: 0,
  listLength: 0,
  updateImageLoading: false,
  filter: {
    keywords: '',
    countries: '',
    positionId: '',
    active: ''
  },
  openDialogView: false,

  checkList: [],
  isDeleteLoading: false
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  skip: state => state.skip,
  filter: state => state.filter,
  updateImageLoading: state => state.updateImageLoading,
  openDialogView: state => state.openDialogView,

  checkList: state => state.checkList,
  isDeleteLoading: state => state.isDeleteLoading,
}

const actions = {
  [GET_LIST]: async ({ commit }, type) => {
    try {
      commit(GET_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}user-positions?include=country,user&filters[keywords]=${state.filter.keywords}&filters[countries]=${state.filter.countries}&filters[active]=${state.filter.active}&filters[position_id]=${state.filter.positionId}&limit=${state.limit}&skip=${state.skip}`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(GET_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }, payload) => {
    try {
      commit(GET_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}user-positions/${payload.id}?include=country,user`)

      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_LIST_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}user-positions/${payload.get('id')}?include=country,user`, payload)
      dispatch(GET_LIST)
      dispatch(GET_LIST_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}user-positions?include=country,user`, payload)
      dispatch(GET_LIST)
      dispatch(GET_LIST_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/user-positions/${payload.get('user_id')}/images/${payload.get('id')}`, payload)
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/user-positions/${payload.get('user_id')}/images`, payload)
      }
      commit(FRESH_IMAGE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_IMAGE_LOADING, false)
    }
  },

  CHECK_ITEM_IN_MATERIALS: async ({ commit }, user_id) => {
    try {
      let response = await $http.get(`${process.env.VUE_APP_ROOT_NEWS}user-positions/${user_id}/materials/count`);
      if (response.data.data > 0) {
        commit('ADD_TO_CHECK_LIST', {
          name: 'Education materials',
          count: response.data.data
        });
      }

      return response.data.data;
    } catch (e) {
      throw e;
    } finally {
    }
  },

  CHECK_ITEM_IN_ADUCATION_MATERIALS: async ({ commit }, user_id) => {
    try {
      let response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}user-positions/${user_id}/materials/count`);
      if (response.data.data > 0) {
        commit('ADD_TO_CHECK_LIST', {
          name: 'Education materials',
          count: response.data.data
        });
      }


      return response.data.data;
    } catch (e) {
      throw e;
    } finally {
    }
  },

  DELETE_ITEM: async ({ commit, dispatch }, user_id) => {
    commit('CHANGE_DELETE_LOADING', true)
    try {
      let response = await $http.delete(`${process.env.VUE_APP_ROOT_USER}user-positions/${user_id}`);
      dispatch('GET_LIST');

      return response.data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_DELETE_LOADING', false)
    }
  }
}

const mutations = {
  [SET_LIST](state, list) {
    state.list = list
  },
  [CHANGE_LOADING](state, status) {
    state.isLoading = status
  },
  [CHANGE_FILTER](state, params) {
    const { isClear, type, value } = params
    if (!isClear) {
      state.filter[type] = value
    } else {
      Object.keys(state.filter).forEach(e => {
        state.filter[e] = ''
      })
    }
  },
  [GET_LIST_LOADING](state, status) {
    state.isListLoading = status
  },
  [SET_ITEM](state, data) {
    state.currentItem = data
  },
  [SET_LIST_LENGTH](state, length) {
    state.listLength = length
  },
  [CHANGE_SKIP](state, count) {
    state.skip = count
  },
  [CHANGE_DIALOG_VIEW](state, status) {
    state.openDialogView = status
  },
  [UPDATE_IMAGE_LOADING](state, status) {
    state.updateImageLoading = status
  },
  [FRESH_IMAGE](state, data) {
    state.currentItem.avatar = data
  },

  ADD_TO_CHECK_LIST(state, checkList) {
    state.checkList.push(checkList)
  },
  RESET_CHECK_LIST(state) {
    state.checkList = []
  },

  CHANGE_DELETE_LOADING(state, status) {
    state.isDeleteLoading = status
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}
