// news
export const NEWS_FETCH_LIST_LOADING = 'NEWS_FETCH_LIST_LOADING'
export const NEWS_FILTER_CHANGE_COUNTRY = 'NEWS_FILTER_CHANGE_COUNTRY'
export const NEWS_FILTER_CHANGE_SEARCH_INPUT = 'NEWS_FILTER_CHANGE_SEARCH_INPUT'
export const NEWS_FRESH_LIST = 'NEWS_FRESH_LIST'
export const NEWS_LIST_CHANGE_SKIP = 'NEWS_LIST_CHANGE_SKIP'
export const NEWS_REMOVE_LIST_ITEM_LOADING = 'NEWS_REMOVE_LIST_ITEM_LOADING'
export const NEWS_SET_LIST = 'NEWS_SET_LIST'
export const NEWS_SET_LIST_LENGTH = 'NEWS_SET_LIST_LENGTH'
export const UPDATE_IMAGE_LOADING = 'UPDATE_IMAGE_LOADING'
export const FRESH_IMAGE = 'FRESH_IMAGE'

// news types
export const SET_NEWS_CATEGORIES = 'SET_NEWS_CATEGORIES'
export const GET_NEWS_CATEGORIES_LOADING = 'GET_NEWS_CATEGORIES_LOADING'
export const SET_NEWS_TAGS = 'SET_NEWS_TAGS'
export const GET_NEWS_TAGS_LOADING = 'GET_NEWS_TAGS_LOADING'
export const NEWS_FILTER_CHANGE_TYPES = 'NEWS_FILTER_CHANGE_TYPES'
export const ADD_QUICK_TAG_LOADING = 'ADD_QUICK_TAG_LOADING'
export const SET_NEW_TAG = 'SET_NEW_TAG'

// general
export const GENERAL_GET_DATA_LOADING = 'GENERAL_GET_DATA_LOADING'
export const GENERAL_SET_DATA = 'GENERAL_SET_DATA'
export const GENERAL_UPDATE_DATA_LOADING = 'GENERAL_UPDATE_DATA_LOADING'
export const GENERAL_CREATE_DATA_LOADING = 'GENERAL_CREATE_DATA_LOADING'
export const GENERAL_CHANGE_TAG_LOADING = 'GENERAL_CHANGE_TAG_LOADING'
export const GENERAL_CHANGE_CATEGORY_LOADING = 'GENERAL_CHANGE_CATEGORY_LOADING'
export const GENERAL_SET_ADDED_TAG = 'GENERAL_SET_ADDED_TAG'
export const GENERAL_REMOVE_TAG_TO_LIST = 'GENERAL_REMOVE_TAG_TO_LIST'
export const GENERAL_SET_ADDED_CATEGORY = 'GENERAL_SET_ADDED_CATEGORY'
export const GENERAL_REMOVE_CATEGORY_TO_LIST = 'GENERAL_REMOVE_CATEGORY_TO_LIST'

// content
export const CONTENT_GET_DATA_LOADING = 'CONTENT_GET_DATA_LOADING'
export const CONTENT_SET_DATA = 'CONTENT_SET_DATA'
export const CONTENT_UPDATE_DATA_LOADING = 'CONTENT_UPDATE_DATA_LOADING'
export const CONTENT_SET_NEW_ITEM = 'CONTENT_SET_NEW_ITEM'
export const CONTENT_UPDATE_ITEM = 'CONTENT_UPDATE_ITEM'

// seo
export const SEO_GET_DATA_LOADING = 'SEO_GET_DATA_LOADING'
export const SEO_SET_DATA = 'SEO_SET_DATA'
export const SEO_UPDATE_DATA_LOADING = 'SEO_UPDATE_DATA_LOADING'
export const SEO_SET_NEW_ITEM = 'SEO_SET_NEW_ITEM'
export const SEO_UPDATE_ITEM = 'SEO_UPDATE_ITEM'
// categories
export const ADD_COUNTRY = 'ADD_COUNTRY'
export const ADD_LIST_ITEM = 'ADD_LIST_ITEM'
export const ADD_SEO_ITEM = 'ADD_SEO_ITEM'
export const ADD_TRANSLATION_ITEM = 'ADD_TRANSLATION_ITEM'
export const CHANGE_DIALOG_VIEW = 'CHANGE_DIALOG_VIEW'
export const CHANGE_EDIT_ID = 'CHANGE_EDIT_ID'
export const CHANGE_LIST_LOADING = 'CHANGE_LIST_LOADING'
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const CHANGE_SKIP = 'CHANGE_SKIP'
export const FRESH_COUNTRY = 'FRESH_COUNTRY'
export const FRESH_SEO_ITEM = 'FRESH_SEO_ITEM'
export const FRESH_TRANSLATION_ITEM = 'FRESH_TRANSLATION_ITEM'
export const REPLACE_LIST_ITEM = 'REPLACE_LIST_ITEM'
export const SEO_LOADING = 'SEO_LOADING'
export const SET_ITEM = 'SET_ITEM'
export const SET_LIST = 'SET_LIST'
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH'
export const SET_SEO = 'SET_SEO'
export const UPDATE_COUNTRIES_LOADING = 'UPDATE_COUNTRIES_LOADING'
export const UPDATE_SEO_LOADING = 'UPDATE_SEO_LOADING'
export const UPDATE_TRANSLATION_LOADING = 'UPDATE_TRANSLATION_LOADING'
export const REMOVE_LIST_ITEM = 'REMOVE_LIST_ITEM'
export const RESET = 'RESET'
export const SET_SETTING = 'SET_SETTING'
