import Vue from 'vue'
import { $http } from '@/utils/https'
const camelcaseObjectDeep = require('camelcase-object-deep')
import {
  SET_LIST,
  CHANGE_LIST_LOADING,
  SET_LIST_LENGTH,
  CHANGE_SKIP,
  CHANGE_LOADING,
  CHANGE_SHOW_DIALOG,
  FRESH_DATA,
  SET_GROUPS
} from './types/mutation-types'

import {
  GET_LIST,
  GET_LIST_ITEM,
  UPDATE,
  CREATE,
  GET_GROUPS
} from './types/action-types'

const state = {
  list: [],
  groups: [],
  currentItem: null,
  isLoading: false,
  isListLoading: false,
  limit: 50,
  skip: 0,
  listLength: 0,
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  groups: state => camelcaseObjectDeep(state.groups),
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  listLength: state => state.listLength,
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  limit: state => state.limit,
  skip: state => state.skip,
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_SETTING}translations?filters[locales]=${payload.locales || ''}&filters[keywords]=${payload.keywords || ''}&filters[group]=${payload.groups || ''}&limit=${state.limit}&skip=${state.skip}`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [GET_GROUPS]: async ({ commit }) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_SETTING}translation-groups`)

      commit(SET_GROUPS, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_SETTING}translations`, payload)
      commit(FRESH_DATA, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_SETTING}translations`, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_GROUPS] (state, list) {
    state.groups = list
  },
  [FRESH_DATA] (state, data) {
    const indexRow = state.list.findIndex(e => Object.keys(e)[0] === data.key);
    const itemRowIndex = state.list[indexRow][data.key].findIndex(e => e.locale === data.locale)
    Vue.set(state.list[indexRow][data.key], itemRowIndex, data )
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_SHOW_DIALOG] (state, status) {
    state.showDialogView = status
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}
