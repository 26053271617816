import { $http } from '@/utils/https'
import Vue from 'vue'
import { selectedModule } from '@/mixins'
import {
  NEWS_FETCH_LIST_LOADING,
  NEWS_FILTER_CHANGE_COUNTRY,
  NEWS_FILTER_CHANGE_SEARCH_INPUT,
  NEWS_FILTER_CHANGE_TYPES,
  NEWS_FRESH_LIST,
  NEWS_LIST_CHANGE_SKIP,
  NEWS_REMOVE_LIST_ITEM_LOADING,
  NEWS_SET_LIST,
  NEWS_SET_LIST_LENGTH,
  SET_SETTING,
  RESET
} from '../news/types/mutation-types'

import { NEWS_FETCH_LIST, NEWS_LIST_DELETE_ITEM, GET_SETTING } from '../news/types/action-types'

const state = {
  newsListLoading: false,
  newsList: [],
  newsListLength: 0,
  limit: 30,
  skip: 0,

  filterSearchInput: '',
  filterSelectedStatus: '',
  filterSelectedCountries: '',

  removeListItemLoading: false,
  setting: null
}

const getters = {
  newsList: state => state.newsList.map(e => {
    if (e.module === 'news') {
      return {
        id: e.id,
        name: e.name,
        createdAt: e.created_at,
        country: e.country.data ? e.country.data.slug : '',
        status: e.status,
        type: e.type
      }
    } else {
      return {
        id: e.id,
        name: e.name,
        country: 'ua',
        createdAt: e.created_at,
        status: e.status,
        type: e.type
      }
    }
  }),
  newsListLoading: state => state.newsListLoading,
  newsListLength: state => state.newsListLength,
  limit: state => state.limit,
  skip: state => state.skip,

  filterSearchInput: state => state.filterSearchInput,
  filterSelectedStatus: state => state.filterSelectedStatus,
  filterSelectedCountries: state => state.filterSelectedCountries,

  removeListItemLoading: state => state.removeListItemLoading,
  setting: state => state.setting
}

const actions = {
  [NEWS_FETCH_LIST]: async ({ commit }, payload) => {
    try {
      commit(NEWS_FETCH_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_NEWS}news?include=country,categories&filters[country]=${state.filterSelectedCountries}&filters[status]=${state.filterSelectedStatus}&filters[keywords]=${state.filterSearchInput}&filters[category]=${payload ? payload.hasOwnProperty('category') ? payload.category : '' : ''}&filters[tag]=${payload ? payload.hasOwnProperty('tag') ? payload.tag : '' : ''}&limit=${state.limit}&skip=${state.skip}`)

      commit(NEWS_SET_LIST, response.data.data)
      commit(NEWS_SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(NEWS_FETCH_LIST_LOADING, false)
    }
  },
  [NEWS_LIST_DELETE_ITEM]: async ({ commit }, payload) => {
    try {
      commit(NEWS_REMOVE_LIST_ITEM_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_NEWS}${selectedModule(payload)}/${payload.id}`)
      commit(NEWS_FRESH_LIST, payload)
    } catch (e) {
      throw e
    } finally {
      commit(NEWS_REMOVE_LIST_ITEM_LOADING, false)
    }
  },
  [GET_SETTING]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_NEWS}setting`)
      commit(SET_SETTING, response.data.data)
    } catch (e) {
      throw e
    }
  }
}

const mutations = {
  [NEWS_SET_LIST] (state, list) {
    state.newsList = list
  },
  [NEWS_REMOVE_LIST_ITEM_LOADING] (state, status) {
    state.removeListItemLoading = status
  },
  [NEWS_FRESH_LIST] (state, params) {
    const index = state.newsList.findIndex(e => e.id === params.id)
    const obj = state.newsList[index]
    obj.status = 'deprecated'

    Vue.set(state.newsList, index, obj)
  },
  [NEWS_SET_LIST_LENGTH] (state, length) {
    state.newsListLength = length
  },
  [NEWS_FILTER_CHANGE_SEARCH_INPUT] (state, text) {
    state.filterSearchInput = text
  },
  [NEWS_FILTER_CHANGE_COUNTRY] (state, text) {
    state.filterSelectedCountries = text
  },
  [NEWS_FILTER_CHANGE_TYPES] (state, text) {
    state.filterSelectedStatus = text
  },
  [NEWS_FETCH_LIST_LOADING] (state, status) {
    state.newsListLoading = status
  },
  [NEWS_LIST_CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [SET_SETTING] (state, count) {
    state.setting = count
  },
  [RESET] (state) {
    state.newsList = []
    state.newsListLength = 0
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
