export default [
  // {
  //   path: 'setting/translations/labels',
  //   name: 'setting.translations.labels',
  //   component: () => import('@/modules/translations/pages/labels/index.vue'),
  //   meta: {}
  // },
  // {
  //   path: 'setting/translations/text',
  //   name: 'setting.translations.text',
  //   component: () => import('@/modules/translations/pages/texts/index.vue'),
  //   meta: {}
  // },
  {
    path: 'setting/translations/:group',
    name: 'translations.group',
    component: () => import('@/modules/translations/index.vue'),
    meta: {}
  },
  {
    path: 'setting/locales',
    name: 'setting.locales',
    component: () => import('@/modules/setting/pages/locales/index.vue'),
    meta: {}
  },
  {
    path: 'setting/course-types',
    name: 'setting.course-types',
    component: () => import('@/modules/setting/pages/course-types/index.vue'),
    meta: {}
  },
  {
    path: 'setting/course-skills',
    name: 'setting.course-skills',
    component: () => import('@/modules/setting/pages/course-skills/index.vue'),
    meta: {}
  },
  {
    path: 'setting/course-categories',
    name: 'setting.course-categories',
    component: () => import('@/modules/setting/pages/course-categories/index.vue'),
    meta: {}
  },
  {
    path: 'setting/tariff-types',
    name: 'setting.tariff-types',
    component: () => import('@/modules/setting/pages/tariff-types/index.vue'),
    meta: {}
  },
  {
    path: 'setting/education-categories',
    name: 'setting.education-categories',
    component: () => import('@/modules/setting/pages/education-categories/index.vue'),
    meta: {}
  },
  {
    path: 'setting/layouts',
    name: 'setting.layouts',
    component: () => import('@/modules/setting/pages/email-layouts/index.vue'),
    meta: {}
  },
  {
    path: 'setting/templates',
    name: 'setting.templates',
    component: () => import('@/modules/setting/pages/email-templates/index.vue'),
    meta: {}
  },
  {
    path: 'setting/countries',
    name: 'setting.countries',
    component: () => import('@/modules/setting/pages/countries/index.vue'),
    meta: {}
  },
  {
    path: 'setting/niches',
    name: 'setting.niches',
    component: () => import('@/modules/setting/pages/niches/index.vue'),
    meta: {}
  },
  {
    path: 'setting/widgets',
    name: 'setting.widgets',
    component: () => import('@/modules/setting/pages/widgets/index.vue'),
    meta: {}
  },
  {
    path: 'setting/getaway',
    name: 'setting.getaway',
    component: () => import('@/modules/setting/pages/getaway/index.vue'),
    meta: {}
  },
  {
    path: 'setting/getaway-providers',
    name: 'setting.getaway.providers',
    component: () => import('@/modules/setting/pages/getaway-providers/index.vue'),
    meta: {}
  },
  {
    path: 'setting/stores',
    name: 'setting.stores',
    component: () => import('@/modules/setting/pages/stores/index.vue'),
    meta: {}
  }
]
