import { $http } from '@/utils/https'
import Vue from 'vue'

import {
  GET_LIST,
  CREATE,
  DELETE,
  UPDATE,
  UPDATE_SORT
} from './types/action-types'
import {
  DELETE_LIST_ITEM,
  UPDATE_LIST_ITEM,
  LIST_LOADING,
  FRESH_LOADING,
  SET_LIST,
  SET_NEW_ITEM,
  CHANGE_EDIT_ID,
} from './types/mutation-types'

const state = {
  listLoading: false,
  list: null,
  loading: false,

  editId: ''
}

const getters = {
  list: state => state.list || [],
  listLoading: state => state.listLoading,
  loading: state => state.loading,

  editId: state => state.editId
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/home-works/${payload.homeWork_id}/contents?include=packages,type,status,chapters,image`)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(LIST_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/home-works/${payload.homeWork_id}/contents/${payload.id}`)
      commit(DELETE_LIST_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [CREATE]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/modules/${payload.get('module_id')}/home-works/${payload.get('homeWork_id')}/contents?include=packages,status,type,chapters`, payload)
      commit(SET_NEW_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [UPDATE]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/modules/${payload.get('module_id')}/home-works/${payload.get('homeWork_id')}/contents/${payload.get('id')}?include=packages,status,type,chapters`, payload)
      commit(UPDATE_LIST_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [UPDATE_SORT]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/home-works/${payload.homeWork_id}/contents/sort?include=packages,status,type`, payload)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [LIST_LOADING] (state, status) {
    state.listLoading = status
  },
  [SET_NEW_ITEM] (state, item) {
    state.list.push(item)
  },
  [UPDATE_LIST_ITEM] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.set(state.list, index, item)
  },
  [DELETE_LIST_ITEM] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.delete(state.list, index)
  },
  [CHANGE_EDIT_ID] (state, id) {
    state.editId = id
  },
  [FRESH_LOADING] (state, status) {
    state.loading = status
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}
