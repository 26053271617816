import { $http } from '@/utils/https'
import { camelCase } from 'lodash'
import Vue from 'vue'

import {
  GET_LIST_LOADING,
  SET_LIST,
  CHANGES_LOADING,
  FRESH_LIST_ITEM,
  REMOVE_LIST_ITEM,
  ADD_LIST_ITEM
} from './types/mutation-types'

import {
  GET_LIST,
  DELETE,
  UPDATE,
  CREATE,
  UPDATE_SORT
} from './types/action-types'

const state = {
  isListLoading: false,
  isLoading: false,
  list: []
}

const getters = {
  list: state => {
    return state.list.map(e => {
      const obj = {}
      for (const i in e) {
        obj[camelCase(i)] = e[i]
      }
      return obj
    })
  },
  isListLoading: state => state.isListLoading,
  isLoading: state => state.isLoading
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(GET_LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/socials?include=status,packages`)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_LIST_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(CHANGES_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/socials/${payload.id}`)
      commit(REMOVE_LIST_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGES_LOADING, false)
    }
  },
  [UPDATE]: async ({ commit }, payload) => {
    try {
      commit(CHANGES_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/socials/${payload.id}?include=status,packages`, payload)
      commit(FRESH_LIST_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGES_LOADING, false)
    }
  },
  [CREATE]: async ({ commit }, payload) => {
    try {
      commit(CHANGES_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/socials?include=status,packages`, payload)
      commit(ADD_LIST_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGES_LOADING, false)
    }
  },
  [UPDATE_SORT]: async ({ commit }, payload) => {
    try {
      commit(CHANGES_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/socials/sort?include=status,packages`, payload)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGES_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [GET_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [CHANGES_LOADING] (state, status) {
    state.isLoading = status
  },
  [ADD_LIST_ITEM] (state, item) {
    state.list.push(item)
  },
  [FRESH_LIST_ITEM] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.set(state.list, index, params)
  },
  [REMOVE_LIST_ITEM] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
