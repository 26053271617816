import Vue from 'vue'
import { $http } from '@/utils/https'
import { DEFAULT_FILTERS } from '@/modules/user-certificate/constants'
import {
  COURSE_LIST,
  GET_LIST,
  GENERATE_ALL,
  GENERATE_USER,
  REMOVE_USER,
} from './types/action-types'
import {
  CHANGE_FILTER,
  CHANGE_LIST_LOADING,
  CHANGE_LOADING,
  CHANGE_SKIP,
  CHANGE_SORT,
  SET_COURSE_LIST,
  SET_LIST,
  SET_LIST_LENGTH,
  CHANGE_GENERATE_ALL_LOADING,
  CHANGE_GENERATE_USER_LOADING,
  CHANGE_REMOVE_USER_LOADING,
  SET_ITEM_CERTIFICATE,
  REMOVE_ITEM_CERTIFICATE,
} from './types/mutation-types'
import camelcaseObjectDeep from 'camelcase-object-deep'

const state = {
  list: [],
  isLoading: false,
  isListLoading: false,
  limit: 20,
  skip: 0,
  listLength: 0,
  defaultSortData: {
    field: 'points',
    direction: 'desc',
  },
  sortData: {},
  filters: DEFAULT_FILTERS,
  isLoadingCourse: false,
  simpleList: null,
  filterCourseId: null,
  isGenerateAllLoading: false,
  isGenerateUserLoading: false,
  isRemoveUserLoading: false,
}

const getters = {
  list: state => state.list,
  listLength: state => state.listLength,
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  limit: state => state.limit,
  skip: state => state.skip,
  filters: state => state.filters,
  simpleList: state => state.simpleList,
  filterCourseId: state => state.filterCourseId,
  isGenerateAllLoading: state => state.isGenerateAllLoading,
  isGenerateUserLoading: state => state.isGenerateUserLoading,
  isRemoveUserLoading: state => state.isRemoveUserLoading
}

const actions = {
  [GET_LIST]: async ({ commit }, { courseId }) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const {
        data: {
          data,
          meta
        }
      } = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${courseId}/users`, {
        params: {
          include: 'user.personalData,package,userCertificate.certificate.type,status',
          skip: state.skip,
          limit: state.limit,
          'filters[status_id]': state.filters.status || '',
          'filters[package_id]': state.filters.package || '',
          'filters[certificate_id]': state.filters.certificate || '',
          'filters[user_id]': state.filters.user || '',
          'sorting[field]': state.sortData.field === undefined ? state.defaultSortData.field : state.sortData.field,
          'sorting[direction]': state.sortData.direction === undefined ? state.defaultSortData.direction : state.sortData.direction,
        }
      })

      commit(SET_LIST, data)
      commit(SET_LIST_LENGTH, meta.total)
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [COURSE_LIST]: async ({ commit }, payload) => {
    const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/list?filters[id]=${payload.courseId}`)
    commit(SET_COURSE_LIST, response.data.data)
  },
  [GENERATE_ALL]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_GENERATE_ALL_LOADING, true)
      $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/users/certificates`)
    } finally {
      commit(CHANGE_GENERATE_ALL_LOADING, false)
    }
  },
  [GENERATE_USER]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_GENERATE_USER_LOADING, true)
      let response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/users/${payload.userId}/certificates?include=certificate.type`)
      commit(SET_ITEM_CERTIFICATE, response.data.data)
    } catch (e){
      throw e
    } finally {
      commit(CHANGE_GENERATE_USER_LOADING, false)
    }
  },
  [REMOVE_USER]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_REMOVE_USER_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/users/${payload.userId}/certificates/${payload.userCertificateId}`)
      commit(REMOVE_ITEM_CERTIFICATE, payload)
    } finally {
      commit(CHANGE_REMOVE_USER_LOADING, false)
    }
  },
}

const mutations = {
  [SET_LIST](state, list) {
    state.list = camelcaseObjectDeep(list)
  },
  [SET_LIST_LENGTH](state, length) {
    state.listLength = length
  },
  [SET_ITEM_CERTIFICATE](state, data) {
    data = camelcaseObjectDeep(data)
    const index = state.list.findIndex(e => e.courseId === data.courseId && e.userId === data.userId)
    let item = state.list[index]
    item.userCertificate.data = data
    Vue.set(state.list, index, item)
  },
  [REMOVE_ITEM_CERTIFICATE](state, data) {
    const index = state.list.findIndex(e => e.courseId === data.courseId && e.userId === data.userId)
    let item = state.list[index]
    item.userCertificate.data = []
    Vue.set(state.list, index, item)
  },
  [CHANGE_LOADING](state, status) {
    state.isLoading = status
  },
  [CHANGE_LIST_LOADING](state, status) {
    state.isListLoading = status
  },
  [CHANGE_GENERATE_ALL_LOADING](state, status) {
    state.isGenerateAllLoading = status
  },
  [CHANGE_GENERATE_USER_LOADING](state, status) {
    state.isGenerateUserLoading = status
  },
  [CHANGE_REMOVE_USER_LOADING](state, status) {
    state.isRemoveUserLoading = status
  },
  [CHANGE_SKIP](state, count) {
    state.skip = count
  },
  [CHANGE_SORT](state, data) {
    state.sortData = data
  },
  [CHANGE_FILTER](state, data = null) {
    if (!data) {
      for (const i in state.filters) {
        state.filters[i] = ''
      }
      return
    }
    state.filters[data.type] = data.value
  },
  [SET_COURSE_LIST](state, data) {
    state.simpleList = data
  },
  setFilterCourseId(state, data) {
    state.filterCourseId = data
  },
  setFilterData(state, data) {
    state.filters = data
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
