import Vue from 'vue'
import { $http } from '@/utils/https'
import {
  CHANGE_DIALOG_VIEW,
  CHANGE_LIST_LOADING,
  CHANGE_LOADING,
  CHANGE_SKIP,
  REMOVE_LIST_ITEM,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH
} from './types/mutation-types'

import { CREATE, DELETE, GET_LIST, UPDATE } from './types/action-types'
import { transformerScoredPointsList } from '@/transformers/course'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: [],
  isLoading: false,
  isListLoading: false,
  currentItem: null,
  limit: 13,
  skip: 0,
  listLength: 0,
  openDialogView: false
}

const getters = {
  list: state => transformerScoredPointsList(state.list),
  isLoading: state => state.isLoading,
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  isListLoading: state => state.isListLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  skip: state => state.skip,
  openDialogView: state => state.openDialogView
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/points?include=user.personalData,admin.personalData&filters[keywords]=${payload.keywords || ''}&limit=${state.limit}&skip=${state.skip}`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('courseId')}/points/${payload.get('id')}?include=user.personalData,admin.personalData`, payload)

      commit(CHANGE_DIALOG_VIEW, false)
      commit(SET_ITEM, response.data.data.course_id)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('courseId')}/points?include=user.personalData,admin.personalData`, payload)

      commit(CHANGE_DIALOG_VIEW, false)
      dispatch(GET_LIST, { courseId: response.data.data.course_id })
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/points/${payload.id}`)
      commit(REMOVE_LIST_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [CHANGE_DIALOG_VIEW] (state, status) {
    state.openDialogView = status
  },
  [REMOVE_LIST_ITEM] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
