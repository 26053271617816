import { $http } from '@/utils/https'
import Vue from 'vue'
import { transformerCurrentUser, transformerUsersList, transformerUsersAdminsList } from '@/transformers/user'
import {
  CHANGE_FILTER,
  CHANGE_LOADING,
  CHANGE_SKIP,
  CHANGE_USER_PERMISSIONS,
  FETCH_LIST_LOADING,
  FRESH_IMAGE,
  FRESH_LIST,
  FRESH_USER_COUNTRIES,
  FRESH_USER_PERMISSIONS,
  FRESH_USER_ROLES,
  GET_CURRENT_LOADING,
  RESET_USER_PERMISSIONS,
  SET_CONTACTS_CHANGES,
  SET_CURRENT_SERVICE_PERMISSIONS,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH,
  SET_UPDATED_CONTACTS,
  SET_USER_PERMISSIONS,
  SET_USER_SETTINGS,
  UPDATE_IMAGE_LOADING
} from './types/mutation-types'

import {
  CREATE_USER,
  DELETE_USER,
  FETCH_LIST,
  GET_CHANGE_CONTACTS,
  GET_LIST_ITEM,
  UPDATE_CONTACTS,
  UPDATE_COUNTRIES,
  UPDATE_IMAGE,
  UPDATE_PERMISSIONS,
  UPDATE_ROLES,
  UPDATE_SETTINGS,
  UPDATE_USER
} from './types/action-types'
import { uniq } from 'lodash'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  isListLoading: false,
  list: [],
  listLength: 0,
  limit: 30,
  skip: 0,
  updateImageLoading: false,

  filter: {
    keywords: '',
    batch: '',
    country: '',
    course: '',
    isActive: '',
    courseUserId: ''
  },

  isLoading: false,
  currentStudent: null,
  currentStudentLoading: false,

  userPermissions: {},
  settings: null,
  contactsChangesList: []
}

const getters = {
  list: state => transformerUsersAdminsList(state.list),
  isListLoading: state => state.isListLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  skip: state => state.skip,
  filter: state => state.filter,
  updateImageLoading: state => state.updateImageLoading,
  currentStudent: state => transformerCurrentUser(state.currentStudent),
  currentStudentLoading: state => state.currentStudentLoading,
  isLoading: state => state.isLoading,
  settings: state => state.settings ? camelcaseObjectDeep(state.settings) : null,
  userPermissions: state => state.userPermissions,
  contactsChangesList: state => camelcaseObjectDeep(state.contactsChangesList)
}

const actions = {
  [FETCH_LIST]: async ({ commit }, payload) => {
    try {
      commit(FETCH_LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}users/admins?include=countries,personalData&filters[keywords]=${payload && payload.keywords ? payload.keywords : state.filter.keywords || ''}&filters[course_users]=${payload && payload.courseUserId ? payload.courseUserId : state.filter.courseUserId || ''}&limit=${state.limit}&skip=${state.skip}&filters[active]=${state.filter.isActive ? 1 : 0}&filters[country]=${state.filter.country || ''}`)
      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(FETCH_LIST_LOADING, false)
    }
  },
  [DELETE_USER]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_USER}users/admins/${payload.id}`)
      commit(FRESH_LIST, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE_USER]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/admins`, payload)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_USER]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.post(`${process.env.VUE_APP_ROOT_USER}users/admins/${payload.get('id')}`, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_COUNTRIES]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/admins/${payload.userId}/access-country?include=countries`, payload)
      commit(FRESH_USER_COUNTRIES, response.data.data.countries)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_ROLES]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/${payload.get('userId')}/roles?include=roles`, payload)
      commit(FRESH_USER_ROLES, response.data.data.roles)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_PERMISSIONS]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/${payload.userId}/permissions?include=permissions`, payload)
      commit(FRESH_USER_PERMISSIONS, response.data.data.permissions)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }, payload) => {
    try {
      commit(GET_CURRENT_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}users/admins/${payload.id}?include=permissions,setting,setting.timezone,countries,personalData,roles,permissions,socials,confirmations,author`)

      commit(SET_USER_PERMISSIONS, response.data.data.permissions.data)
      commit(SET_ITEM, response.data.data)
      commit(SET_USER_SETTINGS, response.data.data.setting.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_CURRENT_LOADING, false)
    }
  },
  [UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/admins/${payload.get('user_id')}/images/${payload.get('id')}`, payload)
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/admins/${payload.get('user_id')}/images`, payload)
      }
      commit(FRESH_IMAGE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_IMAGE_LOADING, false)
    }
  },
  [UPDATE_SETTINGS]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/${payload.userId}/settings?include=timezone`, payload)
      commit(SET_USER_SETTINGS, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  // update phone or email
  [UPDATE_CONTACTS]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/${payload.userId}/personal-data-requests/${payload.id}/${payload.type}`, payload)
      commit(SET_UPDATED_CONTACTS, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [GET_CHANGE_CONTACTS]: async ({ commit }, payload) => {
    try {
      commit(FETCH_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}users/${payload.userId}/personal-data-requests`)
      commit(SET_CONTACTS_CHANGES, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(FETCH_LIST_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_CONTACTS_CHANGES] (state, list) {
    state.contactsChangesList = list
  },
  [SET_UPDATED_CONTACTS] (state, data) {
    const index = state.contactsChangesList.findIndex(e => e.id === data.id)
    Vue.set(state.contactsChangesList, index, data)
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [FRESH_USER_COUNTRIES] (state, data) {
    state.currentStudent.countries = data
  },
  [FRESH_USER_ROLES] (state, data) {
    state.currentStudent.roles = data
  },
  [FRESH_LIST] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [SET_ITEM] (state, data) {
    state.currentStudent = data
  },
  [GET_CURRENT_LOADING] (state, status) {
    state.currentStudentLoading = status
  },
  [FETCH_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [SET_USER_SETTINGS] (state, data) {
    state.settings = data
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [FRESH_IMAGE] (state, data) {
    state.currentStudent.avatar = data
  },
  [CHANGE_FILTER] (state, data) {
    state.filter = data
  },
  [FRESH_USER_PERMISSIONS] (state, data) {
    state.currentStudent.permissions = data
  },
  [RESET_USER_PERMISSIONS] (state) {
    state.userPermissions = {}
  },
  [SET_CURRENT_SERVICE_PERMISSIONS] (state, data) {
    if (data.key === 'add') {
      if (!state.userPermissions.hasOwnProperty(data.microservice)) {
        state.userPermissions[data.microservice] = data.ids
      } else {
        const arr = [...state.userPermissions[data.microservice], ...data.ids]
        const uniqArr = uniq(arr)
        Vue.set(state.userPermissions, data.microservice, uniqArr)
      }
    } else {
      const filteredArr = state.userPermissions[data.microservice].filter(e => data.ids.indexOf(e) < 0)
      Vue.set(state.userPermissions, data.microservice, filteredArr)
    }
  },
  [SET_USER_PERMISSIONS] (state, list) {
    const filteredList = list.filter(e => e.id !== 1)
    filteredList.forEach(data => {
      if (state.userPermissions.hasOwnProperty(data.microservice)) {
        state.userPermissions[data.microservice].push(data.id)
      } else {
        state.userPermissions[data.microservice] = [data.id]
      }
    })
  },
  [CHANGE_USER_PERMISSIONS] (state, data) {
    if (state.userPermissions.hasOwnProperty(data.microservice)) {
      if (data.isAddItem) {
        state.userPermissions[data.microservice].push(data.id)
      } else {
        const index = state.userPermissions[data.microservice].findIndex(e => e === data.id)
        Vue.delete(state.userPermissions[data.microservice], index)
      }
    } else {
      state.userPermissions[data.microservice] = [data.id]
    }
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
