import { $http } from '@/utils/https'
import Vue from 'vue'

import {
  GET_LIST_LOADING,
  SET_LIST,
  SET_NEW_MODULE,
  MODULE_LOADING,
  DELETE_MODULES_ITEM,
  FRESH_MODULE,
  MODULE_FRESH_IMAGE,
  MODULE_UPDATE_IMAGE_LOADING,
  SET_ITEM
} from './types/mutation-types'

import {
  UPDATE_SORT,
  GET_LIST,
  CREATE_MODULE,
  UPDATE_MODULE,
  DELETE_MODULE,
  MODULE_UPDATE_IMAGE
} from './types/action-types'

const state = {
  listLoading: false,
  list: null,
  currentModule: null,
  currentItem: null,
  moduleLoading: false,
  removeListItemLoading: false,
  updateImageLoading: false
}

const getters = {
  list: state => state.list || [],
  listLoading: state => state.listLoading,
  currentModule: state => state.currentModule,
  currentItem: state => state.currentItem,
  moduleLoading: state => state.moduleLoading,
  removeListItemLoading: state => state.removeListItemLoading,
  updateImageLoading: state => state.updateImageLoading
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(GET_LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules?include=lessons,packages,status,image,image.seo`)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_LIST_LOADING, false)
    }
  },
  [DELETE_MODULE]: async ({ commit }, payload) => {
    try {
      commit(MODULE_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.id}`)
      commit(DELETE_MODULES_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(MODULE_LOADING, false)
    }
  },
  [CREATE_MODULE]: async ({ commit }, payload) => {
    try {
      commit(MODULE_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules?include=lessons,packages,status,image`, payload)
      commit(SET_NEW_MODULE, response.data.data)
      commit(SET_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(MODULE_LOADING, false)
    }
  },
  [UPDATE_MODULE]: async ({ commit }, payload) => {
    try {
      commit(MODULE_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.id}?include=lessons,packages,status,image`, payload)
      commit(FRESH_MODULE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(MODULE_LOADING, false)
    }
  },
  [UPDATE_SORT]: async ({ commit }, payload) => {
    try {
      commit(MODULE_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/sort?include=lessons,packages,status,image,image.seo`, payload)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(MODULE_LOADING, false)
    }
  },
  [MODULE_UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(MODULE_UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/modules/${payload.get('module_id')}/images/${payload.get('id')}`, payload)
        response.data.data.isEdit = true
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/modules/${payload.get('module_id')}/images`, payload)
        response.data.data.isEdit = false
      }
      response.data.data.moduleId = payload.get('module_id')
      commit(MODULE_FRESH_IMAGE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(MODULE_UPDATE_IMAGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_ITEM] (state, list) {
    state.currentItem = list
  },
  [GET_LIST_LOADING] (state, status) {
    state.listLoading = status
  },
  [MODULE_LOADING] (state, status) {
    state.moduleLoading = status
  },
  [SET_NEW_MODULE] (state, item) {
    state.list.unshift(item)
  },
  [FRESH_MODULE] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.set(state.list, index, item)
  },
  [DELETE_MODULES_ITEM] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.delete(state.list, index)
  },
  [MODULE_UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [MODULE_FRESH_IMAGE] (state, data) {
    const index = state.list.findIndex(e => e.id === parseInt(data.moduleId))
    if (data.isEdit) {
      state.list[index].image.data.src = data.src
    } else {
      state.list[index].image.data = data
    }
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
