import { $http } from '@/utils/https'// eslint-disable-next-line
import {
  AUTH_SET_TOKEN,
  AUTH_REMOVE_TOKEN,
  AUTH_LOADING_SING_IN
} from '../mutation-types'
import {
  AUTH_LOGIN,
  AUTH_LOGOUT
} from '../action-types'

const state = {
  isAuthenticated: false,
  loadingSingIn: false
}

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  loadingSingIn: state => state.loadingSingIn
}

const actions = {
  [AUTH_LOGIN]: async ({ commit }, payload) => {
    try {
      commit(AUTH_LOADING_SING_IN, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_AUTH}tokens`, payload)
      commit(AUTH_SET_TOKEN, response.data.data)
      return response
    } catch (e) {
      console.log(e);
      throw e
    } finally {
      commit(AUTH_LOADING_SING_IN, false)
    }
  },
  [AUTH_LOGOUT]: async ({ commit }) => {
    try {
      // return await $http.post('v1/logout')
    } catch (e) {
      console.log(e);
      throw e
    } finally {
      commit(AUTH_REMOVE_TOKEN)
    }
  }
}

const mutations = {
  [AUTH_LOADING_SING_IN] (state, status) {
    state.loadingSingIn = status;
  },
  [AUTH_SET_TOKEN] (state, data) {
    localStorage.setItem('user_token', data[0].token);
    localStorage.setItem('user_refresh', data[1].token);
    state.isAuthenticated = true;
  },
  [AUTH_REMOVE_TOKEN] (state) {
    localStorage.removeItem('user_token');
    localStorage.removeItem('user_refresh');
    localStorage.removeItem('is_admin');
    state.isAuthenticated = false;
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
