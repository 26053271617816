import Vue from 'vue'
import { $http } from '@/utils/https'
import { transformerSeo } from '@/transformers'
import { camelCase } from 'lodash'

import {
  ADD_LIST_ITEM,
  ADD_SEO_ITEM,
  CHANGE_EDIT_ID,
  CHANGE_FILTER,
  CHANGE_MATERIAL_TYPE,
  CHANGE_SKIP,
  CHANGES_LOADING,
  FRESH_SEO_ITEM,
  GET_LIST_LOADING,
  ITEM_LOADING,
  REMOVE_LIST_ITEM,
  RESET,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH,
  SET_NEW_ITEM,
  SEO_LOADING,
  SET_SEO,
  UPDATE_LIST_ITEM,
  UPDATE_SEO_LOADING, UPDATE_IMAGE_LOADING, FRESH_IMAGE
} from './types/mutation-types'

import {
  CREATE,
  DELETE,
  GET_ITEM,
  GET_LIST,
  GET_SEO,
  UPDATE, UPDATE_IMAGE,
  UPDATE_SEO,
  UPDATE_SORT
} from './types/action-types'

const state = {
  isListLoading: false,
  isLoading: false,
  isItemLoading: false,
  list: [],
  currentItem: null,
  filterKeywords: '',
  listLength: 0,
  limit: 10,
  skip: 0,
  editId: '',
  selectedMaterialType: '',

  seo: null,
  seoLoading: false,
  updateSeoLoading: false,
  updateImageLoading: false
}

const getters = {
  list: state => {
    return state.list.map(e => {
      const obj = {}
      for (const i in e) {
        obj[camelCase(i)] = e[i]
      }
      return obj
    })
  },
  isListLoading: state => state.isListLoading,
  isLoading: state => state.isLoading,
  filterKeywords: state => state.filterKeywords,
  listLength: state => state.listLength,
  limit: state => state.limit,
  skip: state => state.skip,
  editId: state => state.editId,
  currentItem: state => state.currentItem,
  isItemLoading: state => state.isItemLoading,
  selectedMaterialType: state => state.selectedMaterialType,
  updateImageLoading: state => state.updateImageLoading,

  seo: state => transformerSeo(state.seo),
  seoLoading: state => state.seoLoading,
  updateSeoLoading: state => state.updateSeoLoading
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    commit(RESET)
    try {
      commit(GET_LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/${state.selectedMaterialType}?include=status,image,image.seo,${state.selectedMaterialType === 'bonuses' ? 'authors' : 'speakers'},packages&limit=${state.limit}&skip=${state.skip}&filters[keywords]=${state.filterKeywords || ''}`)
      commit(RESET)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(GET_LIST_LOADING, false)
    }
  },
  [GET_ITEM]: async ({ commit }, payload) => {
    try {
      commit(ITEM_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/${state.selectedMaterialType}/${payload.id}?include=packages,image,image.seo,status,${state.selectedMaterialType === 'bonuses' ? 'authors' : 'speakers'}`)
      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(ITEM_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(CHANGES_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/${state.selectedMaterialType}/${payload.id}`)
      commit(REMOVE_LIST_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGES_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGES_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/${state.selectedMaterialType}?include=packages,status`, payload)
      commit(SET_NEW_ITEM, response.data.data)
      commit(CHANGE_EDIT_ID, response.data.data.id)

      dispatch(GET_LIST, response.data.data)
      dispatch(GET_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGES_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGES_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/${state.selectedMaterialType}/${payload.get('id')}?include=packages,status,${payload.get('authors') ? 'authors' : 'speakers'}`, payload)
      dispatch(GET_LIST, response.data.data)
      dispatch(GET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGES_LOADING, false)
    }
  },
  [UPDATE_SORT]: async ({ commit }, payload) => {
    try {
      commit(GET_LIST_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/${state.selectedMaterialType}/sort?include=status`, payload)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_LIST_LOADING, false)
    }
  },
  [GET_SEO]: async ({ commit }, payload) => {
    try {
      commit(SEO_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/${state.selectedMaterialType}/${payload.id}/seo`)
      commit(SET_SEO, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SEO_LOADING, false)
    }
  },
  [UPDATE_SEO]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_SEO_LOADING, true)

      if (payload.hasOwnProperty('_method')) {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/${state.selectedMaterialType}/${payload.material_id}/seo/${payload.id}`, payload)
        commit(FRESH_SEO_ITEM, response.data.data)
      } else {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/${state.selectedMaterialType}/${payload.material_id}/seo`, payload)
        commit(ADD_SEO_ITEM, response.data.data)
      }
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_SEO_LOADING, false)
    }
  },
  [UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/${state.selectedMaterialType}/${payload.get('material_id')}/images/${payload.get('id')}?include=seo`, payload)
        response.data.data.isEdit = true
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/${state.selectedMaterialType}/${payload.get('material_id')}/images?include=seo`, payload)
        response.data.data.isEdit = false
      }
      commit(FRESH_IMAGE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_IMAGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_LIST_LENGTH] (state, count) {
    state.listLength = count
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [GET_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [CHANGES_LOADING] (state, status) {
    state.isLoading = status
  },
  [ADD_LIST_ITEM] (state, item) {
    state.list.push(item)
  },
  [CHANGE_FILTER] (state, params) {
    switch (params.type) {
      case 'keywords': {
        state.filterKeywords = params.value
        break
      }
      default: {
        state.filterKeywords = params.value
      }
    }
  },
  [CHANGE_EDIT_ID] (state, id) {
    state.editId = id
  },
  [SET_ITEM] (state, data) {
    if (data && data.hasOwnProperty('authors')) {
      data.speakers = data.authors
    }
    state.currentItem = data
  },
  [ITEM_LOADING] (state, status) {
    state.isItemLoading = status
  },
  [SET_NEW_ITEM] (state, item) {
    state.list.push(item)
  },
  [REMOVE_LIST_ITEM] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  },
  [UPDATE_LIST_ITEM] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.set(state.list, index, item)
  },
  [CHANGE_MATERIAL_TYPE] (state, type) {
    state.selectedMaterialType = type
  },
  [RESET] (state) {
    state.list = []
    state.listLength = 0
    state.skip = 0
    state.editId = ''
  },
  [UPDATE_SEO_LOADING] (state, status) {
    state.updateSeoLoading = status
  },
  [SEO_LOADING] (state, status) {
    state.seoLoading = status
  },
  [SET_SEO] (state, content) {
    state.seo = content
  },
  [FRESH_SEO_ITEM] (state, data) {
    const index = state.seo.findIndex(e => e.locale === data.locale)
    Vue.set(state.seo, index, data)
  },
  [ADD_SEO_ITEM] (state, content) {
    state.seo.push(content)
  },
  [UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [FRESH_IMAGE] (state, data) {
    state.currentItem.image.data = data
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
