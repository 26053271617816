import { $http } from '@/utils/https';
import router from '@/router/index';
import { LOADING_USER_DATA, SET_COUNTRY_IDS, SET_USER_DATA, UPDATE_USER_DATA_LOADING } from '../mutation-types'

import { FETCH_USER_DATA, UPDATE_USER_DATA, AUTH_LOGOUT } from '../action-types'
import { uniq } from 'lodash'

const state = {
  profile: null,
  isAdmin: false,
  locales: [],
  accessCountries: null,
  loadingUser: false,
  updateUserLoading: false,
  showPopupUpdatePassword: false,
  updatePasswordLoading: false,
  defaultCountries: null
}

const getters = {
  locales: state => state.locales,
  updatePasswordLoading: state => state.updatePasswordLoading,
  showPopupUpdatePassword: state => state.showPopupUpdatePassword,
  profile: state => state.profile,
  isAdmin: state => state.isAdmin,
  accessCountries: state => {
    if (state.profile) {
      const filteredArr = state.profile.countries.data.filter(e => e.type === 'access')
      return filteredArr.map(e => {
        return {
          ...e.country.data,
          country_id: e.country_id
        }
      })
    }
  },
  defaultCountries: state => {
    if (state.profile) {
      const filteredArr = state.profile.countries.data.filter(e => e.type === 'default')
      return filteredArr.map(e => {
        return {
          ...e.country.data,
          country_id: e.country_id
        }
      })
    }
  },
  loadingUser: state => state.loadingUser,
  updateUserLoading: state => state.updateUserLoading
}

const actions = {
  [FETCH_USER_DATA] : async ({ dispatch, commit }) => {
    try {
      commit(LOADING_USER_DATA, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}profile?include=permissions,personalData,roles,countries,countries.locales,countries.timezones`)
      commit(SET_USER_DATA, response.data.data)
      return response
    } catch (e) {
      if (e.response.status !== undefined && e.response.status === 403) {
        dispatch(`auth/${AUTH_LOGOUT}`, null, { root: true })
          .then(() => {
            router.push('/login');
          });
        return;
      }
      throw e;
    } finally {
      commit(LOADING_USER_DATA, false)
    }
  },
  [UPDATE_USER_DATA]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_USER_DATA_LOADING, true)
      const response = await $http.post('v1/users/update', payload)
      commit(SET_USER_DATA, response.data.data)
      return response
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_USER_DATA_LOADING, false)
    }
  }
}

const mutations = {
  [SET_USER_DATA](state, data) {
    state.profile = data;
    state.isAdmin = (data.permissions.data !== undefined && data.permissions.data.findIndex(perm => perm.name === 'administrator' || perm.name === 'superuser')) !== false;

    localStorage.setItem('is_admin', state.isAdmin);
  },
  [SET_COUNTRY_IDS](state, ids) {
    if (!state.profile) return
    const selectedCountries = state.profile.countries.data.filter(e => e)

    const selectedCountriesLocales = selectedCountries.map(e => {
      if (e.type === 'access' && ids.includes(e.country_id)) {
        return e.country.data.locales.data.map(t => t.locale)
      } else {
        return []
      }
    }).flat()

    state.locales = uniq(selectedCountriesLocales)
  },
  [LOADING_USER_DATA](state, status) {
    state.loadingUser = status
  },
  [UPDATE_USER_DATA_LOADING](state, status) {
    state.updateUserLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
