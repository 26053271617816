import { $http } from '@/utils/https'
import {
  GET_NEWS_CATEGORIES_LOADING,
  GET_NEWS_TAGS_LOADING,
  SET_NEWS_CATEGORIES,
  SET_NEWS_TAGS,
  ADD_QUICK_TAG_LOADING,
  SET_NEW_TAG
} from './types/mutation-types'

import {
  GET_NEWS_CATEGORIES,
  GET_NEWS_TAGS,
  ADD_QUICK_TAG
} from './types/action-types'

const state = {
  newsCategories: null,
  newsCategoriesLoading: false,
  newsTags: null,
  quickTagLoading: false,
  newsTagsLoading: false
}

const getters = {
  newsCategories: state => state.newsCategories,
  newsCategoriesLoading: state => state.newsCategoriesLoading,
  newsTags: state => state.newsTags,
  newsTagsLoading: state => state.newsTagsLoading,
  quickTagLoading: state => state.quickTagLoading,
}

const actions = {
  [GET_NEWS_CATEGORIES]: async ({ commit }) => {
    try {
      commit(GET_NEWS_CATEGORIES_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_NEWS}categories`)
      commit(SET_NEWS_CATEGORIES, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_NEWS_CATEGORIES_LOADING, false)
    }
  },
  [GET_NEWS_TAGS]: async ({ commit }) => {
    try {
      commit(GET_NEWS_TAGS_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_NEWS}tags`)
      commit(SET_NEWS_TAGS, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_NEWS_TAGS_LOADING, false)
    }
  },
  [ADD_QUICK_TAG]: async ({ commit }, payload) => {
    try {
      commit(ADD_QUICK_TAG_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_NEWS}tags/quick`, payload)
      commit(SET_NEW_TAG, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(ADD_QUICK_TAG_LOADING, false)
    }
  },
}

const mutations = {
  [SET_NEWS_CATEGORIES] (state, list) {
    state.newsCategories = list
  },
  [GET_NEWS_CATEGORIES_LOADING] (state, status) {
    state.newsCategoriesLoading = status
  },
  [SET_NEWS_TAGS] (state, list) {
    state.newsTags = list
  },
  [GET_NEWS_TAGS_LOADING] (state, status) {
    state.newsTagsLoading = status
  },
  [ADD_QUICK_TAG_LOADING] (state, status) {
    state.quickTagLoading = status
  },
  [SET_NEW_TAG] (state, item) {
    state.newsTags.unshift(item)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
