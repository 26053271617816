import { $http } from '@/utils/https'
import {forIn} from 'lodash';

export default {
	namespaced: true,
	state: {
		list: [],
		item: {},
		listPagination: {},
		listFilter: {
			page: null,
			limit: null,
			email: null,
			role: null
		},

		loadingGetList: false,
		loadingGetItem: false,
		loadingSaveItem: false,
		loadingDeleteItem: false,
	},

	getters: {
		listActiveFilter(state){
			let filter = {};

			forIn(state.listFilter, (val, key) => {
				if (val !== null ) filter[key] = val;
			});
			return filter
		}
	},

	mutations:{
		SET_LIST(state, list){
			state.list = list;
		},
		SET_ITEM(state, item){
			state.item = item;
		},
		SET_PAGINATION(state, pagination){
			state.listPagination = pagination;
		},
		SET_FILTER_LIST(state, {key, val}){
			state.listFilter[key] = val
		},

		SET_LOADING_STATUS(state, {value_key, status}){
			state[value_key] = status;
		}
	},

	actions: {
		GET_LIST: async({state, getters, commit}) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

				let resp = await $http.get(process.env.VUE_APP_ROOT_MENUS + 'menus', {
					params: getters.listActiveFilter

				});

				commit('SET_LIST', resp.data.data);
				// commit('SET_PAGINATION', resp.pagination);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
			}
		},

		GET_ITEM: async({state, getters, commit}, id) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

				let resp = await $http.get(process.env.VUE_APP_ROOT_MENUS + `menus/${id}`);

				commit('SET_ITEM', resp.data.data);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
			}
		},

		SAVE_ITEM: async({state, commit, dispatch }, formData) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

				let resp = await $http.post(process.env.VUE_APP_ROOT_MENUS + 'menus', formData);

				dispatch('GET_LIST');

				if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
			}
		},

		DELETE_ITEM: async({commit, dispatch }, id) => {
			try {
				commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

				let resp = await $http.delete(process.env.VUE_APP_ROOT_MENUS + `menus/${id}`);
				dispatch('GET_LIST');

				return resp;
			} catch (e) { throw e; } finally {
				commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
			}
		},
	}

};
