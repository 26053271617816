export const GET_LIST = 'GET_LIST'
export const CREATE = 'CREATE'
export const DELETE = 'DELETE'
export const UPDATE = 'UPDATE'
export const UPDATE_SORT = 'UPDATE_SORT'
export const GET_ITEM = 'GET_ITEM'
export const GET_SEO = 'GET_SEO'
export const UPDATE_SEO = 'UPDATE_SEO'
export const LESSON_UPDATE_IMAGE = 'LESSON_UPDATE_IMAGE'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'

// content
export const CONTENT_CREATE_LESSON = 'CONTENT_CREATE_LESSON'
export const CONTENT_UPDATE_LESSON = 'CONTENT_UPDATE_LESSON'
export const CONTENT_GET_LESSON = 'CONTENT_GET_LESSON'
