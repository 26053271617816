import { $http } from '@/utils/https'
import Vue from 'vue'
import {FETCH_LIST_LOADING} from './types/mutation-types';
import {map, assign, find} from 'lodash';

const state = {
	parseList: [],
	queueCount: -1,

	examples: {
		xls: `${process.env.VUE_APP_ROOT_USER}examples/users.xls`,
		csv: `${process.env.VUE_APP_ROOT_USER}examples/users.csv`,
		xlsx: `${process.env.VUE_APP_ROOT_USER}examples/users.xlsx`
	},

	hasLoadingParse: false,
	hasLoadingList: false
}

const getters = {

}

const mutations = {
	SET_PARSE_LIST(state, list){

		state.parseList = map(list, el => {
			return assign(el,{
				is_selected: 1
			})
		});
	},

	SET_QUEUE_COUNT(state, cnt){
		state.queueCount = cnt;
	},

	LOADING_PARSE(state, status){
		state.hasLoadingParse = status;
	},

	LOADING_LIST(state, status){
		state.hasLoadingList = status;
	},

	CHANGE_SELECTED_STATUS(state, {item, status}){
		let selectedItem =  find(state.parseList, ['email', item.email]);
		selectedItem.is_selected = +status;
	}
}

const actions = {
	RUN_PARSE: async ({ commit }, formData) => {
		try {
			commit('LOADING_PARSE', true);

			const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/import/parse`, formData);
			commit('SET_PARSE_LIST', response.data.data);

			commit('LOADING_PARSE', false);
			return response.data.data
		} catch (e) {
			throw e
		} finally {
			commit('LOADING_PARSE', false)
		}
	},

	RUN_QUEUE: async ({ commit }, formData) => {
		try {
			commit('LOADING_PARSE', true);

			const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/import/queue`, formData);

			commit('SET_QUEUE_COUNT', response.data.imported);

			commit('LOADING_PARSE', false);
			return response.data
		} catch (e) {
			throw e
		} finally {
			commit('LOADING_PARSE', false)
		}
	},

	SAVE_PARSE_LIST: async ({ commit, dispatch }, formData) => {
		try {
			commit('LOADING_LIST', true);

			const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/import/process?include=personalData`, formData);

			dispatch('userStudents/FETCH_LIST',{}, {root: true});
			commit('LOADING_LIST', false);
			return response.data.data
		} catch (e) {
			throw e
		} finally {
			commit('LOADING_LIST', false)
		}
	},

}



export default {
	state,
	getters,
	actions,
	mutations
}
