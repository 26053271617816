import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment'

// selected module from news
export function selectedModule (obj) {
  if (obj.module.includes('news.categories')) {
    return 'categories'
  } else if (obj.module.includes('news.tags')) {
    return 'tags'
  } else {
    return 'news'
  }
}

export default Vue.mixin({
  filters: {
    formattedDate(value, format = 'DD.MM.YYYY HH:mm') {
      if (!value) return ''
      return moment(value).format(format)
    }
  },
  computed: {
    ...mapGetters({
      user: 'profile/profile',
      isCheckSlugLoading: 'helpers/isSlugCheckingLoading',
    })
  },
  methods: {
    updateNameSlug (data, name = 'name') {
      this.payload.slug = data.slug
      this.payload[name] = data[name]
    },
    previewPage (path) {
      return `${process.env.VUE_APP_ROOT_LINK}${path}?user_token=${localStorage.getItem('user_token')}&user_refresh=${localStorage.getItem('user_refresh')}&session-id=${localStorage.getItem('session-id')}`
    },
    selectedModule (obj) {
      if (obj.module.includes('news.categories')) {
        return 'news.categories'
      } else if (obj.module.includes('news.tags')) {
        return 'news.tags'
      } else {
        return 'news'
      }
    }
  }
})
