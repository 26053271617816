import Vue from 'vue'
import { $http } from '@/utils/https'
import {
  SET_LIST,
  SET_LIST_LOADING,
  FRESH_LIST,
  CHANGE_FILTER,
  SET_LIST_LENGTH,
  SET_ITEM,
  GET_LIST_MATERIALS_LOADING,
  SET_LIST_MATERIALS
} from './types/mutation-types'

import {
  GET_LIST,
  UPDATE_SORT,
  DELETE,
  GET_LIST_MATERIALS
} from './types/action-types'

const state = {
  list: [],
  isListLoading: false,
  isLoading: false,
  currentItem: null,

  filterCountries: '',
  filterStatus: '',
  filterKeywords: '',

  listLength: 0,
  limit: 30,
  skip: 0
}

const getters = {
  list: state => state.list,
  isListLoading: state => state.isListLoading,
  isLoading: state => state.isLoading,

  filterCountries: state => state.filterCountries,
  filterStatus: state => state.filterStatus,
  filterKeywords: state => state.filterKeywords,

  listLength: state => state.listLength,
  currentItem: state => state.currentItem,
  limit: state => state.limit,
  skip: state => state.skip,
}

const actions = {
  [GET_LIST]: async ({ commit }) => {
    try {
      commit(SET_LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}categories?include=countries.country`)
      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
      return response
    } catch (e) {
      throw e
    } finally {
      commit(SET_LIST_LOADING, false)
    }
  },
  [GET_LIST_MATERIALS]: async ({ commit }) => {
    try {
      commit(GET_LIST_MATERIALS_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}categories?include=countries.country`)
      commit(SET_LIST_MATERIALS, response.data.data)
      return response
    } catch (e) {
      throw e
    } finally {
      commit(GET_LIST_MATERIALS_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(SET_LIST_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_EDUCATION}categories/${payload.id}`)
      commit(FRESH_LIST, payload)
    } catch (e) {
      throw e
    } finally {
      commit(SET_LIST_LOADING, false)
    }
  },
  [UPDATE_SORT]: async ({ commit }, payload) => {
    try {
      commit(SET_LIST_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}categories/`, payload)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SET_LIST_LOADING, false)
    }
  },
}

const mutations = {
  [SET_LIST] (state, data) {
    state.list = data
  },
  [SET_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [FRESH_LIST] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [CHANGE_FILTER] (state, params) {
    switch (params.type) {
      case 'country': {
        state.filterCountries = params.value
        break
      }
      case 'status': {
        state.filterStatus = params.value
        break
      }
      default: {
        state.filterKeywords = params.value
      }
    }
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
