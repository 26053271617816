import { $http } from '@/utils/https'
import { transformerAllUsers } from '@/transformers/user'

import {
    GET_BASIC_USERS_LOADING,
    GET_PERMISSIONS_LOADING,
    GET_SPEAKERS_LOADING,
    GET_USERS_LOADING,
    SET_BASIC_USERS,
    SET_PERMISSIONS,
    SET_SPEAKERS,
    SET_USERS,
    UPDATE_USER_PASSWORD_LOADING
} from './types/mutation-types'

import {
    GET_BASIC_USERS,
    GET_PERMISSIONS,
    GET_SPEAKERS,
    GET_USERS,
    UPDATE_USER_PASSWORD
} from './types/action-types'

const typePerson = payload => {
    if (payload.hasOwnProperty('isAuthors')) {
        return payload.isAuthors ? 'author' : 'speaker'
    }
    
    return 'speaker'
}

const state = {
    users                   : [],
    usersLoading            : false,
    basicUsers              : [],
    basicUsersLoading       : false,
    speakers                : [],
    speakersLoading         : false,
    permissions             : {},
    permissionsLoading      : false,
    updatePasswordLoading   : false
}

const getters = {
    users                   : state => transformerAllUsers(state.users),
    usersLoading            : state => state.usersLoading,
    speakers                : state => state.speakers,
    speakersLoading         : state => state.speakersLoading,
    basicUsers              : state => state.basicUsers,
    basicUsersLoading       : state => state.basicUsersLoading,
    permissions             : state => state.permissions,
    permissionsLoading      : state => state.permissionsLoading,
    updatePasswordLoading   : state => state.updatePasswordLoading
}

const actions = {
    [UPDATE_USER_PASSWORD]  : async ({ commit }, payload) => {
        try {
            commit(UPDATE_USER_PASSWORD_LOADING, true)
            
            await $http.post(`${process.env.VUE_APP_ROOT_USER}users/${payload.get('id')}/passwords`, payload)
        } catch (e) {
            throw e
        } finally {
            commit(UPDATE_USER_PASSWORD_LOADING, false)
        }
    },
    [GET_SPEAKERS]          : async ({ commit }, payload) => {
        try {
            commit(GET_SPEAKERS_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}user-positions?filters[keywords]=${payload.keywords || ''}&filters[id]=${payload.type === 'all_ids' ? '' : (payload.id || '')}&filters[positions]=${typePerson(payload)}&filters[active]=1`);
            
            commit(SET_SPEAKERS, response.data.data)
        } catch (e) {
            throw e
        } finally {
            commit(GET_SPEAKERS_LOADING, false)
        }
    },
    [GET_USERS]             : async ({ commit }, payload) => {
        try {
            commit(GET_USERS_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}users/list?skip=0&limit=15&include=defaultCountry.country,personalData&filters[keywords]=${payload.keywords}&filters[countries]=${payload.countries || ''}`)
            
            commit(SET_USERS, response.data.data);
        } catch (e) {
            throw e
        } finally {
            commit(GET_USERS_LOADING, false)
        }
    },
    [GET_PERMISSIONS]       : async ({ commit }) => {
        try {
            commit(GET_PERMISSIONS_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}permissions`)
            
            commit(SET_PERMISSIONS, response.data.data)
        } catch (e) {
            throw e
        } finally {
            commit(GET_PERMISSIONS_LOADING, false)
        }
    },
    [GET_BASIC_USERS]       : async ({ commit }, payload) => {
        try {
            commit(GET_BASIC_USERS_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}users/list?filters[keywords]=${payload.keywords || ''}`)
            
            commit(SET_BASIC_USERS, response.data.data)
        } catch (e) {
            throw e
        } finally {
            commit(GET_BASIC_USERS_LOADING, false)
        }
    }
}

const mutations = {
    [UPDATE_USER_PASSWORD_LOADING] (state, status) {
        state.updatePasswordLoading = status
    },
    [SET_SPEAKERS] (state, list) {
        state.speakers = list.map(e => {
            if (e.hasOwnProperty('authors')) {
                e.speakers = e.authors
            }
            
            return e
        })
    },
    [GET_SPEAKERS_LOADING] (state, status) {
        state.speakersLoading = status
    },
    [SET_BASIC_USERS] (state, data) {
        state.basicUsers = data
    },
    [GET_BASIC_USERS_LOADING] (state, status) {
        state.basicUsersLoading = status
    },
    [GET_USERS_LOADING] (state, status) {
        state.usersLoading = status
    },
    [SET_USERS] (state, data) {
        state.users = data
    },
    [SET_PERMISSIONS] (state, data) {
        state.permissions = data
    },
    [GET_PERMISSIONS_LOADING] (state, status) {
        state.permissionsLoading = status
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
