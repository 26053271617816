import { $http } from '@/utils/https'
import { CHANGE_LOADING } from './types/mutation-types'

import { CREATE, DELETE, UPDATE } from './types/action-types'

const state = {
  isLoading: false
}

const getters = {
  isLoading: state => state.isLoading
}

const actions = {
  [CREATE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}contents/${payload.contentId}/chapters`, payload)
      commit('educationMaterialsContent/CHANGE_VIDEO_CHAPTERS', {
        ...response.data.data,
        type: 'create'
      }, { root: true })
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}contents/${payload.contentId}/chapters/${payload.id}`, payload)
      commit('educationMaterialsContent/CHANGE_VIDEO_CHAPTERS', {
        ...response.data.data,
        type: 'update'
      }, { root: true })
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      await $http.delete(`${process.env.VUE_APP_ROOT_EDUCATION}contents/${payload.contentId}/chapters/${payload.id}`)
      commit('educationMaterialsContent/CHANGE_VIDEO_CHAPTERS', {
        ...payload,
        content_id: payload.contentId,
        type: 'delete'
      }, { root: true })
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  }
}

const mutations = {
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
