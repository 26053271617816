import {$http} from '@/utils/https'
import {translation} from '@/translations'
import {
  CHANGE_COUNTRY_LOADING,
  GET_BASIC_LOADING,
  GET_CONFIG_LOADING,
  GET_LOCALES_LOADING,
  GET_TIMEZONES_LOADING,
  SET_BASIC_COUNTRIES,
  SET_BASIC_LOCALES,
  SET_CONFIG,
  SET_COUNTRIES,
  SET_COUNTRY, SET_CURRENCIES_LIST,
  SET_LANGUAGE_INTERFACE_LIST,
  SET_LOCALES,
  SET_TIMEZONES,
  SET_TRANSLATIONS
} from './types/mutation-types'

import {
  GET_BASIC_COUNTRIES,
  GET_BASIC_LOCALES,
  GET_CONFIG,
  GET_COUNTRIES,
  GET_COUNTRY,
  GET_LANGUAGE_INTERFACE_LIST,
  GET_LOCALES,
  GET_TIMEZONES,
  GET_TRANSLATIONS,
  GET_COUNTRIES_LIST, GET_CURRENCIES_LIST
} from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  config: null,
  countries: null,
  currencies: [],
  configLoading: false,
  interfaceLanguages: null,
  selectedCountry: null,
  translations: null,
  isCountryLoading: false,
  basicCountries: [],
  basicLocales: [],
  basicLocalesLoading: false,
  timezones: [],
  timezonesLoading: false,
  locales: [],
  localesLoading: false
}

const getters = {
  interfaceLanguages: state => state.interfaceLanguages,
  translations: state => state.translations,
  config: state => state.config,
  configLoading: state => state.configLoading,
  countries: state => state.countries,
  selectedCountry: state => state.selectedCountry,
  isCountryLoading: state => state.isCountryLoading,
  basicLocales: state => state.basicLocales,
  basicCountries: state => state.basicCountries,
  basicLocalesLoading: state => state.basicLocalesLoading,
  timezones: state => camelcaseObjectDeep(state.timezones),
  timezonesLoading: state => state.timezonesLoading,
  locales: state => state.locales,
  localesLoading: state => state.localesLoading,
  currencies: state => state.currencies
}

const actions = {
  [GET_CURRENCIES_LIST]: async ({ commit }, payload) => {
    try {
      const data = {};

      for (let i = 0; i < payload.filters.slug.length; i++) {
        data[`filters[slug][${i}]`] = payload.filters.slug[i]
      }
      const response = await $http.get(`${process.env.VUE_APP_ROOT_SETTING}currencies`, {params: new URLSearchParams(data)})
      commit(SET_CURRENCIES_LIST, response.data.data)
    } catch (e) {
      throw e
    }
  },
  [GET_LANGUAGE_INTERFACE_LIST]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COUNTRY}admin/countries/${payload.id}?include=locales`)
      commit(SET_LANGUAGE_INTERFACE_LIST, response.data.data.locales.data)
    } catch (e) {
      throw e
    }
  },
  [GET_TRANSLATIONS]: async ({commit}) => {
    try {
      // const response = await $http.get(`${process.env.VUE_APP_ROOT_COUNTRY}admin/countries/1?include=currencies,timezones,locales`)
      const result = Object.assign(translation)
      commit(SET_TRANSLATIONS, result)
      return result
    } catch (e) {
      throw e
    }
  },
  [GET_COUNTRIES]: async ({commit}) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COUNTRY}admin/countries?include=timezones,locales`)
      commit(SET_COUNTRIES, response.data.data)
    } catch (e) {
      throw e
    }
  },
  [GET_COUNTRIES_LIST]: async ({commit}) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COUNTRY}admin/countries/list?include=timezones,locales`)
      commit(SET_COUNTRIES, response.data.data)
    } catch (e) {
      throw e
    }
  },
  [GET_BASIC_LOCALES]: async ({commit}) => {
    try {
      commit(GET_BASIC_LOADING, true)
      //const response = await $http.get(`${process.env.VUE_APP_ROOT_COUNTRY}admin/locales/basic`)
      let response = {}
      response.data = {"data":[{"id":0,"country_id":0,"locale":"en","default":false},{"id":0,"country_id":0,"locale":"id","default":false},{"id":0,"country_id":0,"locale":"ru","default":false},{"id":0,"country_id":0,"locale":"uk","default":false}],"meta":{"total":4,"includes":{"available":[],"default":[]}}}
      commit(SET_BASIC_LOCALES, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_BASIC_LOADING, false)
    }
  },
  [GET_LOCALES]: async ({commit}) => {
    try {
      commit(GET_LOCALES_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_SETTING}locales?limit=50`)
      commit(SET_LOCALES, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_LOCALES_LOADING, false)
    }
  },
  [GET_BASIC_COUNTRIES]: async ({commit}) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COUNTRY}admin/countries/basic`)
      commit(SET_BASIC_COUNTRIES, response.data.data)
    } catch (e) {
      throw e
    } finally {
    }
  },
  [GET_TIMEZONES]: async ({commit}, payload) => {
    try {
      commit(GET_TIMEZONES_LOADING, true)

      // const response = await $http.get(`${process.env.VUE_APP_ROOT_SETTING}timezones?filters[keywords]=${payload.keywords}`)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_SETTING}timezones?limit=500`)
      commit(SET_TIMEZONES, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_TIMEZONES_LOADING, false)
    }
  },
}

const mutations = {
  [SET_CURRENCIES_LIST] (state, list) {
    state.currencies = list
  },
  [SET_LOCALES] (state, list) {
    state.locales = list
  },
  [GET_LOCALES_LOADING](state, status) {
    state.localesLoading = status
  },
  [SET_CONFIG](state, list) {
    state.config = list
  },
  [SET_COUNTRY](state, list) {
    state.selectedCountry = list
  },
  [SET_COUNTRIES](state, list) {
    state.countries = list
  },
  [GET_CONFIG_LOADING](state, status) {
    state.configLoading = status
  },
  [SET_LANGUAGE_INTERFACE_LIST](state, list) {
    state.interfaceLanguages = list
  },
  [SET_TRANSLATIONS](state, status) {
    state.translations = status
  },
  [CHANGE_COUNTRY_LOADING](state, status) {
    state.isCountryLoading = status
  },
  [SET_BASIC_LOCALES](state, data) {
    state.basicLocales = data
  },
  [GET_BASIC_LOADING](state, status) {
    state.basicLocalesLoading = status
  },
  [SET_BASIC_COUNTRIES](state, list) {
    state.basicCountries = list
  },
  [SET_TIMEZONES](state, data) {
    state.timezones = data
  },
  [GET_TIMEZONES_LOADING](state, status) {
    state.timezonesLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
