export const GET_COURSES_LOADING = 'GET_COURSES_LOADING'
export const SET_COURSES = 'SET_COURSES'
export const SET_COURSES_LENGTH = 'SET_COURSES_LENGTH'
export const COURSES_FILTER_CHANGE = 'COURSES_FILTER_CHANGE'
export const COURSES_CHANGE_STEP = 'COURSES_CHANGE_STEP'
export const COURSES_CHANGE_LOCK_STEP = 'COURSES_CHANGE_LOCK_STEP'
export const COURSES_SET_SETTING = 'COURSES_SET_SETTING'
export const COURSES_SET_CATEGORIES = 'COURSES_SET_CATEGORIES'
export const COURSES_LIST_CHANGE_SKIP = 'COURSES_LIST_CHANGE_SKIP'
export const COURSES_SET_TYPES = 'COURSES_SET_TYPES'
export const GET_COURSES_SIMPLE_RESPONSE_LOADING = 'GET_COURSES_SIMPLE_RESPONSE_LOADING'
export const SET_COURSES_SIMPLE_RESPONSE = 'SET_COURSES_SIMPLE_RESPONSE'
export const GET_COURSE_USERS_IDS_LOADING = 'GET_COURSE_USERS_IDS_LOADING'
export const SET_COURSE_USERS_IDS = 'SET_COURSE_USERS_IDS'
export const CHANGE_DIALOG_VIEW = 'CHANGE_DIALOG_VIEW'
export const GET_COURSE_MODULES_LOADING = 'GET_COURSE_MODULES_LOADING'
export const SET_COURSE_MODULES = 'SET_COURSE_MODULES'
export const GET_COURSE_MATERIALS_LOADING = 'GET_COURSE_MATERIALS_LOADING'
export const SET_COURSE_MATERIALS = 'SET_COURSE_MATERIALS'

// info
export const INFO_SET_COURSE = 'INFO_SET_COURSE'
export const INFO_GET_COURSE_LOADING = 'INFO_GET_COURSE_LOADING'
export const SET_INFO_LOADING = 'SET_INFO_LOADING'
export const INFO_UPDATE_SEO_LOADING = 'INFO_UPDATE_SEO_LOADING'
export const INFO_SEO_LOADING = 'INFO_SEO_LOADING'
export const INFO_SET_SEO = 'INFO_SET_SEO'
export const UPDATE_SEO_ITEM = 'UPDATE_SEO_ITEM'
export const ADD_SEO_ITEM = 'ADD_SEO_ITEM'
export const INFO_UPDATE_IMAGE_LOADING = 'INFO_UPDATE_IMAGE_LOADING'
export const INFO_FRESH_IMAGE = 'INFO_FRESH_IMAGE'

// skills
export const ADD_LIST_ITEM = 'ADD_LIST_ITEM'
export const CHANGE_LIST_LOADING = 'CHANGE_LIST_LOADING'
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const CHANGE_SKIP = 'CHANGE_SKIP'
export const REMOVE_LIST_ITEM = 'REMOVE_LIST_ITEM'
export const REPLACE_LIST_ITEM = 'REPLACE_LIST_ITEM'
export const SET_LIST = 'SET_LIST'
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH'
export const SET_COURSE_SKILLS = 'SET_COURSE_SKILLS'

// packages
export const PACKAGES_GET_LIST_LOADING = 'PACKAGES_GET_LIST_LOADING'
export const PACKAGES_GET_SIMPLE_LIST_LOADING = 'PACKAGES_GET_SIMPLE_LIST_LOADING'
export const PACKAGES_SET_LIST = 'PACKAGES_SET_LIST'
export const PACKAGES_SET_SIMPLE_LIST = 'PACKAGES_SET_SIMPLE_LIST'
export const PACKAGES_CREATE_LOADING = 'PACKAGES_CREATE_LOADING'
export const PACKAGES_SET_CREATED = 'PACKAGES_SET_CREATED'
export const PACKAGES_UPDATE_LOADING = 'PACKAGES_UPDATE_LOADING'
export const PACKAGES_SET_UPDATE = 'PACKAGES_SET_UPDATE'
export const PACKAGES_UPDATE_ITEM_LOADING = 'PACKAGES_UPDATE_ITEM_LOADING'
export const PACKAGES_DELETE_ITEM = 'PACKAGES_DELETE_ITEM'
export const PACKAGES_DELETE_LOADING = 'PACKAGES_DELETE_LOADING'
export const PACKAGES_CHANGE_SKIP = 'PACKAGES_CHANGE_SKIP'
export const PACKAGES_CHANGE_LIST_LENGTH = 'PACKAGES_CHANGE_LIST_LENGTH'
export const PACKAGES_REQUEST_LOADING = 'PACKAGES_REQUEST_LOADING'

// sections
export const GET_LIST_LOADING = 'GET_LIST_LOADING'
export const FRESH_PACKAGE_ITEM = 'FRESH_PACKAGE_ITEM'
export const UPDATE_PACKAGE_LOADING = 'UPDATE_PACKAGE_LOADING'
export const SET_ITEM = 'SET_ITEM'

// appearance
export const APPEARANCE_SET_STORE = 'APPEARANCE_SET_STORE'
export const APPEARANCE_GET_STORE_LOADING = 'APPEARANCE_GET_STORE_LOADING'
export const APPEARANCE_UPDATE_STORE_LOADING = 'APPEARANCE_UPDATE_STORE_LOADING'
export const APPEARANCE_GET_HEADER_LOADING = 'APPEARANCE_GET_HEADER_LOADING'
export const APPEARANCE_SET_HEADER = 'APPEARANCE_SET_HEADER'
export const APPEARANCE_UPDATE_HEADER_LOADING = 'APPEARANCE_UPDATE_HEADER_LOADING'
export const APPEARANCE_STORE_UPDATE_IMAGE_LOADING = 'APPEARANCE_STORE_UPDATE_IMAGE_LOADING'
export const APPEARANCE_STORE_FRESH_IMAGE = 'APPEARANCE_STORE_FRESH_IMAGE'
export const APPEARANCE_HEADER_FRESH_IMAGE = 'APPEARANCE_HEADER_FRESH_IMAGE'
export const APPEARANCE_HEADER_UPDATE_IMAGE_LOADING = 'APPEARANCE_HEADER_UPDATE_IMAGE_LOADING'

// graduate
export const GRADUATE_SET_CONTENT = 'GRADUATE_SET_CONTENT'
export const GRADUATE_GET_CONTENT_LOADING = 'GRADUATE_GET_CONTENT_LOADING'
export const GRADUATE_UPDATE_CONTENT_LOADING = 'GRADUATE_UPDATE_CONTENT_LOADING'
export const FRESH_IMAGE = 'FRESH_IMAGE'
export const UPDATE_IMAGE_LOADING = 'UPDATE_IMAGE_LOADING'

// setting
export const GET_TIMETABLE_STARTING_LOADING = 'GET_TIMETABLE_STARTING_LOADING'
export const UPDATE_TIMETABLE_STARTING_LOADING = 'UPDATE_TIMETABLE_STARTING_LOADING'
export const SET_TIMETABLE_STARTING = 'SET_TIMETABLE_STARTING'

// certificates
export const CERTIFICATES_CHANGE_DIALOG_VIEW = 'CERTIFICATES_CHANGE_DIALOG_VIEW'
export const CERTIFICATES_CHANGE_LIST_LOADING = 'CERTIFICATES_CHANGE_LIST_LOADING'
export const CERTIFICATES_CHANGE_SIMPLE_LIST_LOADING = 'CERTIFICATES_CHANGE_SIMPLE_LIST_LOADING'
export const CERTIFICATES_CHANGE_LOADING = 'CERTIFICATES_CHANGE_LOADING'
export const CERTIFICATES_CHANGE_SKIP = 'CERTIFICATES_CHANGE_SKIP'
export const CERTIFICATES_REMOVE_LIST_ITEM = 'CERTIFICATES_REMOVE_LIST_ITEM'
export const CERTIFICATES_SET_ITEM = 'CERTIFICATES_SET_ITEM'
export const CERTIFICATES_SET_ITEM_EN = 'CERTIFICATES_SET_ITEM_EN'
export const CERTIFICATES_SET_LIST = 'CERTIFICATES_SET_LIST'
export const CERTIFICATES_SET_SIMPLE_LIST = 'CERTIFICATES_SET_SIMPLE_LIST'
export const CERTIFICATES_SET_LIST_LENGTH = 'CERTIFICATES_SET_LIST_LENGTH'

// forms
export const GET_COURSE_FORMS_LOADING = 'GET_COURSE_FORMS_LOADING'
export const SET_COURSE_FORMS = 'SET_COURSE_FORMS'
