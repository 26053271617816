export const GET_LIST_LOADING = 'GET_LIST_LOADING'
export const SET_LIST = 'SET_LIST'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH'
export const CHANGE_SKIP = 'CHANGE_SKIP'
export const CHANGES_LOADING = 'CHANGES_LOADING'
export const FRESH_LIST_ITEM = 'FRESH_LIST_ITEM'
export const REMOVE_LIST_ITEM = 'REMOVE_LIST_ITEM'
export const ADD_LIST_ITEM = 'ADD_LIST_ITEM'
export const CHANGE_EDIT_ID = 'CHANGE_EDIT_ID'
export const SET_ITEM = 'SET_ITEM'
export const ITEM_LOADING = 'ITEM_LOADING'
export const DELETE_LIST_ITEM = 'DELETE_LIST_ITEM'
export const UPDATE_LIST_ITEM = 'UPDATE_LIST_ITEM'
export const LIST_LOADING = 'LIST_LOADING'
export const FRESH_LOADING = 'FRESH_LOADING'
export const SET_NEW_ITEM = 'SET_NEW_ITEM'
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const RESET = 'RESET'
export const CHANGE_MATERIAL_TYPE = 'CHANGE_MATERIAL_TYPE'
export const UPDATE_SEO_LOADING = 'UPDATE_SEO_LOADING'
export const SEO_LOADING = 'SEO_LOADING'
export const SET_SEO = 'SET_SEO'
export const FRESH_SEO_ITEM = 'FRESH_SEO_ITEM'
export const ADD_SEO_ITEM = 'ADD_SEO_ITEM'
export const UPDATE_IMAGE_LOADING = 'UPDATE_IMAGE_LOADING'
export const FRESH_IMAGE = 'FRESH_IMAGE'
export const CHANGE_VIDEO_CHAPTERS = 'CHANGE_VIDEO_CHAPTERS'

