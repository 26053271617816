import Vue from 'vue'
import { $http } from '@/utils/https'

import { SET_LIST, REMOVE_LIST_ITEM } from './types/mutation-types'
import { GET_LIST, CREATE, DELETE } from './types/action-types'

const state = {
  list: []
}

const getters = {
  list: state => state.list
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      // commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/roles?include=user.personalData&filters[role_id]=${payload.roleId}`)

      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      // commit(CHANGE_LIST_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/roles?`, payload.data)

      // commit(CHANGE_DIALOG_VIEW, false)
      dispatch(GET_LIST, { courseId: response.data.data.course_id, roleId: response.data.data.role_id })
    } catch (e) {
      throw e
    } finally {
      // commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      // commit(CHANGE_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/roles/${payload.id}`)
      commit(REMOVE_LIST_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      // commit(CHANGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [REMOVE_LIST_ITEM] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
