export const GET_LIST = 'GET_LIST'
export const DELETE = 'DELETE'
export const UPDATE = 'UPDATE'
export const CREATE = 'CREATE'
export const GET_ITEM = 'GET_ITEM'
export const UPDATE_SORT = 'UPDATE_SORT'
export const GET_CONTENT = 'GET_CONTENT'
export const GET_SEO = 'GET_SEO'
export const UPDATE_SEO = 'UPDATE_SEO'
export const GET_LIST_ITEM = 'GET_LIST_ITEM'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'
