export default [
  {
    path: 'tests/list',
    name: 'tests',
    component: () => import('@/modules/tests/pages/main/index.vue')
  },
  {
    path: 'tests/results',
    name: 'tests-results',
    component: () => import('@/modules/tests-result/pages/list/index.vue')
  },
  {
    path: 'tests/results/:testId',
    name: 'tests-results-users',
    component: () => import('@/modules/tests-result/pages/list-users/index.vue')
  }
]
