export const CHANGE_LIST_LOADING = 'CHANGE_LIST_LOADING'
export const SET_LIST = 'SET_LIST'
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const CHANGE_SKIP = 'CHANGE_SKIP'
export const CHANGE_SORT = 'CHANGE_SORT'
export const SET_COURSE_LIST = 'SET_COURSE_LIST'
export const CHANGE_GENERATE_ALL_LOADING = 'CHANGE_GENERATE_ALL_LOADING'
export const CHANGE_GENERATE_USER_LOADING = 'CHANGE_GENERATE_USER_LOADING'
export const CHANGE_REMOVE_USER_LOADING = 'CHANGE_REMOVE_USER_LOADING'
export const SET_ITEM_CERTIFICATE = 'SET_ITEM_CERTIFICATE'
export const REMOVE_ITEM_CERTIFICATE = 'REMOVE_ITEM_CERTIFICATE'
