export default [
    {
        path: 'certificates/courses',
        name: 'user-certificate-courses',
        component: () => import('@/modules/user-certificate/pages/list-courses/index.vue')
    },
    {
        path: 'certificates/courses/:courseId',
        name: 'user-certificate-course',
        component: () => import('@/modules/user-certificate/pages/list-users/index.vue')
    }
]