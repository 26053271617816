import { $http } from '@/utils/https'
import Vue from 'vue'
import { getComponentsItemBySlug } from '@/mixins/course'
import {
  CHANGE_LOADING,
  REMOVE_LIST_ITEM,
  SET_LIST,
  SET_ITEM,
  GET_LIST_LOADING,
  FRESH_PACKAGE_ITEM,
  UPDATE_PACKAGE_LOADING
} from './types/mutation-types'

import {
  CREATE,
  GET_LIST,
  DELETE,
  UPDATE_PACKAGES,
  UPDATE_POSITIONS
} from './types/action-types'

const state = {
  courseModules: [],
  isListLoading: false,
  updatePackageLoading: false,
  isLoading: false
}

const getters = {
  courseModules: state => state.courseModules,
  isListLoading: state => state.isListLoading,
  updatePackageLoading: state => state.updatePackageLoading,
  isLoading: state => state.isLoading
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(GET_LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.id}/components?include=packages`)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_LIST_LOADING, false)
    }
  },
  [UPDATE_PACKAGES]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_PACKAGE_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/components/${payload.id}?include=packages`, payload)
      commit(FRESH_PACKAGE_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_PACKAGE_LOADING, false)
    }
  },
  [CREATE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/components?include=packages`, payload)
      commit(SET_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/components/${payload.id}?include=packages`)
      commit(REMOVE_LIST_ITEM, payload)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_POSITIONS]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/components/sort?include=packages`, payload)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    list.map(e => {
      e.customId = e.component_id
      return getComponentsItemBySlug(e)
    })
    state.courseModules = list
  },
  [GET_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [UPDATE_PACKAGE_LOADING] (state, status) {
    state.updatePackageLoading = status
  },
  [SET_ITEM] (state, item) {
    item.customId = item.component_id
    if (state.courseModules.length <= 0) {
      state.courseModules.push(getComponentsItemBySlug(item))
    } else {
      state.courseModules.splice(item.sort, 0, getComponentsItemBySlug(item))
    }
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [REMOVE_LIST_ITEM] (state, params) {
    const index = state.courseModules.findIndex(e => e.id === params.id)
    Vue.delete(state.courseModules, index)
  },
  [FRESH_PACKAGE_ITEM] (state, params) {
    const index = state.courseModules.findIndex(e => e.id === params.id)
    params.customId = params.component_id
    Vue.set(state.courseModules, index, getComponentsItemBySlug(params))
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
