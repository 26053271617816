// auth
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
export const AUTH_REMOVE_TOKEN = 'AUTH_REMOVE_TOKEN'
export const AUTH_LOADING_SING_IN = 'AUTH_LOADING_SING_IN'

export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER'
export const FIX_BODY = 'FIX_BODY'
// system
export const SET_CUSTOM_BREADCRUMBS_LIST = 'SET_CUSTOM_BREADCRUMBS_LIST'
export const RESET_CUSTOM_BREADCRUMBS_LIST = 'RESET_CUSTOM_BREADCRUMBS_LIST'
export const CHANGE_OPEN_MENU = 'CHANGE_OPEN_MENU'
export const CHANGE_ERROR = 'CHANGE_ERROR'
// profile
export const SET_USER_DATA = 'SET_USER_DATA'
export const LOADING_USER_DATA = 'LOADING_USER_DATA'
export const UPDATE_USER_DATA_LOADING = 'UPDATE_USER_DATA_LOADING'
export const SET_COUNTRY_IDS = 'SET_COUNTRY_IDS'

// files
export const UPLOAD_LOADING = 'UPLOAD_LOADING'
export const SET_UPLOAD_FILE = 'SET_UPLOAD_FILE'
export const GET_LAST_UPLOAD_FILES_LOADING = 'GET_LAST_UPLOAD_FILES_LOADING'
export const SET_LAST_UPLOAD_FILES = 'SET_LAST_UPLOAD_FILES'
