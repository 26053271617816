import { $http } from "@/utils/https";

import {
  APPEARANCE_GET_HEADER_LOADING,
  APPEARANCE_GET_STORE_LOADING,
  APPEARANCE_HEADER_FRESH_IMAGE,
  APPEARANCE_HEADER_UPDATE_IMAGE_LOADING,
  APPEARANCE_SET_HEADER,
  APPEARANCE_SET_STORE,
  APPEARANCE_STORE_FRESH_IMAGE,
  APPEARANCE_STORE_UPDATE_IMAGE_LOADING,
  APPEARANCE_UPDATE_HEADER_LOADING,
  APPEARANCE_UPDATE_STORE_LOADING
} from "./types/mutation-types";

import {
  APPEARANCE_GET_HEADER,
  APPEARANCE_GET_STORE,
  APPEARANCE_HEADER_UPDATE_IMAGE,
  APPEARANCE_STORE_UPDATE_IMAGE,
  APPEARANCE_UPDATE_HEADER,
  APPEARANCE_UPDATE_STORE
} from "./types/action-types";

const state = {
  store: null,
  storeLoading: false,
  updateStoreLoading: false,
  header: null,
  headerLoading: false,
  updateHeaderLoading: false,
  updateImageLoading: false
};

const getters = {
  store: state => state.store,
  storeLoading: state => state.storeLoading,
  updateStoreLoading: state => state.updateStoreLoading,
  header: state => state.header,
  headerLoading: state => state.headerLoading,
  updateHeaderLoading: state => state.updateHeaderLoading,
  updateImageLoading: state => state.updateImageLoading
};

const actions = {
  // store
  [APPEARANCE_GET_STORE]: async ({ commit }, payload) => {
    try {
      commit(APPEARANCE_GET_STORE_LOADING, true);
      const response = await $http.get(
        `${process.env.VUE_APP_ROOT_COURSE}courses/${payload.id}/store?include=image,image.seo`
      );
      commit(APPEARANCE_SET_STORE, response.data.data);
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit(APPEARANCE_GET_STORE_LOADING, false);
    }
  },
  [APPEARANCE_UPDATE_STORE]: async ({ commit }, payload) => {
    try {
      commit(APPEARANCE_UPDATE_STORE_LOADING, true);
      const response = await $http.patch(
        `${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/store?include=image,image.seo`,
        payload
      );
      commit(APPEARANCE_SET_STORE, response.data.data);
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit(APPEARANCE_UPDATE_STORE_LOADING, false);
    }
  },
  [APPEARANCE_STORE_UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(APPEARANCE_STORE_UPDATE_IMAGE_LOADING, true);
      let response = "";
      if (payload.get("id")) {
        response = await $http.post(
          `${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get(
            "course_id"
          )}/store/${payload.get("store_id")}/images/${payload.get(
            "id"
          )}?include=seo`,
          payload
        );
      } else {
        response = await $http.post(
          `${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get(
            "course_id"
          )}/store/${payload.get("store_id")}/images?include=seo`,
          payload
        );
      }
      commit(APPEARANCE_STORE_FRESH_IMAGE, response.data.data);
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit(APPEARANCE_STORE_UPDATE_IMAGE_LOADING, false);
    }
  },
  // header
  [APPEARANCE_GET_HEADER]: async ({ commit }, payload) => {
    try {
      commit(APPEARANCE_GET_HEADER_LOADING, true);
      const response = await $http.get(
        `${process.env.VUE_APP_ROOT_COURSE}courses/${payload.id}/header?include=images.seo`
      );
      commit(APPEARANCE_SET_HEADER, response.data.data);
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit(APPEARANCE_GET_HEADER_LOADING, false);
    }
  },
  [APPEARANCE_UPDATE_HEADER]: async ({ commit }, payload) => {
    try {
      commit(APPEARANCE_UPDATE_HEADER_LOADING, true);
      const response = await $http.patch(
        `${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/header?include=images.seo`,
        payload
      );
      commit(APPEARANCE_SET_HEADER, response.data.data);
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit(APPEARANCE_UPDATE_HEADER_LOADING, false);
    }
  },
  [APPEARANCE_HEADER_UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(APPEARANCE_HEADER_UPDATE_IMAGE_LOADING, true);
      let response = "";
      if (payload.params.get("id")) {
        response = await $http.post(
          `${process.env.VUE_APP_ROOT_COURSE}courses/${payload.params.get(
            "course_id"
          )}/header/${payload.params.get(
            "header_id"
          )}/images/${payload.params.get("id")}?include=seo`,
          payload.params
        );
      } else {
        response = await $http.post(
          `${process.env.VUE_APP_ROOT_COURSE}courses/${payload.params.get(
            "course_id"
          )}/header/${payload.params.get("header_id")}/images?include=seo`,
          payload.params
        );
      }

      const data = { response: response.data.data, type: payload.type };
      commit(APPEARANCE_HEADER_FRESH_IMAGE, data);
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      commit(APPEARANCE_HEADER_UPDATE_IMAGE_LOADING, false);
    }
  }
};

const mutations = {
  // store
  [APPEARANCE_SET_STORE](state, content) {
    state.store = content;
  },
  [APPEARANCE_GET_STORE_LOADING](state, status) {
    state.storeLoading = status;
  },
  [APPEARANCE_UPDATE_STORE_LOADING](state, status) {
    state.updateStoreLoading = status;
  },
  [APPEARANCE_STORE_UPDATE_IMAGE_LOADING](state, status) {
    state.updateImageLoading = status;
  },
  [APPEARANCE_STORE_FRESH_IMAGE](state, data) {
    state.store.image.data = data;
  },
  // header
  [APPEARANCE_SET_HEADER](state, content) {
    state.header = content;

    console.log("content", content);
    state.header.images.data = [
      content.images.data[0] ? content.images.data[0] : {},
      content.images.data[1] ? content.images.data[1] : {}
    ];

    if (!content) return;
    state.header.color_background = !state.header.color_background
      ? "#f5f5f5ff"
      : state.header.color_background;
    state.header.color_title = !state.header.color_title
      ? "#000000FF"
      : state.header.color_title;
    state.header.color_description = !state.header.color_description
      ? "#888888FF"
      : state.header.color_description;
    state.header.color_breadcrumbs = !state.header.color_breadcrumbs
      ? "#888888FF"
      : state.header.color_breadcrumbs;
    state.header.color_rating_active = !state.header.color_rating_active
      ? "#b83f39FF"
      : state.header.color_rating_active;
    state.header.color_rating_inactive = !state.header.color_rating_inactive
      ? "#C4C4C4FF"
      : state.header.color_rating_inactive;
    state.header.color_button_start_continue_studying = !state.header
      .color_button_start_continue_studying
      ? "#b83f39FF"
      : state.header.color_button_start_continue_studying;
    state.header.color_button_start_continue_studying_hover = !state.header
      .color_button_start_continue_studying_hover
      ? "#ffffffff"
      : state.header.color_button_start_continue_studying_hover;
    state.header.color_button_start_continue_studying_text = !state.header
      .color_button_start_continue_studying_text
      ? "#ffffffff"
      : state.header.color_button_start_continue_studying_text;
    state.header.color_button_start_continue_studying_text_hover = !state.header
      .color_button_start_continue_studying_text_hover
      ? "#b83f39FF"
      : state.header.color_button_start_continue_studying_text_hover;
    state.header.color_button_watch_trailer = !state.header
      .color_button_watch_trailer
      ? "#ffffffff"
      : state.header.color_button_watch_trailer;
    state.header.color_button_watch_trailer_hover = !state.header
      .color_button_watch_trailer_hover
      ? "#000000FF"
      : state.header.color_button_watch_trailer_hover;
    state.header.color_button_watch_trailer_text = !state.header
      .color_button_watch_trailer_text
      ? "#000000FF"
      : state.header.color_button_watch_trailer_text;
    state.header.color_button_watch_trailer_text_hover = !state.header
      .color_button_watch_trailer_text_hover
      ? "#ffffffff"
      : state.header.color_button_watch_trailer_text_hover;
    state.header.font_size = !state.header.font_size
      ? "medium"
      : state.header.font_size;
  },
  [APPEARANCE_GET_HEADER_LOADING](state, status) {
    state.headerLoading = status;
  },
  [APPEARANCE_UPDATE_HEADER_LOADING](state, status) {
    state.updateHeaderLoading = status;
  },
  [APPEARANCE_HEADER_FRESH_IMAGE](state, data) {
    const { response, type } = data;

    if (type === "desktop") {
      state.header.images.data[0] = response;
    }
    if (type === "mobile") {
      state.header.images.data[1] = response;
    }

    // if (!state.header.images.data.length) {
    //   state.header.images.data = [data];
    // }

    // const findedImage = state.header.images.data.find(
    //   item => item.src === data.src
    // );

    // console.log("findedImage", findedImage);

    // if (state.header.images.data.length && findedImage) {
    //   // change current image
    //   state.header.images.data = state.header.images.data.map(item =>
    //     item.id === data.id ? { ...data } : item
    //   );
    // }

    // if (state.header.images.data.length && !findedImage) {
    //   state.header.images.data = [...state.header.images.data, data];
    // }
  },
  [APPEARANCE_HEADER_UPDATE_IMAGE_LOADING](state, status) {
    state.updateImageLoading = status;
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
