export const GET_CONFIG = 'GET_CONFIG'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_LANGUAGE_INTERFACE_LIST = 'GET_LANGUAGE_INTERFACE_LIST'
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS'
export const GET_COUNTRY = 'GET_COUNTRY'
export const GET_BASIC_COUNTRIES = 'GET_BASIC_COUNTRIES'
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES'
export const DELETE = 'DELETE'

export const GET_LIST = 'GET_LIST'
export const GET_LIST_ITEM = 'GET_LIST_ITEM'
export const UPDATE = 'UPDATE'
export const CREATE = 'CREATE'
export const GET_GROUPS = 'GET_GROUPS'
export const GET_BASIC_LOCALES = 'GET_BASIC_LOCALES'
export const GET_SEO = 'GET_SEO'
export const UPDATE_SEO = 'UPDATE_SEO'
export const UPDATE_TRANSLATION = 'UPDATE_TRANSLATION'
export const GET_LAYOUTS_LIST = 'GET_LAYOUTS_LIST'
