export const UPDATE_LIST_ITEM = 'UPDATE_LIST_ITEM'
export const DELETE_LIST_ITEM = 'DELETE_LIST_ITEM'
export const LIST_LOADING = 'LIST_LOADING'
export const SET_LIST = 'SET_LIST'
export const SET_NEW_ITEM = 'SET_NEW_ITEM'
export const FRESH_LOADING = 'FRESH_LOADING'
export const ITEM_LOADING = 'ITEM_LOADING'
export const SET_ITEM = 'SET_ITEM'
export const UPDATE_SEO_LOADING = 'UPDATE_SEO_LOADING'
export const SEO_LOADING = 'SEO_LOADING'
export const SET_SEO = 'SET_SEO'
export const FRESH_SEO_ITEM = 'FRESH_SEO_ITEM'
export const ADD_SEO_ITEM = 'ADD_SEO_ITEM'
export const LESSON_FRESH_IMAGE = 'LESSON_FRESH_IMAGE'
export const LESSON_UPDATE_IMAGE_LOADING = 'LESSON_UPDATE_IMAGE_LOADING'

export const CHANGE_EDIT_ID = 'CHANGE_EDIT_ID'
export const CHANGE_VIDEO_CHAPTERS = 'CHANGE_VIDEO_CHAPTERS'
export const CHANGE_LOADING = 'CHANGE_LOADING'
