export const DEFAULT_FILTERS = {
  module: '',
  status: '',
  dateFrom: '',
  dateTo: '',
  isChecking: 1,
  user: '',
  homeWork: '',
  graduateWork: '',
  material: '',
  materialType: ''
}
