import { $http } from '@/utils/https'
import {
  SET_LIST,
  CHANGE_LIST_LOADING,
  SET_LIST_LENGTH,
  CHANGE_LIST_FILTER,
  CHANGE_SKIP,
  CHANGE_LOADING,
  CHANGE_SHOW_HOME_WORK_DIALOG_LESSON,
  SET_ITEM,
  UPDATE_IMAGE_LOADING,
  FRESH_IMAGE,
  CHANGE_PRACTIC_TYPE
} from './types/mutation-types'

import {
  GET_LIST,
  GET_LIST_ITEM,
  CREATE,
  UPDATE,
  DELETE,
  UPDATE_IMAGE
} from './types/action-types'
const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: null,
  currentItem: null,
  isLoading: false,
  isListLoading: false,
  showHomeWorkDialog: false,
  updateImageLoading: false,
  limit: 20,
  skip: 0,
  practicType: 1,
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  limit: state => state.limit,
  skip: state => state.skip,
  showHomeWorkDialog: state => state.showHomeWorkDialog,
  updateImageLoading: state => state.updateImageLoading,
  practicType: state => state.practicType,
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/home-works/?include=country&filter[byCountries]=${state.filterCountries || ''}&filter[status]=${state.filterStatus || ''}&filter[keywords]=${state.filterKeywords || ''}&limit=${state.limit}&skip=${state.skip}`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/home-works/${state.currentItem.id}?include=questions,status,type,image,image.seo,packages,module`)

      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/home-works?include=questions,status,type,packages,image`, payload)
      commit(SET_ITEM, response.data.data)
      dispatch('lessons/GET_LIST', response.data.data, { root: true })
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/home-works/${payload.id}?include=questions,status,type,packages,image`, payload)
      dispatch('lessons/GET_LIST', response.data.data, { root: true })
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/home-works/${payload.id}`)
      commit('lessons/DELETE_LIST_ITEM', payload, { root: true })
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/modules/${payload.get('module_id')}/home-works/${payload.get('home_works_id')}/images/${payload.get('id')}?include=seo`, payload)
        response.data.data.isEdit = true
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/modules/${payload.get('module_id')}/home-works/${payload.get('home_works_id')}/images?include=seo`, payload)
        response.data.data.isEdit = false
      }
      response.data.data.homeWorksId = payload.get('home_works_id')
      commit(FRESH_IMAGE, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_IMAGE_LOADING, false)
    }
  },
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [CHANGE_LIST_FILTER] (state, params) {
    switch (params.type) {
      case 'type': {
        state.filterType = params.value
        break
      }
      case 'country': {
        state.filterCountries = params.value
        break
      }
      case 'status': {
        state.filterStatus = params.value
        break
      }
      default: {
        state.filterKeywords = params.value
      }
    }
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [CHANGE_SHOW_HOME_WORK_DIALOG_LESSON] (state, status) {
    state.showHomeWorkDialog = status
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [FRESH_IMAGE] (state, data) {
    state.currentItem.image.data = data
  },
  [CHANGE_PRACTIC_TYPE] (state, type) {
    state.practicType = type
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
