import { $http } from '@/utils/https'
import Vue from 'vue'
import { transformerCurrentUser, transformerUsersList } from '@/transformers/user'

import {
    CHANGE_FILTER,
    CHANGE_LOADING,
    CHANGE_SKIP,
    FETCH_LIST_LOADING,
    FRESH_IMAGE,
    FRESH_LIST,
    FRESH_USER_ROLES,
    GET_CURRENT_LOADING,
    SET_CONTACTS_CHANGES,
    SET_ITEM,
    SET_LIST,
    SET_LIST_LENGTH,
    SET_UPDATED_CONTACTS,
    SET_USER_SETTINGS,
    UPDATE_IMAGE_LOADING,
    SET_USER_DATA
} from './types/mutation-types'

import {
    CREATE_USER,
    DELETE_USER,
    FETCH_LIST,
    GET_CHANGE_CONTACTS,
    GET_LIST_ITEM,
    UPDATE_CONTACTS,
    UPDATE_IMAGE,
    UPDATE_ROLES,
    UPDATE_SETTINGS,
    UPDATE_USER,
    GET_USER_DATA
} from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
    isListLoading           : false,
    list                    : [],
    listLength              : 0,
    limit                   : 30,
    skip                    : 0,
    updateImageLoading      : false,
    filter                  : {
        keywords        : '',
        batch           : '',
        country         : '',
        course          : '',
        isActive        : '',
        courseUserId    : ''
    },
    isLoading               : false,
    currentStudent          : null,
    settings                : null,
    currentStudentLoading   : false,
    contactsChangesList     : [],
    userData                : null
}

const getters = {
    list                    : state => transformerUsersList(state.list),
    isListLoading           : state => state.isListLoading,
    listLength              : state => state.listLength,
    limit                   : state => state.limit,
    skip                    : state => state.skip,
    filter                  : state => state.filter,
    updateImageLoading      : state => state.updateImageLoading,
    currentStudent          : state => transformerCurrentUser(state.currentStudent),
    currentStudentLoading   : state => state.currentStudentLoading,
    settings                : state => camelcaseObjectDeep(state.settings),
    isLoading               : state => state.isLoading,
    contactsChangesList     : state => camelcaseObjectDeep(state.contactsChangesList),
    userData                : state => state.userData
}

const actions = {
    [FETCH_LIST]            : async ({ commit }) => {
        try {
            commit(FETCH_LIST_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}users/clients?include=countries,personalData,coursesSummary&filters[keywords]=${state.filter.keywords || ''}&filters[course_users]=${state.filter.courseUserId || ''}&limit=${state.limit}&skip=${state.skip}&filters[active]=${state.filter.isActive ? 1 : 0}&filters[country]=${state.filter.country || ''}`)
            
            commit(SET_LIST, response.data.data)
            commit(SET_LIST_LENGTH, response.data.meta.total)
        } catch (e) {
            throw e
        } finally {
            commit(FETCH_LIST_LOADING, false)
        }
    },
    [DELETE_USER]           : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.delete(`${process.env.VUE_APP_ROOT_USER}users/clients/${payload.id}`)
            
            commit(FRESH_LIST, payload)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [CREATE_USER]           : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/clients`, payload)
            
            return response.data.data
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [UPDATE_USER]           : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.post(`${process.env.VUE_APP_ROOT_USER}users/clients/${payload.get('id')}`, payload)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [GET_LIST_ITEM]         : async ({ commit }, payload) => {
        try {
            commit(GET_CURRENT_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}users/clients/${payload.id}?include=permissions,setting,setting.timezone,countries,personalData,roles,permissions,socials,confirmations,author`)
            
            commit(SET_ITEM, response.data.data)
            commit(SET_USER_SETTINGS, response.data.data.setting.data)
        } catch (e) {
        throw e
        } finally {
            commit(GET_CURRENT_LOADING, false)
        }
    },
    [UPDATE_IMAGE]          : async ({ commit }, payload) => {
        try {
            commit(UPDATE_IMAGE_LOADING, true)
            
            let response = ''
            
            if (payload.get('id')) {
                response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/clients/${payload.get('user_id')}/images/${payload.get('id')}`, payload)
            } else {
                response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/clients/${payload.get('user_id')}/images`, payload)
            }
            
            commit(FRESH_IMAGE, response.data.data)
        } catch (e) {
            throw e
        } finally {
            commit(UPDATE_IMAGE_LOADING, false)
        }
    },
    [UPDATE_ROLES]          : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/${payload.get('userId')}/roles?include=roles`, payload)
            
            commit(FRESH_USER_ROLES, response.data.data.roles)
            
            return response.data.data
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [UPDATE_SETTINGS]       : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/${payload.userId}/settings?include=timezone`, payload)
            
            commit(SET_USER_SETTINGS, response.data.data)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    // update phone or email
    [UPDATE_CONTACTS]       : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}users/${payload.userId}/personal-data-requests/${payload.id}/${payload.type}`, payload)
            
            commit(SET_UPDATED_CONTACTS, response.data.data)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [GET_CHANGE_CONTACTS]   : async ({ commit }, payload) => {
        try {
            commit(FETCH_LIST_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}users/${payload.userId}/personal-data-requests`)
            
            commit(SET_CONTACTS_CHANGES, response.data.data)
        } catch (e) {
            throw e
        } finally {
            commit(FETCH_LIST_LOADING, false)
        }
    },
    [GET_USER_DATA]         : async ({ commit }, payload) => {
        try {
            // commit(FETCH_LIST_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_AUTH_ADMIN}users/${payload.id}/tokens`)
            
            commit(SET_USER_DATA, response.data.data)
        } catch (e) {
            throw e
        } finally {
            // commit(FETCH_LIST_LOADING, false)
        }
    }
}

const mutations = {
    [SET_LIST] (state, list) {
        state.list = list
    },
    [SET_CONTACTS_CHANGES] (state, list) {
        state.contactsChangesList = list
    },
    [SET_UPDATED_CONTACTS] (state, data) {
        const index = state.contactsChangesList.findIndex(e => e.id === data.id)
        
        Vue.set(state.contactsChangesList, index, data)
    },
    [CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
    [FRESH_LIST] (state, params) {
        const index = state.list.findIndex(e => e.id === params.id)
        
        Vue.delete(state.list, index)
    },
    [SET_LIST_LENGTH] (state, length) {
        state.listLength = length
    },
    [SET_ITEM] (state, data) {
        state.currentStudent = data
    },
    [SET_USER_SETTINGS] (state, data) {
        state.settings = data
    },
    [GET_CURRENT_LOADING] (state, status) {
        state.currentStudentLoading = status
    },
    [FETCH_LIST_LOADING] (state, status) {
        state.isListLoading = status
    },
    [CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [UPDATE_IMAGE_LOADING] (state, status) {
        state.updateImageLoading = status
    },
    [FRESH_IMAGE] (state, data) {
        state.currentStudent.avatar = data
    },
    [CHANGE_FILTER] (state, data) {
        state.filter = data
    },
    [FRESH_USER_ROLES] (state, data) {
        state.currentStudent.roles = data
    },
    [SET_USER_DATA] (state, data) {
        state.userData = data
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
