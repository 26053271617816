import {$http} from '@/utils/https'

import {
    SET_USERS_SETTING
} from './types/mutation-types'

import {
    GET_USERS_SETTING,
} from './types/action-types'

const getters = {
    userTypes: state => state.userTypes
}

const state = {
    userTypes: null
}

const actions = {
    [GET_USERS_SETTING]: async ({commit}) => {
        const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}setting`)
        commit(SET_USERS_SETTING, response.data.data)
    },
}

const mutations = {
    [SET_USERS_SETTING](state, content) {
        console.log(content)
        state.userTypes = content.hasOwnProperty('user') ? content.user.types : [];
    },
}
export default {
    state,
    getters,
    actions,
    mutations
}
