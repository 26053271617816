import Vue from 'vue'
import { $http } from '@/utils/https'
import { transformerSeo } from '@/transformers'

import {
  UPDATE_SEO_LOADING,
  SEO_LOADING,
  SET_SEO,
  FRESH_SEO_ITEM,
  ADD_SEO_ITEM
} from './types/mutation-types'

import {
  GET_SEO,
  UPDATE_SEO
} from './types/action-types'

const state = {
  seo: null,
  seoLoading: false,
  updateSeoLoading: false
}

const getters = {
  seo: state => transformerSeo(state.seo),
  seoLoading: state => state.seoLoading,
  updateSeoLoading: state => state.updateSeoLoading
}

const actions = {
  [GET_SEO]: async ({ commit }, payload) => {
    try {
      commit(SEO_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/lessons/${payload.lesson_id}/seo`)
      commit(SET_SEO, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SEO_LOADING, false)
    }
  },
  [UPDATE_SEO]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_SEO_LOADING, true)
      if (payload.hasOwnProperty('_method')) {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/lessons/${payload.lesson_id}/seo/${payload.id}`, payload)
        commit(FRESH_SEO_ITEM, response.data.data)
      } else {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/lessons/${payload.lesson_id}/seo`, payload)
        commit(ADD_SEO_ITEM, response.data.data)
      }
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_SEO_LOADING, false)
    }
  }
}

const mutations = {
  [UPDATE_SEO_LOADING] (state, status) {
    state.updateSeoLoading = status
  },
  [SEO_LOADING] (state, status) {
    state.seoLoading = status
  },
  [SET_SEO] (state, content) {
    state.seo = content
  },
  [FRESH_SEO_ITEM] (state, data) {
    const index = state.seo.findIndex(e => e.locale === data.locale)
    Vue.set(state.seo, index, data)
  },
  [ADD_SEO_ITEM] (state, content) {
    state.seo.push(content)
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
