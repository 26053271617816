import Vue from 'vue'
import { $http } from '@/utils/https'
import {
  CHANGE_DIALOG_VIEW,
  CHANGE_LIST_LOADING,
  CHANGE_LOADING,
  CHANGE_SKIP,
  REPLACE_LIST_ITEM,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH,
  UPDATE_TRANSLATION_LOADING,
  FRESH_TRANSLATION_ITEM,
  ADD_TRANSLATION_ITEM
} from './types/mutation-types'

import { GET_LIST, GET_LIST_ITEM, UPDATE, UPDATE_TRANSLATION } from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: [],
  isLoading: false,
  isListLoading: false,
  currentItem: null,
  limit: 13,
  skip: 0,
  listLength: 0,
  openDialogView: false,
  isTranslationLoading: false
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  isLoading: state => state.isLoading,
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  isListLoading: state => state.isListLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  skip: state => state.skip,
  openDialogView: state => state.openDialogView,
  isTranslationLoading: state => state.isTranslationLoading,
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COUNTRY}admin/countries?include=locales&filters[keywords]=${payload.keywords || ''}&limit=${state.limit}&skip=${state.skip}`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COUNTRY}admin/countries/${payload.id}?include=locales,status,translations`)

      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COUNTRY}admin/countries/${payload.get('id')}?include=locales`, payload)
      commit(REPLACE_LIST_ITEM, response.data.data)
      commit(SET_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_TRANSLATION]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_TRANSLATION_LOADING, true)
      if (payload.get('_method')) {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COUNTRY}admin/countries/${payload.get('country_id')}/translations/${payload.get('id')}`, payload)
        commit(FRESH_TRANSLATION_ITEM, response.data.data)
      } else {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COUNTRY}admin/countries/${payload.get('country_id')}/translations`, payload)
        commit(ADD_TRANSLATION_ITEM, response.data.data)
      }
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_TRANSLATION_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [REPLACE_LIST_ITEM] (state, data) {
    const index = state.list.findIndex(e => e.id === data.id)
    Vue.set(state.list, index, data)
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [CHANGE_DIALOG_VIEW] (state, status) {
    state.openDialogView = status
  },
  [UPDATE_TRANSLATION_LOADING] (state, status) {
    state.isTranslationLoading = status
  },
  [ADD_TRANSLATION_ITEM] (state, content) {
    state.currentItem.translations.data.push(content)
  },
  [FRESH_TRANSLATION_ITEM] (state, data) {
    const index = state.currentItem.translations.data.findIndex(e => e.id === data.id)
    Vue.set(state.currentItem.translations.data, index, data)
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
