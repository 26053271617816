// news
export const NEWS_CREATE_ITEM = 'NEWS_CREATE_ITEM'
export const NEWS_FETCH_LIST = 'NEWS_FETCH_LIST'
export const NEWS_GET_CURRENT = 'NEWS_GET_CURRENT'
export const NEWS_LIST_DELETE_ITEM = 'NEWS_LIST_DELETE_ITEM'
export const NEWS_UPDATE_ITEM = 'NEWS_UPDATE_ITEM'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'

// news types
export const GET_NEWS_CATEGORIES = 'GET_NEWS_CATEGORIES'
export const GET_NEWS_TAGS = 'GET_NEWS_TAGS'
export const ADD_QUICK_TAG = 'ADD_QUICK_TAG'

// general
export const GENERAL_GET_DATA = 'GENERAL_GET_DATA'
export const GENERAL_UPDATE_DATA = 'GENERAL_UPDATE_DATA'
export const GENERAL_CREATE_DATA = 'GENERAL_CREATE_DATA'
export const GENERAL_ADD_TAG = 'GENERAL_ADD_TAG'
export const GENERAL_REMOVE_TAG = 'GENERAL_REMOVE_TAG'
export const GENERAL_ADD_CATEGORY = 'GENERAL_ADD_CATEGORY'
export const GENERAL_REMOVE_CATEGORY = 'GENERAL_REMOVE_CATEGORY'

// content
export const CONTENT_GET_DATA = 'CONTENT_GET_DATA'
export const CONTENT_UPDATE_DATA = 'CONTENT_UPDATE_DATA'
export const CONTENT_CREATE_DATA = 'CONTENT_CREATE_DATA'

// seo
export const SEO_GET_DATA = 'SEO_GET_DATA'

// categories
export const CREATE = 'CREATE'
export const UPDATE = 'UPDATE'
export const GET_LIST = 'GET_LIST'
export const GET_LIST_ITEM = 'GET_LIST_ITEM'
export const GET_SEO = 'GET_SEO'
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES'
export const UPDATE_SEO = 'UPDATE_SEO'
export const UPDATE_TRANSLATION = 'UPDATE_TRANSLATION'
export const DELETE = 'DELETE'
export const GET_SETTING = 'GET_SETTING'
