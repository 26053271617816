import Vue from 'vue'
import { $http } from '@/utils/https'

import {
    CHANGE_LOADING,
    COURSES_CHANGE_LOCK_STEP,
    COURSES_CHANGE_STEP,
    COURSES_FILTER_CHANGE,
    COURSES_LIST_CHANGE_SKIP,
    COURSES_SET_CATEGORIES,
    COURSES_SET_SETTING,
    COURSES_SET_TYPES,
    GET_COURSE_MATERIALS_LOADING,
    GET_COURSE_MODULES_LOADING,
    GET_COURSE_USERS_IDS_LOADING,
    GET_COURSES_LOADING,
    GET_COURSES_SIMPLE_RESPONSE_LOADING,
    REMOVE_LIST_ITEM,
    SET_COURSE_MATERIALS,
    SET_COURSE_MODULES,
    SET_COURSE_USERS_IDS,
    SET_COURSES,
    SET_COURSES_LENGTH,
    SET_COURSES_SIMPLE_RESPONSE
} from './types/mutation-types'

import {
    COURSES_GET_CATEGORIES,
    COURSES_GET_SETTING,
    COURSES_GET_TYPES,
    DELETE_COURSE,
    GET_COURSE_MATERIALS,
    GET_COURSE_MODULES,
    GET_COURSE_USERS_IDS,
    GET_COURSES,
    GET_COURSES_SIMPLE_RESPONSE,
    COPY_COURSE,
    UPDATE_COURSE,
    GET_COURSES_WITH_FORM, 
    COURSES_FORMS_GET_LIST, 
    COURSES_FORMS_SAVE_FORM, 
    COURSES_FORMS_DELETE_FORM
} from './types/action-types'

import { getComponentsItemBySlug } from '@/mixins/course'

const camelCaseKeys = require('camelcase-object-deep')

const state = {
    listLoading             : false,
    list                    : [],
    listLength              : 0,
    limit                   : 20,
    skip                    : 0,
    step                    : 1,
    stepLock                : false,
    setting                 : null,
    categories              : null,
    types                   : null,
    courseModules           : [],
    courseModulesLoading    : false,
    courseMaterials         : [],
    courseMaterialsLoading  : false,
    isLoading               : false,
    isSimpleCourseLoading   : false,
    simpleCourses           : [],
    courseUsersIds          : [],
    isCourseUsersIdsLoading : [],
    filterSearchInput       : '',
    filterType              : '',
    filterStatus            : '',
    filterStore             : '',
    filterCountries         : []
}

const getters = {
    list                    : state => camelCaseKeys(state.list),
    listLoading             : state => state.listLoading,
    listLength              : state => state.listLength,
    limit                   : state => state.limit,
    skip                    : state => state.skip,
    step                    : state => state.step,
    stepLock                : state => state.stepLock,
    setting                 : state => state.setting,
    categories              : state => state.categories,
    types                   : state => state.types,
    courseModules           : state => camelCaseKeys(state.courseModules),
    courseModulesLoading    : state => state.courseModulesLoading,
    courseMaterials         : state => camelCaseKeys(state.courseMaterials),
    courseMaterialsLoading  : state => state.courseMaterialsLoading,
    isLoading               : state => state.isLoading,
    filterSearchInput       : state => state.filterSearchInput,
    filterCountries         : state => state.filterCountries,
    filterType              : state => state.filterType,
    filterStatus            : state => state.filterStatus,
    filterStore             : state => state.filterStore,
    simpleCourses           : state => state.simpleCourses,
    isSimpleCourseLoading   : state => state.isSimpleCourseLoading,
    courseUsersIds          : state => state.courseUsersIds,
    isCourseUsersIdsLoading : state => state.isCourseUsersIdsLoading
}

const actions = {
    [GET_COURSES_WITH_FORM]         : async ({ commit }, payload) => {
        try {
            commit(GET_COURSES_LOADING, true)
            
            const response = await $http.get(
                `${process.env.VUE_APP_ROOT_COURSE}courses`, 
                {
                    params: {
                        include                         : 'type,image,status,forms',
                        'filters[status]'               : state.filterStatus || '',
                        'filters[type]'                 : state.filterType || '',
                        'filters[keywords]'             : state.filterSearchInput || '',
                        'filters[with_completed_works]' : payload?.withCompletedWorks ? 1 : 0,
                        'filters[with_ratings]'         : payload?.withRatings ? 1 : 0,
                        'filters[user]'                 : payload?.userId ? payload.userId : '',
                        'filters[countries]'            : state.filterCountries || '',
                        'filters[store_status]'         : state.filterStore || '',
                        limit                           : state.limit,
                        skip                            : state.skip
                    }
                }
            );
            
            if(response.data && 'data' in response.data && response.data.data){
                commit(SET_COURSES, response.data.data)
            }else{
                commit(SET_COURSES, [])
            }
            
            if(response.data && 'meta' in response.data){
                commit(SET_COURSES_LENGTH, response.data.meta.total)
            }else{
                commit(SET_COURSES_LENGTH, 0)
            }
            // eslint-disable-next-line no-useless-catch
        } catch (e) {
            throw e
        } finally {
            commit(GET_COURSES_LOADING, false)
        }
    },
    [GET_COURSES]                   : async ({ commit }, payload) => {
        try {
            commit(GET_COURSES_LOADING, true)
            
            const response = await $http.get(
                `${process.env.VUE_APP_ROOT_COURSE}courses`, 
                {
                    params: {
                        include                         : 'type,image,status',
                        'filters[status]'               : state.filterStatus || '',
                        'filters[type]'                 : state.filterType || '',
                        'filters[keywords]'             : state.filterSearchInput || '',
                        'filters[with_completed_works]' : payload?.withCompletedWorks ? 1 : 0,
                        'filters[with_ratings]'         : payload?.withRatings ? 1 : 0,
                        'filters[user]'                 : payload?.userId ? payload.userId : '',
                        'filters[countries]'            : state.filterCountries || '',
                        'filters[store_status]'         : state.filterStore || '',
                        limit                           : state.limit,
                        skip                            : state.skip
                    }
                }
            );
            
            if(response.data && 'data' in response.data && response.data.data){
                commit(SET_COURSES, response.data.data)
            }else{
                commit(SET_COURSES, [])
            }
            
            if(response.data && 'meta' in response.data){
                commit(SET_COURSES_LENGTH, response.data.meta.total)
            }else{
                commit(SET_COURSES_LENGTH, 0)
            }
            // eslint-disable-next-line no-useless-catch
        } catch (e) {
            throw e
        } finally {
            commit(GET_COURSES_LOADING, false)
        }
    },
    [GET_COURSE_MATERIALS]          : async ({ commit }, payload) => {
        try {
            commit(GET_COURSE_MATERIALS_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/materials/list?include=&filters[package_id]=${payload.packageId}&limit=100`)
            
            if(response.data && 'data' in response.data && response.data.data){
                commit(SET_COURSE_MATERIALS, response.data.data)
            }else{
                commit(SET_COURSE_MATERIALS, [])
            }
            // eslint-disable-next-line no-useless-catch
        } catch (e) {
            throw e
        } finally {
            commit(GET_COURSE_MATERIALS_LOADING, false)
        }
    },
    [GET_COURSE_MODULES]            : async ({ commit }, payload) => {
        try {
            commit(GET_COURSE_MODULES_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}modules/list?include=&filters[course_id]=${payload.courseId}&filters[package_id]=${payload.packageId}&limit=100`)
            
            if(response.data && 'data' in response.data && response.data.data){
                commit(SET_COURSE_MODULES, response.data.data)
            }else{
                commit(SET_COURSE_MODULES, [])
            }
            // eslint-disable-next-line no-useless-catch
        } catch (e) {
            throw e
        } finally {
            commit(GET_COURSE_MODULES_LOADING, false)
        }
    },
    [GET_COURSES_SIMPLE_RESPONSE]   : async ({ commit }, payload) => {
        try {
            commit(GET_COURSES_SIMPLE_RESPONSE_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses?include=&filters[keywords]=${payload?.keywords ? payload.keywords : ''}&filters[batch]=${payload?.batch ? payload.batch : ''}&limit=20&skip=0`)
            
            if(response.data && 'data' in response.data && response.data.data){
                commit(SET_COURSES_SIMPLE_RESPONSE, response.data.data)
            }else{
                commit(SET_COURSES_SIMPLE_RESPONSE, [])
            }
            // eslint-disable-next-line no-useless-catch
        } catch (e) {
            throw e
        } finally {
            commit(GET_COURSES_SIMPLE_RESPONSE_LOADING, false)
        }
    },
    [DELETE_COURSE]                 : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.id}`)
            
            commit(REMOVE_LIST_ITEM, payload)
            // eslint-disable-next-line no-useless-catch
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [GET_COURSE_USERS_IDS]          : async ({ commit }, payload) => {
        try {
            commit(GET_COURSE_USERS_IDS_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.id}/users/list`)
            
            if(response.data && 'data' in response.data && response.data.data){
                commit(SET_COURSE_USERS_IDS, response.data.data)
            }else{
                commit(SET_COURSE_USERS_IDS, [])
            }
            // eslint-disable-next-line no-useless-catch
        } catch (e) {
            throw e
        } finally {
            commit(GET_COURSE_USERS_IDS_LOADING, false)
        }
    },
    [COURSES_GET_SETTING]           : async ({ commit }) => {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}setting`)
            
            if(response.data && 'data' in response.data && response.data.data){
                commit(COURSES_SET_SETTING, response.data.data)
            }else{
                commit(COURSES_SET_SETTING, [])
            }
        } catch (e) {
            throw e
        }
    },
    [COURSES_GET_CATEGORIES]        : async ({ commit }) => {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}categories`)
            
            if(response.data && 'data' in response.data && response.data.data){
                commit(COURSES_SET_CATEGORIES, response.data.data)
            }else{
                commit(COURSES_SET_CATEGORIES, [])
            }
        } catch (e) {
            throw e;
        }
    },
    [COURSES_GET_TYPES]             : async ({ commit }) => {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}course-types`)
            
            if(response.data && 'data' in response.data && response.data.data){
                commit(COURSES_SET_TYPES, response.data.data)
            }else{
                commit(COURSES_SET_TYPES, [])
            }
        } catch (e) {
            throw e
        }
    },
    [COPY_COURSE]                   : async (context, payload) => {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.id}/copy`)
        } catch (e) {
            throw e
        }
    },
    [UPDATE_COURSE]                 : async (context, payload) => {
        // eslint-disable-next-line no-useless-catch
        try {
            const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.id}/update-all-users`)
        } catch (e) {
            throw e
        }
    }
}

const mutations = {
    [SET_COURSES] (state, list) {
        state.list = list
    },
    [GET_COURSES_LOADING] (state, status) {
        state.listLoading = status
    },
    [SET_COURSES_LENGTH] (state, status) {
        state.listLength = status
    },
    [SET_COURSE_MODULES] (state, data) {
        state.courseModules = data
    },
    [CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
    [REMOVE_LIST_ITEM] (state, params) {
        const index = state.list.findIndex(e => e.id === params.id)
        
        Vue.delete(state.list, index)
    },
    [GET_COURSE_MODULES_LOADING] (state, status) {
        state.courseModulesLoading = status
    },
    [SET_COURSE_MATERIALS] (state, data) {
        state.courseMaterials = data
    },
    [GET_COURSE_MATERIALS_LOADING] (state, status) {
        state.courseMaterialsLoading = status
    },
    [COURSES_FILTER_CHANGE] (state, params) {
        switch (params.type) {
            case 'type': {
                state.filterType = params.value
                break
            }
            case 'country': {
                state.filterCountries = params.value
                break
            }
            case 'store': {
                state.filterStore = params.value
                break
            }
            case 'status': {
                state.filterStatus = params.value
                break
            }
            default: {
                state.filterSearchInput = params.value
            }
        }
    },
    [COURSES_CHANGE_STEP] (state, step) {
        state.step = step
    },
    [COURSES_CHANGE_LOCK_STEP] (state, status) {
        state.stepLock = status
    },
    [COURSES_SET_SETTING] (state, setting) {
        const components = setting.courses.components;
        const currentComponents = [];
        
        for (const i in components) {
            let obj = {};
            
            obj = components[i]
            obj.customId = components[i].id
            obj.packages = components[i].packages ? components[i].packages : { data: [] }
            
            currentComponents.push(getComponentsItemBySlug(obj))
        }
        
        setting.courses.components = currentComponents
        state.setting = setting
    },
    [COURSES_SET_CATEGORIES] (state, categories) {
        state.categories = categories
    },
    [COURSES_SET_TYPES] (state, types) {
        state.types = types
    },
    [COURSES_LIST_CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [GET_COURSES_SIMPLE_RESPONSE_LOADING] (state, status) {
        state.isSimpleCourseLoading = status
    },
    [SET_COURSES_SIMPLE_RESPONSE] (state, list) {
        state.simpleCourses = list
    },
    [SET_COURSE_USERS_IDS] (state, list) {
        let ids = [];
        
        list.forEach(e => {
            ids.push(e.user_id)
        });
        
        state.courseUsersIds = ids
    },
    [GET_COURSE_USERS_IDS_LOADING] (state, status) {
        state.isCourseUsersIdsLoading = status
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
