import { $http } from '@/utils/https'
import Vue from 'vue'
const camelcaseObjectDeep = require('camelcase-object-deep')
import {
  SET_LIST,
  CHANGE_LIST_LOADING,
  SET_LIST_LENGTH,
  CHANGE_SKIP,
  CHANGE_LOADING,
  SET_ITEM,
  ADD_LIST_ITEM,
  SET_UPDATED_LIST_ITEM,
} from './types/mutation-types'

import {
  GET_LIST,
  GET_LIST_ITEM,
  CREATE,
  UPDATE,
  DELETE,
  UPDATE_SORT
} from './types/action-types'

const state = {
  list: null,
  currentItem: null,
  isLoading: false,
  isListLoading: false,
  limit: 20,
  skip: 0
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  limit: state => state.limit,
  skip: state => state.skip,
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/home-works/${payload.id}/questions?include=answers,status,type`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/home-works/${state.currentItem.id}?include=answers,status,type`)

      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      let response = '';
      if(payload.get('type_id') === 1){
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/modules/${payload.get('module_id')}/home-works/${payload.get('home_work_id')}/questions?include=answers,status,type`, payload)
      }else{
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/modules/${payload.get('module_id')}/home-works/${payload.get('home_work_id')}/questions?include=answers,status,type`, payload)
      }
      commit(ADD_LIST_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/modules/${payload.get('module_id')}/home-works/${payload.get('home_work_id')}/questions/${payload.get('id')}?include=answers,status,type`, payload)
      commit(SET_UPDATED_LIST_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/home-works/${payload.home_work_id}/questions/${payload.id}?include=answers,status,type`)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_SORT]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/modules/${payload.get('module_id')}/home-works/${payload.get('home_work_id')}/questions/sort?include=answers,status,type`, payload)

      commit(SET_LIST, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [ADD_LIST_ITEM] (state, data) {
    state.list.push(data)
  },
  [SET_UPDATED_LIST_ITEM] (state, data) {
    const index = state.list.find(e => e.id === data.id)
    Vue.set(state.list, index, data)
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
