import Vue from 'vue'
import Vue2Crumbs from 'vue-2-crumbs'
import VueTheMask from 'vue-the-mask'
import VTooltip from 'v-tooltip'
import Clipboard from 'v-clipboard'
import Toasted from 'vue-toasted'
import VueYoutube from 'vue-youtube'
import VueDisqus from 'vue-disqus'
import Vuetify from 'vuetify/lib'
import DatetimePicker from 'vuetify-datetime-picker'
import { VueMaskDirective } from 'v-mask'
import 'moment/locale/uk'
import 'moment/locale/ru'
Vue.directive('mask', VueMaskDirective)

// use
Vue.use(Vuetify)
Vue.use(VTooltip)
Vue.use(VueDisqus)
Vue.use(Vue2Crumbs)
Vue.use(DatetimePicker)
Vue.use(VueYoutube)
Vue.use(Toasted, {
  duration: 5000
})
Vue.use(VueTheMask)
Vue.use(Clipboard)

export const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdiSvg' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#1d1d1d',
        blue: '#404040',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        'blue-grey': '#607D8B'
      },
      dark: {
        primary: '#444444',
        'blue-grey': '#000'
      }
    }
  }
})
