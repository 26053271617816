export default [
  {
    path: 'feedbacks',
    name: 'feedbacks',
    component: () => import('@/modules/feedbacks/main/index.vue'),
    meta: {}
  },
  {
    path: 'feedbacks/:feedback_id',
    name: 'feedbacks.edit',
    component: () => import('@/modules/feedbacks/edit/index.vue'),
    meta: {},
  }
]
