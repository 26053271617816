import Vue from 'vue'
import { $http } from '@/utils/https'
import { transformerSeo } from '@/transformers';
import {map} from 'lodash';

import {
  ADD_COUNTRY,
  ADD_LIST_ITEM,
  ADD_SEO_ITEM,
  ADD_TRANSLATION_ITEM,
  CHANGE_DIALOG_VIEW,
  CHANGE_EDIT_ID,
  CHANGE_LIST_LOADING,
  CHANGE_LOADING,
  CHANGE_SKIP,
  FRESH_COUNTRY,
  FRESH_SEO_ITEM,
  FRESH_TRANSLATION_ITEM, REMOVE_LIST_ITEM,
  REPLACE_LIST_ITEM,
  SEO_LOADING,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH,
  SET_SEO,
  UPDATE_COUNTRIES_LOADING,
  UPDATE_SEO_LOADING,
  UPDATE_TRANSLATION_LOADING
} from './types/mutation-types'

import {
  CREATE, DELETE,
  GET_LIST,
  GET_LIST_ITEM,
  UPDATE
} from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: [],
  isLoading: false,
  isListLoading: false,
  currentItem: null,
  limit: 13,
  skip: 0,
  listLength: 0,
  openDialogView: false,
  editId: '',

  checkList: [],
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  isLoading: state => state.isLoading,
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  isListLoading: state => state.isListLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  skip: state => state.skip,
  openDialogView: state => state.openDialogView,
  editId: state => state.editId,

  checkList: state => state.checkList
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      if (payload) {
        const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}niches/list?include=translations&limit=999&skip=${state.skip}`)
        commit(SET_LIST, response.data.data)
        commit(SET_LIST_LENGTH, response.data.meta.total)
      } else {
        const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}niches/list?include=translations&limit=${state.limit}&skip=${state.skip}`)
        commit(SET_LIST, response.data.data)
        commit(SET_LIST_LENGTH, response.data.meta.total)
      }
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}niches/${state.editId}`)

      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}niches/${payload.get('id')}`, payload)
      commit(REPLACE_LIST_ITEM, response.data.data)
      commit(SET_ITEM, response.data.data)
      dispatch(GET_LIST)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}niches`, payload)
      commit(ADD_LIST_ITEM, response.data.data)
      commit(SET_ITEM, response.data.data)
      dispatch(GET_LIST)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}niches/${payload.id}`)
      commit(REMOVE_LIST_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  CHECK_ITEM_IN_COURSES: async ({commit}, id) => {
    try {
      let response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}niches/${id}/courses`)
      if (response.data.data.length > 0){
        let resultList = map(response.data.data, item => {
          return item.name
        })

        commit('ADD_TO_CHECK_LIST', resultList);
      }
      return response.data.data
    } catch (e) {
        throw e
    } finally {
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [REPLACE_LIST_ITEM] (state, data) {
    const index = state.list.findIndex(e => e.id === data.id)
    Vue.set(state.list, index, data)
  },
  [ADD_LIST_ITEM] (state, data) {
    state.list.unshift(data)
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [CHANGE_DIALOG_VIEW] (state, status) {
    state.openDialogView = status
  },
  [CHANGE_EDIT_ID] (state, id) {
    state.editId = id
  },
  [REMOVE_LIST_ITEM] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  },

  ADD_TO_CHECK_LIST (state, checkList) {
      state.checkList = state.checkList.concat(checkList)

  },
  RESET_CHECK_LIST (state) {
      state.checkList = []
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}
