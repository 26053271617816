export const translation = {
  noData: 'No data',
  successUserDelete: 'User successfully deleted',
  validationSameAs: 'Passwords do not match',
  maxLengthField: 'Maximum number of field characters :field - :count',
  minLengthField: 'Minimum number of field characters :field - :count',
  validationRequired: 'Fill out this field',
  validationEmail: 'Enter a valid email',
  passwordField: 'Password',
  confirmPasswordField: 'Confirm password',
  emailField: 'Email',
  fullNameField: 'Full name',
  lastNameField: 'Last Name',
  countryField: 'Country',
  phoneField: 'Phone',
  birthdayField: 'Date of birth',
  cancel: 'Cancel',
  save: 'Save',
  done: 'Done',
  successAuth: 'Successful authorization',
  access: 'Accesses',
  status: 'Status',
  active: 'Active',
  inActive: 'Inactive',
  sendPasswordToEmail: 'Send password to email address',
  author: 'Could be an author',
  speaker: 'Could be an speaker',
  generateRandomPassword: 'Generate password',
  accessToCourses: 'Access to Courses',
  select: 'Select',
  userSuccessCreate: 'User created successfully',
  userSuccessUpdate: 'User data successfully updated',
  userSuccessChangePassword: 'Password changed successfully',
  loadingDataWaiting: 'Loading ... Please wait',
  logout: 'Exit',
  btnCreate: 'Create',
  create: 'Create',
  read: 'Read',
  update: 'Change',
  delete: 'Delete',
  students: 'Students',
  admins: 'Admins',
  edit: 'Editing',
  btnExport: 'Export',
  btnImport: 'Import',
  kindOfActivity: 'Current activity',
  student: 'Student',
  businessman: 'Entrepreneur',
  employee: 'Employee',
  freelancer: 'Freelancer',
  another: 'Other',
  countEmployee: 'Number of employees',
  genderField: 'Gender',
  male: 'Male',
  female: 'Female',
  more: 'more',
  changePassword: 'Change password',
  interfaceLanguage: 'Interface language',
  btnAdd: 'Add',
  roles: 'Roles',
  roleAdmin: 'Administrator',
  roleModerator: 'Moderator',
  roleFull: 'Full',
  yes: 'Yes',
  courses: 'Courses',
  originCountryField: 'Origin country ',
  defaultCountryField: 'Default country',
  accessCountryField: 'Access country',
  courseTypeField: 'Courses types',
  categoryField: 'Courses category',
  statusField: 'Status',
  nextLessonRuleField: 'Next lesson Rule',
  next: 'Next',
  add: 'Add',
  back: 'Back',
  video: 'Video',
  type: 'Type',
  change: 'Change',
  locale: 'Locale',
  file: 'File',
  close: 'Close',
  createNewsDate: 'Date',
  preview: 'Preview',
  nameField: 'Name',
  urlField: 'URL',
  newsTypeField: 'News types',
  newsTagsField: 'Tags',
  dateExpired: 'Date expired',
  pageNotFoundDescription: 'Page not found',
  newsSuccessCreate: 'News created successfully',
  newsSuccessUpdate: 'News updated successfully',
  newsSuccessCreateSeo: 'Seo created successfully',
  newsSuccessUpdateSeo: 'Seo updated successfully',
  newsSuccessCreateContent: 'Content created successfully',
  newsSuccessUpdateContent: 'Content updated successfully',
  accessSuccessUpdate: 'Access success update',
  accessSuccessCreate: 'Access success create',
  accessSuccessDelete: 'Access success delete',
  modulesAccessSuccessUpdate: 'Modules access success update',
  userCourseDelete: 'The course success delete',
  userSuccessAddCourse: 'The course success added',
  userSuccessUpdateCourse: 'The course success updated',
  itemIsTied: 'This item is tied to:',
  newstags: 'Tags',
  dragAll: 'Move all',
  newscategories: 'Categories',
  news: 'News',
  types: 'Types',
  modules: 'Modules',
  lessons: 'Lessons',
  accessCountries: 'Access countries',
  moduleSuccessCreate: 'Module success create',
  lessonSuccessCreate: 'Lesson success create',
  moduleSuccessUpdate: 'Module success update',
  lessonSuccessUpdate: 'Lesson success update',

  home_work_type_options: 'Test',
  home_work_type_text: 'Practical',
  btn_create_home_work: 'Create home work',
  on_line_translation: 'Online translation',
  btn_create_lesson: 'Create lesson',
  questions: 'Questions',
  qa_sessions: 'Q/A Sessions',
  meetups: 'Meetups',
  bonuses: 'Bonuses',
  text: 'Text',
  webinars: 'Webinars',
  master_classes: 'Master classes',
  success_create_course: 'Success create course',
  success_info_course_update: 'Success update course info',
  course_components_change_position: 'Success change position',
  course_add_components: 'Success add component to course',
  course_remove_components: 'Success remove component from course',
  success_update_course_store: 'Success update course store',
  success_deleted: 'Success deleted',
  success_updated: 'Success updated',
  success_upload: 'Success uploaded',
  success_created: 'Success created',
  success_cancel: 'Success canceled',
  success_change_position: 'Success change position',
  updating_data: 'Updating data...',

  course_type_digital_professions_academy: 'Digital professions academy',
  course_type_standard_gm_product: 'Standard GM product',
  course_status_active: 'Active',
  course_status_testing: 'Testing',
  course_status_disabled: 'Disable',
  next_lesson_rule_everything_is_open: 'Everything is open',
  next_lesson_rule_view_lesson_more_5_minutes: 'View lesson more 5 minutes',
  next_lesson_rule_option_homework_successful: 'Option homework successful',
  next_lesson_rule_all_homework_successful: 'All homework successful',

  save_all: 'Save all',

  statistics: 'Statistics',
  title: 'Title',
  description: 'Description'
}
