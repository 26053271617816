import Vue from 'vue'
import { $http } from '@/utils/https'
import { camelCase } from 'lodash'
import { transformerSeo } from '@/transformers'

import {
  ADD_SEO_ITEM,
  INFO_FRESH_IMAGE,
  INFO_GET_COURSE_LOADING,
  INFO_SEO_LOADING,
  INFO_SET_COURSE,
  INFO_SET_SEO,
  INFO_UPDATE_IMAGE_LOADING,
  INFO_UPDATE_SEO_LOADING,
  SET_INFO_LOADING,
  UPDATE_SEO_ITEM
} from './types/mutation-types'

import {
  CREATE_COURSE,
  INFO_COURSE_UPDATE,
  INFO_GET_COURSE,
  INFO_GET_SEO,
  INFO_UPDATE_IMAGE,
  INFO_UPDATE_SEO
} from './types/action-types'

const state = {
  info: null,
  infoLoading: false,
  loading: false,
  seo: null,
  seoLoading: false,
  updateSeoLoading: false,
  updateImageLoading: false
}

const getters = {
  info: state => {
    const obj = {}
    for (const i in state.info) {
      obj[camelCase(i)] = state.info[i]
    }
    obj.countries = obj?.countries?.data.map(e => e.country_id) || ''
    obj.countryId = obj?.country?.data.country_id || ''
    return obj
  },
  infoLoading: state => state.infoLoading,
  loading: state => state.loading,
  seo: state => transformerSeo(state.seo),
  seoLoading: state => state.seoLoading,
  updateSeoLoading: state => state.updateSeoLoading,
  updateImageLoading: state => state.updateImageLoading
}

const actions = {
  [INFO_GET_COURSE]: async ({ commit }, payload) => {
    try {
      commit(INFO_GET_COURSE_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.id}?include=country,countries,image,image.seo,components,nextLessonRule,skills,skills.skill,notifications.template.translations`)
      commit(INFO_SET_COURSE, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(INFO_GET_COURSE_LOADING, false)
    }
  },
  [CREATE_COURSE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(SET_INFO_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses?include=country,countries`, payload)
      dispatch(INFO_GET_COURSE, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(SET_INFO_LOADING, false)
    }
  },
  [INFO_COURSE_UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(SET_INFO_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('id')}?include=country,countries`, payload)
      dispatch(INFO_GET_COURSE, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(SET_INFO_LOADING, false)
    }
  },
  [INFO_GET_SEO]: async ({ commit }, payload) => {
    try {
      commit(INFO_SEO_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/seo`)
      commit(INFO_SET_SEO, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(INFO_SEO_LOADING, false)
    }
  },
  [INFO_UPDATE_SEO]: async ({ commit }, payload) => {
    try {
      commit(INFO_UPDATE_SEO_LOADING, true)
      if (payload.hasOwnProperty('_method')) {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/seo/${payload.id}`, payload)
        commit(UPDATE_SEO_ITEM, response.data.data)
      } else {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/seo`, payload)
        commit(ADD_SEO_ITEM, response.data.data)
      }
    } catch (e) {
      throw e
    } finally {
      commit(INFO_UPDATE_SEO_LOADING, false)
    }
  },
  [INFO_UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(INFO_UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/images/${payload.get('id')}?include=seo`, payload)
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/images?include=seo`, payload)
      }
      commit(INFO_FRESH_IMAGE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(INFO_UPDATE_IMAGE_LOADING, false)
    }
  }
}

const mutations = {
  [INFO_SET_COURSE] (state, content) {
    state.info = content
  },
  [INFO_GET_COURSE_LOADING] (state, status) {
    state.infoLoading = status
  },
  [SET_INFO_LOADING] (state, status) {
    state.loading = status
  },
  [INFO_UPDATE_SEO_LOADING] (state, status) {
    state.updateSeoLoading = status
  },
  [INFO_SEO_LOADING] (state, status) {
    state.seoLoading = status
  },
  [INFO_SET_SEO] (state, content) {
    state.seo = content
  },
  [UPDATE_SEO_ITEM] (state, data) {
    const index = state.seo.findIndex(e => e.locale === data.locale)
    Vue.set(state.seo, index, data)
  },
  [ADD_SEO_ITEM] (state, content) {
    state.seo.push(content)
  },
  [INFO_UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [INFO_FRESH_IMAGE] (state, data) {
    state.info.image.data = data
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
