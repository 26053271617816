import Vue from 'vue'
import { $http } from '@/utils/https'
import {
  CHANGE_FILTER,
  CHANGE_LIST_LOADING,
  CHANGE_LOADING,
  CHANGE_SHOW_HOME_WORK_DIALOG_LESSON,
  CHANGE_SKIP,
  GET_COURSE_LOADING,
  REPLACE_LIST_ITEM,
  SET_COURSE,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH,
  CHANGE_SORT,
  REPLACE_CANCELED_ITEM_FROM_LIST,
  SET_HOME_WORKS,
  SET_GRADUATE_WORK,
  SET_COURSE_LIST
} from './types/mutation-types'

import {
  ACCEPT_QUESTION,
  CANCEL,
  COMPLETE_WORK,
  DECLINE_QUESTION,
  GET_COURSE,
  GET_LIST,
  GET_LIST_ITEM,
  GET_COURSE_MODULES,
  GET_COURSE_HOME_WORKS,
  GET_COURSE_GRADUATE_WORK,
  COURSE_LIST
} from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: [],
  currentItem: null,
  isLoading: false,
  isListLoading: false,
  showHomeWorkDialog: false,
  limit: 20,
  skip: 0,
  listLength: 0,
  sortData: '',

  filters: {
    module: '',
    status: '',
    dateFrom: '',
    dateTo: '',
    isChecking: 1,
    user: '',
    homeWork: '',
    graduateWork: ''
  },

  currentCourse: null,
  isLoadingCourse: false,
  homeWorks: null,
  graduateWork: null,
  simpleList: null,
  filterCourseId: null
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  listLength: state => state.listLength,
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  limit: state => state.limit,
  skip: state => state.skip,
  showHomeWorkDialog: state => state.showHomeWorkDialog,

  filters: state => state.filters,

  currentCourse: state => state.currentCourse,
  isLoadingCourse: state => state.isLoadingCourse,
  homeWorks: state => camelcaseObjectDeep(state.homeWorks),
  graduateWork: state => state.graduateWork,
  simpleList: state => state.simpleList,
  filterCourseId: state => state.filterCourseId
}

const actions = {
  // for filter
  [GET_COURSE]: async ({ commit }, payload) => {
    try {
      commit(GET_COURSE_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}?include=modules`)
      commit(SET_COURSE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_COURSE_LOADING, false)
    }
  },
  // end filter
  [GET_COURSE_MODULES]: async ({ commit }, payload) => {
    try {
      commit(GET_COURSE_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}modules/list?filters[course_id]=${payload.courseId || ''}&limit=100`)
      commit(SET_COURSE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_COURSE_LOADING, false)
    }
  },
  [GET_COURSE_GRADUATE_WORK]: async ({ commit }, payload) => {
    try {
      commit(GET_COURSE_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}graduate-works/list?filters[course_id]=${payload.courseId || ''}`)
      commit(SET_GRADUATE_WORK, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_COURSE_LOADING, false)
    }
  },
  [GET_COURSE_HOME_WORKS]: async ({ commit }, payload) => {
    try {
      commit(GET_COURSE_LOADING, true)
      if (payload?.moduleId) {
        const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}home-works/list?filters[module_id]=${payload.moduleId || ''}&limit=100`)
        commit(SET_HOME_WORKS, response.data.data)
      } else {
        const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}home-works/list?filters[course_id]=${payload.courseId || ''}&limit=100`)
        commit(SET_HOME_WORKS, response.data.data)
      }
    } catch (e) {
      throw e
    } finally {
      commit(GET_COURSE_LOADING, false)
    }
  },

  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}users/works?include=course,module,user,status,homeWork,graduateWork&filters[course]=${payload.courseId || ''}&filters[module]=${state.filters.module || ''}&filters[status]=${state.filters.status || ''}&filters[date_from]=${state.filters.dateFrom || ''}&filters[user]=${state.filters.user || ''}&filters[date_to]=${state.filters.dateTo || ''}&limit=${state.limit}&skip=${state.skip}&filters[home_work]=${state.filters.homeWork || ''}&filters[graduate_work]=${state.filters.graduateWork || ''}`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      let response = ''
      if (payload.workType === 'homeWork') {
        response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}users/home-works/${payload.homeWorkId}?include=course,module,homeWork.type,user.personalData,status,homeWorkQuestions.answers.userAnswer,homeWorkQuestions.userQuestion,checker.personalData`)
      } else {
        response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}users/graduate-works/${payload.graduateWorkId}?include=course,graduateWork,user.personalData,status,checker.personalData`)
      }

      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [ACCEPT_QUESTION]: async ({ dispatch, commit }, payload) => {
    try {
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/home-works/${payload.get('homeWorkId')}/questions/${payload.get('id')}/accept`, payload)
      commit(SET_ITEM, response.data.data)
      dispatch(GET_LIST_ITEM, {
        homeWorkId: payload.get('homeWorkId'),
        workType: 'homeWork'
      })
    } catch (e) {
      throw e
    }
  },
  [DECLINE_QUESTION]: async ({ dispatch, commit }, payload) => {
    try {
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/home-works/${payload.get('homeWorkId')}/questions/${payload.get('id')}/decline`, payload)
      commit(SET_ITEM, response.data.data)
      dispatch(GET_LIST_ITEM, {
        homeWorkId: payload.get('homeWorkId'),
        workType: 'homeWork'
      })
    } catch (e) {
      throw e
    }
  },
  [CANCEL]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      let response = ''
      if (payload.get('workType') === 'homeWork') {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/home-works/${payload.get('currentId')}/cancel?include=course,graduateWork,user,status,checker`, payload)
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/graduate-works/${payload.get('currentId')}/cancel?include=course,graduateWork,user,status,checker`, payload)
      }
      dispatch(GET_LIST, { courseId: response.data.data.course_id })
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [COMPLETE_WORK]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      let response = ''
      if (payload.get('workType') && payload.get('workType') === 'homeWork') {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/home-works/${payload.get('currentId')}/complete?include=module,user,checker,status,homeWork,homeWork.type,homeWorkQuestions`, payload)
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/graduate-works/${payload.get('currentId')}/complete?include=course,graduateWork,user,status,checker`, payload)
      }
      dispatch(GET_LIST, { courseId: response.data.data.course_id })
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },

  RESET: async ({ dispatch, commit }, payload) => {
      Object.assign(payload, {
		  '_method': 'patch'
      } );

      try {
          commit(CHANGE_LOADING, true);
          let response;
      
      if (payload.workType && (payload.workType === 'home_work' || payload.workType === 'homeWork')) {
			  response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/home-works/${payload.userHomeWorkId}/cancel`, payload)
		  } else {
			  response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/graduate-works/${payload.userGraduateWorkId}/cancel`, payload)
		  }

		  return response;
      } catch (e) {
          throw e
      } finally {
          commit(CHANGE_LOADING, false)
      }
  },
  [COURSE_LIST]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/list?filters[id]=${payload.courseId || ''}`)
      commit(SET_COURSE_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [CHANGE_SHOW_HOME_WORK_DIALOG_LESSON] (state, status) {
    state.showHomeWorkDialog = status
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [REPLACE_LIST_ITEM] (state, data) {
    const index = state.list.findIndex(e => e.id === data.id)
    Vue.set(state.list, index, data)
  },
  [REPLACE_CANCELED_ITEM_FROM_LIST] (state, data) {
    const index = state.list.findIndex(e => e.id === data.id)
    Vue.set(state.list, index, data)
  },
  [GET_COURSE_LOADING] (state, status) {
    state.isLoadingCourse = status
  },
  [SET_COURSE] (state, data) {
    state.currentCourse = data
  },
  [SET_HOME_WORKS] (state, data) {
    state.homeWorks = data
  },
  [SET_GRADUATE_WORK] (state, data) {
    state.graduateWork = data
  },
  [CHANGE_SORT] (state, data) {
    state.sortData = data
  },
  [CHANGE_FILTER] (state, data = null) {
    if (!!data && data.type !== 'graduateWork') state.filters.graduateWork = ''
    if (!data) {
      for (const i in state.filters) {
        state.filters[i] = ''
      }
      state.filters.isChecking = '1'
      return
    }
    state.filters[data.type] = data.value
  },
  [SET_COURSE_LIST] (state, data) {
    state.simpleList = data
  },
  setFilterCourseId (state, data) {
    state.filterCourseId = data
  },
  setFilterData (state, data) {
    state.filters = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
