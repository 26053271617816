import { $http } from '@/utils/https'
import Vue from 'vue'
import {
  CONTENT_GET_DATA_LOADING,
  CONTENT_SET_DATA,
  CONTENT_SET_NEW_ITEM,
  CONTENT_UPDATE_DATA_LOADING,
  CONTENT_UPDATE_ITEM
} from './types/mutation-types'

import { CONTENT_GET_DATA, CREATE, UPDATE } from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  content: null,
  isLoading: false,
  contentUpdateLoading: false
}

const getters = {
  content: state => {
    if (state.content) {
      return {
        id: state.content.id,
        country: state.content.country_id,
        title: state.content.title,
        shortContent: state.content.short_content,
        defaultLocale: state.content.default_locale,
        content: state.content.content.data.map(e => {
          return camelcaseObjectDeep(e)
        })
      }
    }
  },
  isLoading: state => state.isLoading,
  contentUpdateLoading: state => state.contentUpdateLoading
}

const actions = {
  [CONTENT_GET_DATA]: async ({ commit }, payload) => {
    try {
      commit(CONTENT_GET_DATA_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_EVENT}events/${payload.id}?include=content`)
      commit(CONTENT_SET_DATA, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CONTENT_GET_DATA_LOADING, false)
    }
  },
  [UPDATE]: async ({ commit }, payload) => {
    try {
      commit(CONTENT_UPDATE_DATA_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_EVENT}contents/${payload.id}`, payload)
      commit(CONTENT_UPDATE_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CONTENT_UPDATE_DATA_LOADING, false)
    }
  },
  [CREATE]: async ({ commit }, payload) => {
    try {
      commit(CONTENT_UPDATE_DATA_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EVENT}contents`, payload)
      commit(CONTENT_SET_NEW_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CONTENT_UPDATE_DATA_LOADING, false)
    }
  }
}

const mutations = {
  [CONTENT_SET_DATA] (state, data) {
    state.content = data
  },
  [CONTENT_SET_NEW_ITEM] (state, data) {
    state.content.content.data.push(data)
  },
  [CONTENT_UPDATE_ITEM] (state, data) {
    const index = state.content.content.data.findIndex(e => e.locale === data.locale)
    Vue.set(state.content.content.data, index, data)
  },
  [CONTENT_GET_DATA_LOADING] (state, status) {
    state.isLoading = status
  },
  [CONTENT_UPDATE_DATA_LOADING] (state, status) {
    state.contentUpdateLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
