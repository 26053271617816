import { $http } from '@/utils/https'
import {
  SET_STATISTICS_COURSE_DETALIES,
  SET_STATISTICS_COURSE_STUDENTS,
  SET_STATISTICS_LOADER,
  SET_STATISTICS_LESSON_DETAILS,
  SET_STATISTICS_HOME_WORK_DETAILS,
  SET_STATISTICS_GRADUATE_WORK_DETAILS,
  SET_STATISTICS_USER_DETAILS,
  SET_EXPORT_STATUS,
} from './types/mutation-types'

import {
  GET_STATISTICS_COURSE_DETALIES,
  GET_STATISTICS_COURSE_STUDENTS,
  GET_STATISTICS_LESSON_DETAILS,
  GET_STATISTICS_HOME_WORK_DETAILS,
  GET_STATISTICS_GRADUATE_WORK_DETAILS,
  GET_STATISTICS_USER_DETAILS,
  GET_EXPORT_STATUS
} from './types/action-types'

const state = {
  courseDetaliesData: null,
  courseStudentsData: null,
  loading: false,
  lessonData: null,
  homeWorkData: null,
  graduateWorkData: null,
  userData: null,
  exportStatus: false
}

const getters = {
  courseDetaliesData: state => state.courseDetaliesData,
  courseStudentsData: state => state.courseStudentsData,
  loading: state => state.loading,
  lessonData: state => state.lessonData,
  homeWorkData: state => state.homeWorkData,
  graduateWorkData: state => state.graduateWorkData,
  userData: state => state.userData,
  exportStatus: state => state.exportStatus
}

const actions = {
  [GET_STATISTICS_COURSE_DETALIES]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_STATISTIC}courses/${payload.courseId}?include=packages,modules.materials,graduateWork`)
      commit(SET_STATISTICS_COURSE_DETALIES, response.data.data)
    } catch (e) {
      throw e
    }
  },
  [GET_STATISTICS_COURSE_STUDENTS]: async ({ commit }, payload) => {
    commit(SET_STATISTICS_LOADER, true)
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_STATISTIC}courses/${payload.courseId}/users?include=user.personalData,package,currentMaterial.module&filters[package_id]=${payload.packageId ? payload.packageId : ''}`)
      commit(SET_STATISTICS_COURSE_STUDENTS, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SET_STATISTICS_LOADER, false)
    }
  },
  [GET_STATISTICS_LESSON_DETAILS]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_STATISTIC}courses/${payload.courseId}/modules/${payload.moduleId}/lessons/${payload.materialId}?include=userLessons.userCourse.user.personalData,userLessons.userCourse.package,userLessons.userCourse.currentMaterial.module`)
      commit(SET_STATISTICS_LESSON_DETAILS, response.data.data)
    } catch (e) {
      throw e
    } finally {
    }
  },
  [GET_STATISTICS_HOME_WORK_DETAILS]: async ({ commit }, payload) => {
    commit(SET_STATISTICS_LOADER, true)
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_STATISTIC}courses/${payload.courseId}/modules/${payload.moduleId}/home-works/${payload.materialId}?include=${payload.include ? payload.include : 'userHomeWorks.userCourse.user.personalData,userHomeWorks.userCourse.package,userHomeWorks.userCourse.currentMaterial.module'}`)
      commit(SET_STATISTICS_HOME_WORK_DETAILS, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SET_STATISTICS_LOADER, false)
    }
  },
  [GET_STATISTICS_GRADUATE_WORK_DETAILS]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_STATISTIC}courses/${payload.courseId}/graduate-work?include=userGraduateWorks.userCourse.user.personalData,userGraduateWorks.userCourse.package,userGraduateWorks.userCourse.currentMaterial.module`)
      commit(SET_STATISTICS_GRADUATE_WORK_DETAILS, response.data.data)
    } catch (e) {
      throw e
    } finally {
    }
  },
  [GET_STATISTICS_USER_DETAILS]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_STATISTIC}courses/${payload.courseId}/users/${payload.userId}?include=user.personalData,userModules.module,userModules.userMaterials.material,userGraduateWork.graduateWork`)
      commit(SET_STATISTICS_USER_DETAILS, response.data.data)
    } catch (e) {
      throw e
    } finally {
    }
  },
  [GET_EXPORT_STATUS]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_STATISTIC}exports/${payload.export}`)

      commit(SET_EXPORT_STATUS, response.data.data)
    } catch (e) {
      throw e
    } finally {
    }
  }
}

const mutations = {
  [SET_STATISTICS_COURSE_DETALIES] (state, data) {
    state.courseDetaliesData = data
  },
  [SET_STATISTICS_COURSE_STUDENTS] (state, data) {
    state.courseStudentsData = data
  },
  [SET_STATISTICS_LOADER] (state, status) {
    state.loading = status
  },
  [SET_STATISTICS_LESSON_DETAILS] (state, data) {
    state.lessonData = data
  },
  [SET_STATISTICS_HOME_WORK_DETAILS] (state, data) {
    state.homeWorkData = data
  },
  [SET_STATISTICS_GRADUATE_WORK_DETAILS] (state, data) {
    state.graduateWorkData = data
  },
  [SET_STATISTICS_USER_DETAILS] (state, data) {
    state.userData = data
  },
  [SET_EXPORT_STATUS](state, data) {
    state.exportStatus = data.status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
