export function transformerAllUsers(list) {
    if (!list.length) return []
    
    return list.map(item => {
        return {
            id          : item.id,
            email       : item.email,
            fullName    : item.personalData.data.full_name,
            activity    : item.personalData.data.activity ? item.personalData.data.activity.type : '',
            avatar      : item.personalData.data.avatar,
            countrySlug : item.defaultCountry.data.hasOwnProperty('id') ? item.defaultCountry.data.country.data.hasOwnProperty('slug') ? item.defaultCountry.data.country.data.slug : '' : '',
            countryId   : item.defaultCountry.data.country_id
        }
    })
}

export function transformerCurrentUser(item) {
    if (!item) return
    
    const originCountry     = item.countries.data.length > 0 ? item.countries.data.find(e => e.type === 'origin') : ''
    const defaultCountry    = item.countries.data.length > 0 ? item.countries.data.find(e => e.type === 'default') : ''
    
    // ...
    
    return {
        countries           : item.countries,
        fullName            : item.personalData.data.full_name,
        email               : item.email,
        id                  : item.id,
        originCountry       : typeof originCountry !== "undefined" ? originCountry.country_id : '',
        defaultCountry      : defaultCountry.country_id,
        phone               : item.phone,
        birthday            : item.personalData.data.birthday,
        activity            : item.personalData.data.activity ? item.personalData.data.activity.type : '',
        gender              : item.personalData.data.gender,
        interface           : item.setting.data.interface_language,
        avatar              : item.personalData.data.avatar,
        image               : {
            data: {
                src : item.personalData.data.avatar,
                id  : item.id,
                seo : {
                    data: []
                }
            }
        },
        personalData        : item.personalData,
        coursesLanguages    : item.setting.data.courses_languages,
        timezoneId          : item.setting.data.timezone_id,
        permissions         : item.permissions,
        roles               : item.roles,
        isAuthor            : !Array.isArray(item.author.data),
        employers           : item.personalData.data.activity ? item.personalData.data.activity.employees_count : '',
        isActive            : item.active,
        isTester            : item.is_tester,
        isNotRequiredCards  : item.is_not_required_cards,
        typeId              : item.type_id
    }
}

export function transformerUsersList(list) {
    return list.map(item => {
        return {
            id          : item.id,
            fullName    : item.personalData.data.full_name,
            email       : item.email,
            points      : item.coursesSummary.data.scored_points,
            country     : item.countries.data.length > 0 ? item.countries.data[0].country.data.hasOwnProperty('slug') ? item.countries.data[0].country.data.slug : '' : '',
            phone       : item.phone,
            create_at   : item.created_at,
            status      : item.active
        }
    })
}

export function transformerUsersAdminsList(list) {
    return list.map(item => {
        return {
            id          : item.id,
            fullName    : item.personalData.data.full_name,
            email       : item.email,
            country     : item.countries.data.length > 0 ? item.countries.data[0].country.data.hasOwnProperty('slug') ? item.countries.data[0].country.data.slug : '' : '',
            phone       : item.phone,
            create_at   : item.created_at,
            status      : item.active
        }
    })
}
