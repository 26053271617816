import { $http } from '@/utils/https'

import {
  GET_TIMETABLE_STARTING_LOADING,
  UPDATE_TIMETABLE_STARTING_LOADING,
  SET_TIMETABLE_STARTING
} from './types/mutation-types'

import {
  GET_TIMETABLE_STARTING,
  UPDATE_TIMETABLE_STARTING
} from './types/action-types'

const state = {
  timetableStarting: null,
  timetableStartingLoading: false,
  updateTimetableStartingLoading: false
}

const getters = {
  timetableStarting: state => state.timetableStarting
}

const actions = {
  [GET_TIMETABLE_STARTING]: async ({ commit }, payload) => {
    try {
      commit(GET_TIMETABLE_STARTING_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.id}/setting`)
      commit(SET_TIMETABLE_STARTING, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_TIMETABLE_STARTING_LOADING, false)
    }
  },
  [UPDATE_TIMETABLE_STARTING]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_TIMETABLE_STARTING_LOADING, true)
      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/setting`, payload)
      commit(SET_TIMETABLE_STARTING, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_TIMETABLE_STARTING_LOADING, false)
    }
  }
}

const mutations = {
  [SET_TIMETABLE_STARTING] (state, content) {
    state.timetableStarting = content
  },
  [GET_TIMETABLE_STARTING_LOADING] (state, status) {
    state.timetableStartingLoading = status
  },
  [UPDATE_TIMETABLE_STARTING_LOADING] (state, status) {
    state.updateTimetableStartingLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
