import {DELETE_FEEDBACK, GET_FEEDBACKS} from "@/store/modules/feedbacks/types/action-types";
import {$http} from "@/utils/https";
import {
  FEEDBACK_CHANGE_LOCK_STEP,
  FEEDBACK_LIST_CHANGE_SKIP,
  GET_DELETE_FEEDBACK_LOADING,
  GET_FEEDBACKS_LOADING,
  SET_FEEDBACKS,
  SET_FEEDBACKS_LENGTH
} from "@/store/modules/feedbacks/types/mutation-types";
import camelCaseKeys from "camelcase-object-deep";

const state = {
  list: [],
  listLength: 0,
  listLoading: false,
  limit: 20,
  skip: 0,
  stepLock: false,
  deleteLoading: false
}

const getters = {
  list: (state) => camelCaseKeys(state.list),
  listLength: (state) => state.listLength,
  listLoading: (state) => state.listLoading,
  limit: (state) => state.limit,
  skip: (state) => state.skip,
  deleteLoading: (state) => state.deleteLoading,
}

const actions = {
  [GET_FEEDBACKS]: async ({ commit }) => {
    try {
      commit(GET_FEEDBACKS_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}forms-feedback`, {
        params: {
          include: 'package,course',
          limit: state.limit,
          skip: state.skip
        }
      })
      commit(SET_FEEDBACKS, response.data.data)
      commit(SET_FEEDBACKS_LENGTH, response.data.meta.total)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(GET_FEEDBACKS_LOADING, false)
    }
  },
  [DELETE_FEEDBACK]: async ({ commit }, payload) => {
    try {
      commit(GET_DELETE_FEEDBACK_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}forms-feedback/${payload.id}`, {})
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(GET_DELETE_FEEDBACK_LOADING, false)
    }
  }
}

const mutations = {
  [SET_FEEDBACKS] (state, list) {
    state.list = list
  },
  [GET_FEEDBACKS_LOADING] (state, listLoading) {
    state.listLoading = listLoading
  },
  [SET_FEEDBACKS_LENGTH] (state, listLength) {
    state.listLength = listLength
  },
  [FEEDBACK_LIST_CHANGE_SKIP] (state, skip) {
    state.skip = skip
  },
  [FEEDBACK_CHANGE_LOCK_STEP] (state, status) {
    state.stepLock = status
  },
  [GET_DELETE_FEEDBACK_LOADING] (state, deleteLoading) {
    state.deleteLoading = deleteLoading
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
