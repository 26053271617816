import Vue from 'vue'
import { $http } from '@/utils/https'
import { transformerSeo } from '@/transformers'

import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_LIST_ITEM,
  GET_SEO,
  UPDATE,
  UPDATE_IMAGE,
  UPDATE_SEO,
  UPDATE_SORT
} from './types/action-types'
import {
  ADD_SEO_ITEM,
  CHANGE_EDIT_ID,
  CHANGE_LOADING,
  FRESH_IMAGE,
  FRESH_SEO_ITEM,
  GET_LIST_LOADING,
  REMOVE_LIST_ITEM,
  SEO_LOADING,
  SET_ITEM,
  SET_LIST,
  SET_NEW_ITEM,
  SET_SEO,
  UPDATE_IMAGE_LOADING,
  UPDATE_LIST_ITEM,
  UPDATE_SEO_LOADING
} from './types/mutation-types'

const state = {
  list: [],
  isListLoading: false,
  content: null,
  isLoading: false,
  currentItem: null,
  updateImageLoading: false,

  seo: null,
  seoLoading: false,
  updateSeoLoading: false
}

const getters = {
  content: state => state.content,
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  currentItem: state => state.currentItem,
  updateImageLoading: state => state.updateImageLoading,
  list: state => state.list,
  seo: state => transformerSeo(state.seo),
  seoLoading: state => state.seoLoading,
  updateSeoLoading: state => state.updateSeoLoading
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(GET_LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/live-streams?include=packages,status,speakers,image,image.seo`)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_LIST_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/live-streams?include=packages,status`, payload)
      dispatch(GET_LIST, response.data.data)
      dispatch(GET_LIST_ITEM, response.data.data)

      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/live-streams/${payload.get('id')}?include=packages,status,speakers`, payload)
      dispatch(GET_LIST, response.data.data)
      dispatch(GET_LIST_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/live-streams/${payload.id}`)
      commit(REMOVE_LIST_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_SORT]: async ({ commit }, payload) => {
    try {
      commit(GET_LIST_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/live-streams/sort?include=status,packages`, payload)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/live-streams/${payload.id}?include=packages,status,speakers,image,image.seo`)
      commit(SET_ITEM, response.data.data)
      return response
    } catch (e) {
      throw e
    }
  },
  [GET_SEO]: async ({ commit }, payload) => {
    try {
      commit(SEO_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/live-streams/${payload.id}/seo`)
      commit(SET_SEO, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SEO_LOADING, false)
    }
  },
  [UPDATE_SEO]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_SEO_LOADING, true)
      if (payload.hasOwnProperty('_method')) {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/live-streams/${payload.stream_id}/seo/${payload.id}`, payload)
        commit(FRESH_SEO_ITEM, response.data.data)
      } else {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/live-streams/${payload.stream_id}/seo`, payload)
        commit(ADD_SEO_ITEM, response.data.data)
      }
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_SEO_LOADING, false)
    }
  },
  [UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/live-streams/${payload.get('stream_id')}/images/${payload.get('id')}?include=seo`, payload)
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/live-streams/${payload.get('stream_id')}/images?include=seo`, payload)
      }
      commit(FRESH_IMAGE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_IMAGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [GET_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [SET_ITEM] (state, item) {
    state.currentItem = item
  },
  [SET_NEW_ITEM] (state, item) {
    state.list.push(item)
  },
  [UPDATE_LIST_ITEM] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.set(state.list, index, item)
  },
  [REMOVE_LIST_ITEM] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  },
  [CHANGE_EDIT_ID] (state, id) {
    state.editId = id
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [UPDATE_SEO_LOADING] (state, status) {
    state.updateSeoLoading = status
  },
  [SEO_LOADING] (state, status) {
    state.seoLoading = status
  },
  [SET_SEO] (state, content) {
    state.seo = content
  },
  [FRESH_SEO_ITEM] (state, data) {
    const index = state.seo.findIndex(e => e.locale === data.locale)
    Vue.set(state.seo, index, data)
  },
  [ADD_SEO_ITEM] (state, content) {
    state.seo.push(content)
  },
  [UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [FRESH_IMAGE] (state, data) {
    state.currentItem.image.data = data
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
