export const GET_LIST = 'GET_LIST'
export const GET_LIST_ITEM = 'GET_LIST_ITEM'
export const CREATE = 'CREATE'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'
export const ACCEPT_QUESTION = 'ACCEPT_QUESTION'
export const DECLINE_QUESTION = 'DECLINE_QUESTION'
export const COMPLETE_TEST = 'COMPLETE_TEST'

export const UPDATE_SORT = 'UPDATE_SORT'
export const UPDATE_SEO = 'UPDATE_SEO'
export const CANCEL = 'CANCEL'
