import { $http } from '@/utils/https'
import { DEFAULT_FILTERS } from '@/modules/reviews/constants'
import {
  COURSE_LIST,
  GET_COURSE,
  GET_COURSE_GRADUATE_WORK,
  GET_COURSE_HOME_WORKS,
  GET_COURSE_MATERIALS,
  GET_COURSE_MODULES,
  GET_LIST
} from './types/action-types'
import {
  CHANGE_FILTER,
  CHANGE_LIST_LOADING,
  CHANGE_LOADING,
  CHANGE_SHOW_HOME_WORK_DIALOG_LESSON,
  CHANGE_SKIP,
  CHANGE_SORT,
  GET_COURSE_LOADING,
  REPLACE_CANCELED_ITEM_FROM_LIST,
  REPLACE_LIST_ITEM,
  SET_COURSE,
  SET_COURSE_LIST, SET_COURSE_MATERIALS,
  SET_GRADUATE_WORK,
  SET_HOME_WORKS,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH
} from './types/mutation-types'
import camelcaseObjectDeep from 'camelcase-object-deep'
import Vue from 'vue'

const state = {
  list: [],
  currentItem: null,
  isLoading: false,
  isListLoading: false,
  showHomeWorkDialog: false,
  limit: 20,
  skip: 0,
  listLength: 0,
  sortData: '',

  filters: DEFAULT_FILTERS,

  currentCourse: null,
  isLoadingCourse: false,
  homeWorks: null,
  materials: null,
  graduateWork: null,
  simpleList: null,
  filterCourseId: null
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  listLength: state => state.listLength,
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  limit: state => state.limit,
  skip: state => state.skip,
  showHomeWorkDialog: state => state.showHomeWorkDialog,

  filters: state => state.filters,

  currentCourse: state => state.currentCourse,
  isLoadingCourse: state => state.isLoadingCourse,
  homeWorks: state => camelcaseObjectDeep(state.homeWorks),
  materials: state => camelcaseObjectDeep(state.materials),
  graduateWork: state => state.graduateWork,
  simpleList: state => state.simpleList,
  filterCourseId: state => state.filterCourseId
}

const actions = {
  // for filter
  [GET_COURSE]: async ({ commit }, payload) => {
    try {
      commit(GET_COURSE_LOADING, true)

      const response = await $http.get(
        `${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}?include=modules`)
      commit(SET_COURSE, response.data.data)
    } finally {
      commit(GET_COURSE_LOADING, false)
    }
  },
  // end filter
  [GET_COURSE_MODULES]: async ({ commit }, payload) => {
    try {
      commit(GET_COURSE_LOADING, true)

      const response = await $http.get(
        `${process.env.VUE_APP_ROOT_COURSE}modules/list?filters[course_id]=${payload.courseId}&limit=100`)
      commit(SET_COURSE, response.data.data)
    } finally {
      commit(GET_COURSE_LOADING, false)
    }
  },
  [GET_COURSE_GRADUATE_WORK]: async ({ commit }, payload) => {
    try {
      commit(GET_COURSE_LOADING, true)

      const response = await $http.get(
        `${process.env.VUE_APP_ROOT_COURSE}graduate-works/list?filters[course_id]=${payload.courseId}`)
      commit(SET_GRADUATE_WORK, response.data.data)
    } finally {
      commit(GET_COURSE_LOADING, false)
    }
  },
  [GET_COURSE_HOME_WORKS]: async ({ commit }, payload) => {
    try {
      commit(GET_COURSE_LOADING, true)
      if (payload?.moduleId) {
        const response = await $http.get(
          `${process.env.VUE_APP_ROOT_COURSE}home-works/list?filters[module_id]=${payload.moduleId}&limit=100`)
        commit(SET_HOME_WORKS, response.data.data)
      } else {
        const response = await $http.get(
          `${process.env.VUE_APP_ROOT_COURSE}home-works/list?filters[course_id]=${payload.courseId}&limit=100`)
        commit(SET_HOME_WORKS, response.data.data)
      }
    } finally {
      commit(GET_COURSE_LOADING, false)
    }
  },
  [GET_COURSE_MATERIALS]: async ({ commit }) => {
    try {
      commit(GET_COURSE_LOADING, true)
      if (state.filters.module) {
        const { data: { data } } = await $http.get(
          `${process.env.VUE_APP_ROOT_COURSE}modules/${state.filters.module}/materials/list`)
        commit(SET_COURSE_MATERIALS, data)
      } else {
        commit(SET_COURSE_MATERIALS, null)
      }
    } finally {
      commit(GET_COURSE_LOADING, false)
    }
  },
  [GET_LIST]: async ({ commit }, { courseId }) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const {
        data: {
          data,
          meta
        }
      } = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}ratings`, {
        params: {
          include: 'user.personalData,material.course,material.module',
          skip: state.skip,
          limit: state.limit,
          'filters[course_id]': courseId,
          'filters[module_id]': state.filters.module || '',
          'filters[status]': state.filters.status || '',
          'filters[date_from]': state.filters.dateFrom || '',
          'filters[date_to]': state.filters.dateTo || '',
          'filters[user_id]': state.filters.user || '',
          'filters[ratable_type]': state.filters.materialType || '',
          'filters[ratable_id]': state.filters.material || ''

        }
      })
      commit(SET_LIST, data)
      commit(SET_LIST_LENGTH, meta.total)
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [COURSE_LIST]: async ({ commit }, payload) => {
    try {
      const response = await $http.get(
        `${process.env.VUE_APP_ROOT_COURSE}courses/list?filters[id]=${payload.courseId}`)
      commit(SET_COURSE_LIST, response.data.data)
    } finally {
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [CHANGE_SHOW_HOME_WORK_DIALOG_LESSON] (state, status) {
    state.showHomeWorkDialog = status
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [REPLACE_LIST_ITEM] (state, data) {
    const index = state.list.findIndex(e => e.id === data.id)
    Vue.set(state.list, index, data)
  },
  [REPLACE_CANCELED_ITEM_FROM_LIST] (state, data) {
    const index = state.list.findIndex(e => e.id === data.id)
    Vue.set(state.list, index, data)
  },
  [GET_COURSE_LOADING] (state, status) {
    state.isLoadingCourse = status
  },
  [SET_COURSE] (state, data) {
    state.currentCourse = data
  },
  [SET_HOME_WORKS] (state, data) {
    state.homeWorks = data
  },
  [SET_COURSE_MATERIALS] (state, data) {
    state.materials = data
  },
  [SET_GRADUATE_WORK] (state, data) {
    state.graduateWork = data
  },
  [CHANGE_SORT] (state, data) {
    state.sortData = data
  },
  [CHANGE_FILTER] (state, data = null) {
    if (!!data && data.type !== 'graduateWork') state.filters.graduateWork = ''
    if (!data) {
      for (const i in state.filters) {
        state.filters[i] = ''
      }
      state.filters.isChecking = '1'
      return
    }
    state.filters[data.type] = data.value
  },
  [SET_COURSE_LIST] (state, data) {
    state.simpleList = data
  },
  setFilterCourseId (state, data) {
    state.filterCourseId = data
  },
  setFilterData (state, data) {
    state.filters = data
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
