import Vue from 'vue'
import store from '@/store'
import router from '@/router/index'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

// for multiple parallel requests
let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

export const $http = axios.create({
  baseURL: '',
  // baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('user_token')}`,
    'Api-Version': 'v1',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

$http.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('user_token')}`
  config.headers['Accept-Locale'] = store.getters.currentLanguage.translationKey
  config.headers['Country-Id'] = 242
  config.headers['Session-Id'] = localStorage.getItem('session-id') || ''
  return config
})
$http.interceptors.response.use((response) => {
  if (response.headers.hasOwnProperty('session-id')) {
    localStorage.setItem('session-id', response.headers['session-id'])
  }
  return response
}, (error) => {
  console.log('interceptors.request.use error', error)
  const originalRequest = error.config
  switch (error.response.status) {
    case 500: {
      store.commit('system/CHANGE_ERROR', {
        message: '500 server error',
        show: true
      })
      // router.push({ name: 'server-not-found' })
      break
    }
    case 404: {
      store.commit('system/CHANGE_ERROR', {
        message: '404 page not found',
        show: true
      })
      // router.push({ name: 'page-not-found' })
      break
    }
    case 403: {
      store.commit('system/CHANGE_ERROR', {
        message: `${error.response.data.error.message_key}`,
        show: true,
        code: error.response.status
      })
      break
    }
    case 422: {
      const errors = error.response.data.validation
      Object.values(errors).forEach(e => {
        e.forEach(t => {
          store.commit('system/CHANGE_ERROR', {
            message: `${t}`,
            show: true,
            code: error.response.status
          })
        })
      })
      break
    }
    case 423: {
      store.commit('system/CHANGE_ERROR', {
        message: `${error.response.data.error.message_key}`,
        show: true,
        code: error.response.status
      })
      break
    }
    default: {
      break
    }
  }
  if (error.response.status === 401 && router.app._route.name !== 'sing-in' && !originalRequest.retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({
          resolve,
          reject
        })
      }).then((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`
        return Vue.axios(originalRequest)
      }).catch(error => error)
    }

    originalRequest.retry = true
    isRefreshing = true

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_ROOT_AUTH}tokens/refresh`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user_refresh')}`,
          'Api-Version': 'v1',
          Accept: 'application/json',
          'Country-Id': '242',
          'Session-Id': localStorage.getItem('session-id') || '',
          'Accept-Language': store.getters.currentLanguage.translationKey
        }
      })
        .then(response => {
          localStorage.setItem('user_token', response.data.data[0].token)
          localStorage.setItem('user_refresh', response.data.data[1].token)
          originalRequest.headers.Authorization = `Bearer ${response.data.data[0].token}`
          axios.defaults.headers.Authorization = `Bearer ${response.data.data[0].token}`
          processQueue(null, response.data.data[0].token)
          resolve(Vue.axios(originalRequest))
        })
        .catch((error) => {
          try {
            store.dispatch('auth/AUTH_LOGOUT', {}, {root: true})
            router.push({ name: 'sing-in' })
            processQueue(error, null)
            reject(error)
          } catch (e) {
            console.log(e);
            throw e;
          }
        })
        .then(() => {
          isRefreshing = false
        })
    })
  }
  throw error
})

export default function install (Vue) {
  Object.defineProperty(Vue.prototype, '$http', {
    get () {
      return $http
    }
  })
}
