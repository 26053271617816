export default [
  {
    path: 'events/list',
    name: 'events.list',
    component: () => import('@/modules/events/pages/main/index.vue'),
    meta: {}
  },
  {
    path: 'events/',
    name: 'events.view',
    component: () => import('@/modules/events/pages/view/index.vue'),
    meta: {},
    children: [
      {
        path: 'create',
        name: 'events.create',
        component: () => import('@/modules/events/pages/general/index.vue'),
        meta: {}
      },
      {
        path: ':id/general',
        name: 'events.general',
        component: () => import('@/modules/events/pages/general/index.vue'),
        meta: {}
      },
      {
        path: ':id/seo',
        name: 'events.seo',
        component: () => import('@/modules/events/pages/seo/index.vue'),
        meta: {}
      },
      {
        path: ':id/content',
        name: 'events.content',
        component: () => import('@/modules/events/pages/content/index.vue'),
        meta: {}
      }
    ]
  }
]
