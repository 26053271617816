import Vue from 'vue'
import { $http } from '@/utils/https'

import {
    CERTIFICATES_CHANGE_DIALOG_VIEW,
    CERTIFICATES_CHANGE_LIST_LOADING,
    CERTIFICATES_CHANGE_SIMPLE_LIST_LOADING,
    CERTIFICATES_CHANGE_LOADING,
    CERTIFICATES_CHANGE_SKIP,
    CERTIFICATES_REMOVE_LIST_ITEM,
    CERTIFICATES_SET_ITEM,
    CERTIFICATES_SET_ITEM_EN,
    CERTIFICATES_SET_LIST,
    CERTIFICATES_SET_SIMPLE_LIST,
    CERTIFICATES_SET_LIST_LENGTH
} from './types/mutation-types'

import { 
    CERTIFICATES_GET_LIST,
    CERTIFICATES_GET_SIMPLE_LIST,
    CERTIFICATES_CREATE, 
    CERTIFICATES_UPDATE,
    CERTIFICATES_DELETE
} from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
    list                : [],
    listSimple          : [],
    isLoading           : false,
    isListLoading       : false,
    isListSimpleLoading : false,
    currentItem         : null,
    currentItemEn       : null,
    limit               : 50,
    skip                : 0,
    listLength          : 0,
    openDialogView      : false
}

const getters = {
    list                : state => camelcaseObjectDeep(state.list),
    listSimple          : state => camelcaseObjectDeep(state.listSimple),
    isLoading           : state => state.isLoading,
    currentItem         : state => camelcaseObjectDeep(state.currentItem),
    currentItemEn       : state => camelcaseObjectDeep(state.currentItemEn),
    isListLoading       : state => state.isListLoading,
    isListSimpleLoading : state => state.isListSimpleLoading,
    listLength          : state => state.listLength,
    limit               : state => state.limit,
    skip                : state => state.skip,
    openDialogView      : state => state.openDialogView
}

const actions = {
    [CERTIFICATES_GET_LIST]         : async ({ commit }, payload) => {
        try {
            commit(CERTIFICATES_CHANGE_LIST_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/certificates?include=packages,status,course,template,templateEn`)
            
            commit(CERTIFICATES_SET_LIST        , response.data.data)
            commit(CERTIFICATES_SET_LIST_LENGTH , response.data.meta.total)
        } finally {
            commit(CERTIFICATES_CHANGE_LIST_LOADING, false)
        }
    },
    [CERTIFICATES_GET_SIMPLE_LIST]  : async ({ commit }, payload) => {
        try {
            commit(CERTIFICATES_CHANGE_SIMPLE_LIST_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}certificates/list`, {
                params: {
                    include             : '',
                    'filters[course_id]': payload.courseId || '',
                    limit               : 100,
                    skip                : 0
                }
            });
            
            commit(CERTIFICATES_SET_SIMPLE_LIST, response.data.data)
        } finally {
            commit(CERTIFICATES_CHANGE_SIMPLE_LIST_LOADING, false)
        }
    },
    [CERTIFICATES_UPDATE]           : async ({ dispatch, commit }, payload) => {
        try {
            commit(CERTIFICATES_CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/certificates/${payload.id}?include=packages,status,type,course,template`, payload)
            
            commit(CERTIFICATES_CHANGE_DIALOG_VIEW, false)
            
            dispatch(CERTIFICATES_GET_LIST, { courseId: response.data.data.course_id })
        } finally {
            commit(CERTIFICATES_CHANGE_LOADING, false)
        }
    },
    [CERTIFICATES_CREATE]           : async ({ dispatch, commit }, payload) => {
        try {
            commit(CERTIFICATES_CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/certificates?include=packages,status,type,course,template`, payload)
            
            commit(CERTIFICATES_CHANGE_DIALOG_VIEW, false)
            
            dispatch(CERTIFICATES_GET_LIST, { courseId: response.data.data.course_id })
        } finally {
            commit(CERTIFICATES_CHANGE_LOADING, false)
        }
    },
    [CERTIFICATES_DELETE]           : async ({ commit }, payload) => {
        try {
            commit(CERTIFICATES_CHANGE_LOADING, true)
            
            await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/certificates/${payload.id}`)
            
            commit(CERTIFICATES_REMOVE_LIST_ITEM, payload)
        } finally {
            commit(CERTIFICATES_CHANGE_LOADING, false)
        }
    }
}

const mutations = {
    [CERTIFICATES_SET_LIST] (state, list) {
        state.list = list
    },
    [CERTIFICATES_SET_SIMPLE_LIST](state, list) {
        state.listSimple = list
    },
    [CERTIFICATES_SET_ITEM] (state, data) {
        state.currentItem = data
    },
    [CERTIFICATES_SET_ITEM_EN] (state, data) {
        state.currentItemEn = data
    },
    [CERTIFICATES_CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
    [CERTIFICATES_CHANGE_LIST_LOADING] (state, status) {
        state.isListLoading = status
    },
    [CERTIFICATES_CHANGE_SIMPLE_LIST_LOADING](state, status) {
        state.isListSimpleLoading = status
    },
    [CERTIFICATES_SET_LIST_LENGTH] (state, length) {
        state.listLength = length
    },
    [CERTIFICATES_CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [CERTIFICATES_CHANGE_DIALOG_VIEW] (state, status) {
        state.openDialogView = status
    },
    [CERTIFICATES_REMOVE_LIST_ITEM] (state, params) {
        const index = state.list.findIndex(e => e.id === params.id)
        
        Vue.delete(state.list, index)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
