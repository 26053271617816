import Vue from 'vue'
import { $http } from '@/utils/https'

import {
    CHANGE_FILTER,
    CHANGE_LOADING,
    CHANGE_SKIP,
    EDUCATION_LIST_LOADING,
    FRESH_LIST,
    SET_CURRENT_EDUCATION,
    SET_ITEM,
    SET_LIST,
    SET_LIST_EDUCATION_LENGTH,
    SET_LIST_LENGTH,
    SET_LIST_LOADING
} from './types/mutation-types'

import {
    CHECK_EDUCATION_LIST_ITEM, COPY,
    CREATE,
    DELETE, FRESH_IMAGE,
    GET_CURRENT_EDUCATION,
    GET_LIST,
    GET_LIST_ITEM,
    UPDATE, UPDATE_IMAGE, UPDATE_IMAGE_LOADING
} from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
    list                : [],
    isListLoading       : false,
    isLoading           : false,
    currentItem         : null,
    
    filterCountries     : '',
    filterStatus        : '',
    filterKeywords      : '',
    
    listLength          : 0,
    limit               : 5, // 2000
    skip                : 0,
    
    educationList       : [],
    educationListLoading: [],
    educationListLength : 0,
    
    updateImageLoading  : false,
}

const getters = {
    list                : state => camelcaseObjectDeep(state.list),
    isListLoading       : state => state.isListLoading,
    isLoading           : state => state.isLoading,
    filterCountries     : state => state.filterCountries,
    filterStatus        : state => state.filterStatus,
    filterKeywords      : state => state.filterKeywords,
    listLength          : state => state.listLength,
    currentItem         : state => camelcaseObjectDeep(state.currentItem),
    limit               : state => state.limit,
    skip                : state => state.skip,
    educationList       : state => state.educationList,
    educationListLength : state => state.educationListLength,
    educationListLoading: state => state.educationListLoading,
    updateImageLoading  : state => state.updateImageLoading
}

const actions = {
    [UPDATE_IMAGE]              : async ({ commit }, payload) => {
        try {
            commit(UPDATE_IMAGE_LOADING, true)
            
            let response = '';
            
            if (payload.get('id')) {
                response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs/${payload.get('tariff_id')}/images/${payload.get('id')}`, payload)
                response.data.data.isEdit = true
            } else {
                response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs/${payload.get('tariff_id')}/images`, payload)
                response.data.data.isEdit = false
            }
            
            response.data.data.isEdit = true
            
            commit(FRESH_IMAGE, response.data.data)
        } catch (e) {
            throw e
        } finally {
            commit(UPDATE_IMAGE_LOADING, false)
        }
    },
    [COPY]                      : async ({ commit }, payload) => {
        const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs/copy/${payload.id}`);
    },
    [GET_LIST]                  : async ({ commit }, payload) => {
        let actionPlanCountryId = '';
        
        if (payload && payload.hasOwnProperty('countryIds')) {
            actionPlanCountryId = payload.countryIds
        } else {
            actionPlanCountryId = ''
        }
        
        try {
            commit(SET_LIST_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs?include=country,currency,type,status&filters[countries]=${actionPlanCountryId || state.filterCountries || ''}&filters[status]=${state.filterStatus || ''}&filters[keywords]=${state.filterKeywords || ''}&limit=${state.limit}&skip=${state.skip}`)
            
            commit(SET_LIST         , response.data.data)
            commit(SET_LIST_LENGTH  , response.data.meta.total)
            
            return response
        } catch (e) {
            throw e
        } finally {
            commit(SET_LIST_LOADING, false)
        }
    },
    [GET_CURRENT_EDUCATION]     : async ({ commit }, payload) => {
        try {
            commit(EDUCATION_LIST_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}${payload.slug}?include=tariffs&limit=${payload.limit}&skip=${payload.skip}&filters[countries]=${payload.countryId}`)
            
            commit(SET_CURRENT_EDUCATION, response.data.data)
            commit(SET_LIST_EDUCATION_LENGTH, response.data.meta.total)
            
            return response
        } catch (e) {
            throw e
        } finally {
            commit(EDUCATION_LIST_LOADING, false)
        }
    },
    [CHECK_EDUCATION_LIST_ITEM] : async ({ commit }, payload) => {
        try {
            return await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs/${payload.tariffId}/${payload.slug}/${payload.id}/${payload.isChecked ? 'exclude' : 'include'}`, { _method: 'patch' })
        } catch (e) {
            throw e
        }
    },
    [GET_LIST_ITEM]             : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs/${payload.id}?include=country,currency,type,status,accessible&filters[countries]=${state.filterCountries || ''}&filter[status]=${state.filterStatus || ''}&filter[keywords]=${state.filterKeywords || ''}&limit=${state.limit}&skip=${state.skip}`)
            
            commit(SET_ITEM, response.data.data)
            
            return response
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [DELETE]                    : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.delete(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs/${payload.id}`)
            
            commit(FRESH_LIST, payload)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [UPDATE]                    : async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs/${state.currentItem.id}?include=country,currency,type,status,accessible`, payload)
            
            commit(SET_ITEM, response.data.data)
            
            dispatch(GET_LIST)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [CREATE]                    : async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tariffs?include=country,currency,type,status,accessible`, payload)
            
            commit(SET_ITEM, response.data.data)
            
            dispatch(GET_LIST_ITEM, response.data.data)
            dispatch(GET_LIST)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    }
}

const mutations = {
    [UPDATE_IMAGE_LOADING] (state, status) {
        state.updateImageLoading = status
    },
    [FRESH_IMAGE] (state, data) {
        const index = state.list.findIndex(e => e.id === parseInt(data.id))
        
        if (data.isEdit) {
            state.currentItem.picture.data = data;
        } else {
            state.list[index].picture.data = data
        }
    },
    [SET_LIST] (state, data) {
        state.list = data
    },
    [SET_LIST_LOADING] (state, status) {
        state.isListLoading = status
    },
    [FRESH_LIST] (state, params) {
        const index = state.list.findIndex(e => e.id === params.id)
        
        Vue.delete(state.list, index)
    },
    [SET_LIST_LENGTH] (state, length) {
        state.listLength = length
    },
    [CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
    [SET_ITEM] (state, data) {
        state.currentItem = data
    },
    [CHANGE_FILTER] (state, params) {
        switch (params.type) {
            case 'country': {
                state.filterCountries = params.value
                break
            }
            case 'status': {
                state.filterStatus = params.value
                break
            }
            default: {
                state.filterKeywords = params.value
            }
        }
    },
    [CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [SET_LIST_EDUCATION_LENGTH] (state, count) {
        state.educationListLength = count
    },
    [EDUCATION_LIST_LOADING] (state, status) {
        state.educationListLoading = status
    },
    [SET_CURRENT_EDUCATION] (state, list) {
        if (list) {
            state.educationList.push(...list)
        } else {
            state.educationList = []
            state.educationListLength = 0
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
