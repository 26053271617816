import Vue from 'vue'
import { $http } from '@/utils/https'
import { transformerSeo } from '@/transformers'

import {
  SEO_GET_DATA_LOADING,
  SEO_SET_DATA,
  SEO_UPDATE_DATA_LOADING,
  SEO_SET_NEW_ITEM,
  SEO_UPDATE_ITEM
} from './types/mutation-types'

import {
  SEO_GET_DATA,
  UPDATE,
  CREATE
} from './types/action-types'

const state = {
  seo: null,
  seoLoading: false,
  seoUpdateLoading: false
}

const getters = {
  seo: state => transformerSeo(state.seo),
  seoLoading: state => state.seoLoading,
  seoUpdateLoading: state => state.seoUpdateLoading
}

const actions = {
  [SEO_GET_DATA]: async ({ commit }, payload) => {
    try {
      commit(SEO_GET_DATA_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_EVENT}events/${payload.id}?include=seo`)
      commit(SEO_SET_DATA, response.data.data.seo.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(SEO_GET_DATA_LOADING, false)
    }
  },
  [UPDATE]: async ({ commit }, payload) => {
    try {
      commit(SEO_UPDATE_DATA_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EVENT}seo/${payload.get('id')}`, payload)
      commit(SEO_UPDATE_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SEO_UPDATE_DATA_LOADING, false)
    }
  },
  [CREATE]: async ({ commit }, payload) => {
    try {
      commit(SEO_UPDATE_DATA_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EVENT}seo`, payload)
      commit(SEO_SET_NEW_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SEO_UPDATE_DATA_LOADING, false)
    }
  }
}

const mutations = {
  [SEO_SET_DATA] (state, data) {
    state.seo = data
  },
  [SEO_SET_NEW_ITEM] (state, data) {
    state.seo.seo.data.push(data)
  },
  [SEO_UPDATE_ITEM] (state, data) {
    const index = state.seo.seo.data.findIndex(e => e.locale === data.locale)
    Vue.set(state.seo.seo.data, index, data)
  },
  [SEO_GET_DATA_LOADING] (state, status) {
    state.seoLoading = status
  },
  [SEO_UPDATE_DATA_LOADING] (state, status) {
    state.seoUpdateLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
