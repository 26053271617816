export const UPDATE = 'UPDATE'
export const CREATE = 'CREATE'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'

// main
export const EVENTS_FETCH_LIST = 'EVENTS_FETCH_LIST'
export const EVENTS_GET_CURRENT = 'EVENTS_GET_CURRENT'
export const EVENTS_LIST_DELETE_ITEM = 'EVENTS_LIST_DELETE_ITEM'

// general
export const GENERAL_GET_DATA = 'GENERAL_GET_DATA'

// content
export const CONTENT_GET_DATA = 'CONTENT_GET_DATA'

// seo
export const SEO_GET_DATA = 'SEO_GET_DATA'
