export const SET_LIST = 'SET_LIST'
export const CHANGE_LIST_LOADING = 'CHANGE_LIST_LOADING'
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH'
export const CHANGE_LIST_FILTER = 'CHANGE_LIST_FILTER'
export const CHANGE_SKIP = 'CHANGE_SKIP'
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const CHANGE_SHOW_DIALOG = 'CHANGE_SHOW_DIALOG'
export const SET_ITEM = 'SET_ITEM'
export const DELETE_LIST_ITEM = 'DELETE_LIST_ITEM'
export const ADD_LIST_ITEM = 'ADD_LIST_ITEM'
export const SET_UPDATED_LIST_ITEM = 'SET_UPDATED_LIST_ITEM'
export const REMOVE_LIST_ITEM = 'REMOVE_LIST_ITEM'
export const UPDATE_IMAGE_LOADING = 'UPDATE_IMAGE_LOADING'
export const FRESH_IMAGE = 'FRESH_IMAGE'
export const CHANGE_EDIT_ITEM = 'CHANGE_EDIT_ITEM'
export const REPLACE_LIST_ITEM = 'REPLACE_LIST_ITEM'

export const CHANGE_FILTER = 'CHANGE_FILTER'
export const CHANGE_EDIT_ID = 'CHANGE_EDIT_ID'
export const RESET_FILTER = 'RESET_FILTER'

export const ADD_SEO_ITEM = 'ADD_SEO_ITEM'
export const FRESH_SEO_ITEM = 'FRESH_SEO_ITEM'
export const SEO_LOADING = 'SEO_LOADING'
export const SET_SEO = 'SET_SEO'
export const UPDATE_SEO_LOADING = 'UPDATE_SEO_LOADING'
