import { $http } from '@/utils/https'
import Vue from 'vue'

import {
  PACKAGES_DELETE_ITEM,
  PACKAGES_GET_LIST_LOADING,
  PACKAGES_GET_SIMPLE_LIST_LOADING,
  PACKAGES_SET_CREATED,
  PACKAGES_SET_LIST,
  PACKAGES_SET_SIMPLE_LIST,
  PACKAGES_SET_UPDATE,
  PACKAGES_UPDATE_ITEM_LOADING,
  PACKAGES_CHANGE_SKIP,
  PACKAGES_CHANGE_LIST_LENGTH,
  PACKAGES_REQUEST_LOADING
} from './types/mutation-types'

import {
  PACKAGES_CREATE,
  PACKAGES_DELETE,
  PACKAGES_GET_LIST,
  PACKAGES_GET_SIMPLE_LIST,
  PACKAGES_UPDATE,
  PACKAGES_UPDATE_SORT
} from './types/action-types'

const state = {
  list: [],
  listSimple: [],
  listLoading: false,
  listSimpleLoading: false,
  listLength: 0,
  requestLoading: false,
}

const getters = {
  list: state => state.list.map((e, index) => {
    return {
      ...e,
      index: state.skip + index + 1,
    }
  }),
  listSimple: state => state.listSimple,
  listLoading: state => state.listLoading,
  listSimpleLoading: state => state.listSimpleLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  skip: state => state.skip,
  requestLoading: state => state.requestLoading,
}

const actions = {
  [PACKAGES_GET_LIST]: async ({ commit  }, payload) => {
    try {
      commit(PACKAGES_GET_LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.id}/packages?include=status`);

      commit(PACKAGES_SET_LIST, response.data.data)
      commit(PACKAGES_CHANGE_LIST_LENGTH, response.data.meta.total)
    } finally {
      commit(PACKAGES_GET_LIST_LOADING, false)
    }
  },
  [PACKAGES_GET_SIMPLE_LIST]: async ({ commit }, payload) => {
    try {
      commit(PACKAGES_GET_SIMPLE_LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}packages/list`, {
        params: {
          include: '',
          'filters[course_id]': payload.courseId || '',
          limit: 100,
          skip: 0
        }
      })

      commit(PACKAGES_SET_SIMPLE_LIST, response.data.data)
    } finally {
      commit(PACKAGES_GET_SIMPLE_LIST_LOADING, false)
    }
  },
  [PACKAGES_CREATE]: async ({ commit }, payload) => {
    try {
      commit(PACKAGES_REQUEST_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/packages?include=status`, payload)
      commit(PACKAGES_SET_CREATED, response.data.data)
    } finally {
      commit(PACKAGES_REQUEST_LOADING, false)
    }
  },
  [PACKAGES_UPDATE]: async ({ commit }, payload) => {
    try {
      commit(PACKAGES_REQUEST_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/packages/${payload.id}?include=status`, payload)
      commit(PACKAGES_SET_UPDATE, response.data.data)
    } finally {
      commit(PACKAGES_REQUEST_LOADING, false)
    }
  },
  [PACKAGES_UPDATE_SORT]: async ({ commit }, payload) => {
    try {
      commit(PACKAGES_GET_LIST_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/packages/sort?include=lessons,packages,status`, payload)
      commit(PACKAGES_SET_LIST, response.data.data)
    } finally {
      commit(PACKAGES_GET_LIST_LOADING, false)
    }
  },
  [PACKAGES_DELETE]: async ({ commit }, payload) => {
    try {
      commit(PACKAGES_REQUEST_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/packages/${payload.id}`)
      commit(PACKAGES_DELETE_ITEM, payload)
    } finally {
      commit(PACKAGES_REQUEST_LOADING, false)
    }
  }
}
const mutations = {
  [PACKAGES_SET_LIST] (state, list) {
    state.list = list
  },
  [PACKAGES_SET_SIMPLE_LIST](state, list) {
    state.listSimple = list
  },
  [PACKAGES_GET_LIST_LOADING] (state, status) {
    state.listLoading = status
  },
  [PACKAGES_GET_SIMPLE_LIST_LOADING](state, status) {
    state.listSimpleLoading = status
  },
  [PACKAGES_SET_CREATED] (state, item) {
    state.list.push(item)
  },
  [PACKAGES_SET_UPDATE] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.set(state.list, index, item)
  },
  [PACKAGES_UPDATE_ITEM_LOADING] (state, item) {
    Vue.set(state.list, item.index - 1, item)
  },
  [PACKAGES_DELETE_ITEM] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.delete(state.list, index)
  },
  [PACKAGES_REQUEST_LOADING] (state, status) {
    state.requestLoading = status
  },
  [PACKAGES_CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [PACKAGES_CHANGE_LIST_LENGTH] (state, count) {
    state.listLength = count
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
