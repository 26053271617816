import {GET_FEEDBACK, SAVE_FEEDBACK} from "@/store/modules/feedbacks/types/action-types";
import {
  GET_FEEDBACK_LOADING,
  SET_FEEDBACK,
} from "@/store/modules/feedbacks/types/mutation-types";
import {$http} from "@/utils/https";

const state = {
  item: {},
  itemLoading: false
}

const getters = {
  item: (state) => state.item,
  itemLoading: (state) => state.itemLoading
}

const actions = {
  [GET_FEEDBACK]: async ({ commit }, payload) => {
    try {
      commit(GET_FEEDBACK_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}forms-feedback/${payload.id}`, {})
      commit(SET_FEEDBACK, response.data.data)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(GET_FEEDBACK_LOADING, false)
    }
  },
  [SAVE_FEEDBACK]: async ({ commit }, payload) => {
    try {
      commit(GET_FEEDBACK_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}forms-feedback/${payload.id}`, payload)
      commit(SET_FEEDBACK, response.data.data)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(GET_FEEDBACK_LOADING, false)
    }
  },
}

const mutations = {
  [SET_FEEDBACK] (state, item) {
    state.item = item
    state.course = item.course
    state.package = item.package
  },
  [GET_FEEDBACK_LOADING] (state, itemLoading) {
    state.itemLoading = itemLoading
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
