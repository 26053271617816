// list
export const SET_FEEDBACKS = 'SET_FEEDBACKS';
export const SET_FEEDBACKS_LENGTH = 'SET_FEEDBACKS_LENGTH';
export const GET_FEEDBACKS_LOADING = 'GET_FEEDBACKS_LOADING'
export const FEEDBACK_LIST_CHANGE_SKIP = 'FEEDBACK_LIST_CHANGE_SKIP'
export const FEEDBACK_CHANGE_LOCK_STEP = 'FEEDBACK_CHANGE_LOCK_STEP'
export const FEEDBACK_FILTER_CHANGE = 'FEEDBACK_FILTER_CHANGE'
export const GET_DELETE_FEEDBACK_LOADING = 'GET_DELETE_FEEDBACK_LOADING'

//edit
export const GET_FEEDBACK_LOADING = 'GET_FEEDBACK_LOADING'
export const SET_FEEDBACK = 'SET_FEEDBACK'

