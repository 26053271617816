import Vue from 'vue'
import { $http } from '@/utils/https'

import {
    ADD_LIST_ITEM,
    ADD_TRANSLATION_ITEM,
    CHANGE_DIALOG_VIEW,
    CHANGE_EDIT_ID,
    CHANGE_LIST_FILTER,
    CHANGE_LIST_LOADING,
    CHANGE_LIST_EN_LOADING,
    CHANGE_LOADING,
    CHANGE_SKIP,
    FRESH_TRANSLATION_ITEM,
    REMOVE_LIST_ITEM,
    REPLACE_LIST_ITEM,
    SET_ITEM,
    SET_LIST,
    SET_LIST_EN,
    SET_LIST_LENGTH,
    SET_LIST_EN_LENGTH,
    UPDATE_TRANSLATION_LOADING
} from './types/mutation-types'

import {
    CREATE,
    DELETE,
    GET_LIST,
    GET_LIST_EN,
    GET_LIST_ITEM,
    UPDATE,
    UPDATE_TRANSLATION,
    GET_TEMPLATE_LIST
} from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
    list                : [],
    listEn              : [],
    isLoading           : false,
    isEnLoading         : false,
    isListLoading       : false,
    isListEnLoading     : false,
    currentItem         : null,
    currentItemEn       : null,
    limit               : 10,
    skip                : 0,
    listLength          : 0,
    listEnLength        : 0,
    openDialogView      : false,
    editId              : '',
    isTranslationLoading: false,
    templateTypes       : [],
    filterCountries     : '',
    filterTypeId        : '',
    filterKeywords      : '',
    filterKeywordsEn    : ''
}

const getters = {
    list                : state => camelcaseObjectDeep(state.list),
    listEn              : state => camelcaseObjectDeep(state.listEn),
    isLoading           : state => state.isLoading,
    isEnLoading         : state => state.isEnLoading,
    currentItem         : state => camelcaseObjectDeep(state.currentItem),
    currentItemEn       : state => camelcaseObjectDeep(state.currentItemEn),
    isListLoading       : state => state.isListLoading,
    isListEnLoading     : state => state.isListEnLoading,
    listLength          : state => state.listLength,
    listEnLength        : state => state.listEnLength,
    limit               : state => state.limit,
    skip                : state => state.skip,
    openDialogView      : state => state.openDialogView,
    editId              : state => state.editId,
    isTranslationLoading: state => state.isTranslationLoading,
    filterCountries     : state => state.filterCountries,
    filterKeywords      : state => state.filterKeywords,
    filterKeywordsEn    : state => state.filterKeywordsEn,
    layoutsList         : state => state.layoutsList,
    isLayoutsListLoading: state => state.isLayoutsListLoading,
    templateTypes       : state => state.templateTypes
}

const actions = {
    [GET_LIST]              : async ({ commit }, filter) => {
        //console.log("GET_LIST");
        
        try {
            commit(CHANGE_LIST_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_TEMPLATE}templates/list?include=country,status,translations,layout&filters[countries]=${state.filterCountries || ''}&filters[keywords]=${state.filterKeywords || ''}&filters[type]=${filter ? filter.type : ''}&filters[type_id]=${state.filterTypeId || ''}&limit=${state.limit}&skip=${state.skip}`)
            
            //console.log("SET_LIST1:");
            //console.log(response.data.data);
            //console.log("");
            
            commit(SET_LIST         , response.data.data)
            commit(SET_LIST_LENGTH  , response.data.meta.total)
        } catch (e) {
            //throw e
            
            console.log("GET_LIST templates error:");
            console.log(e);
            console.log("");
        } finally {
            commit(CHANGE_LIST_LOADING, false)
        }
    },
    [GET_LIST_EN]           : async ({ commit }, filter) => {
        //console.log("GET_LIST");
        
        try {
            commit(CHANGE_LIST_EN_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_TEMPLATE}templates/list?include=country,status,translations,layout&filters[countries]=${state.filterCountries || ''}&filters[keywords]=${state.filterKeywordsEn || ''}&filters[type]=${filter ? filter.type : ''}&filters[type_id]=${state.filterTypeId || ''}&limit=${state.limit}&skip=${state.skip}`)
            
            //console.log("SET_LIST1:");
            //console.log(response.data.data);
            //console.log("");
            
            commit(SET_LIST_EN         , response.data.data)
            commit(SET_LIST_EN_LENGTH  , response.data.meta.total)
        } catch (e) {
            //throw e
            
            console.log("GET_LIST EN templates error:");
            console.log(e);
            console.log("");
        } finally {
            commit(CHANGE_LIST_EN_LOADING, false)
        }
    },
    GET_TEMPLATES_TYPES     : async ({ commit }) => {
        try {
            commit(CHANGE_LIST_LOADING, true);
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_TEMPLATE}types/list?skip=0&limit=15`)
            
            commit('SET_TEMPLATE_TYPES', response.data.data);
            commit(SET_LIST_LENGTH, response.data.meta.total)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LIST_LOADING, false)
        }
    },
    [GET_LIST_ITEM]         : async ({ commit }) => {
        console.log("GET_LIST_ITEM");
        
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_TEMPLATE}templates/${state.editId}?include=translations,country,status`)
            
            commit(SET_ITEM, response.data.data)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [UPDATE]                : async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_TEMPLATE}templates/${state.currentItem.id}?include=translations,country,status`, payload)
            
            commit(REPLACE_LIST_ITEM, response.data.data)
            commit(SET_ITEM, response.data.data)
            
            dispatch(GET_LIST)
            
            return response.data.data
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [CREATE]                : async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_TEMPLATE}templates?include=translations,country,status`, payload)
            
            commit(ADD_LIST_ITEM, response.data.data)
            commit(SET_ITEM, response.data.data)
            
            dispatch(GET_LIST)
            
            return response.data.data
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [DELETE]                : async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const response = await $http.delete(`${process.env.VUE_APP_ROOT_TEMPLATE}templates/${payload.id}`)
            
            commit(REMOVE_LIST_ITEM, payload)
            
            return response.data.data
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [UPDATE_TRANSLATION]    : async ({ commit }, payload) => {
        try {
            commit(UPDATE_TRANSLATION_LOADING, true)
            
            if (payload.get('_method')) {
                const response = await $http.post(`${process.env.VUE_APP_ROOT_TEMPLATE}templates/${state.currentItem.id}/translations/${payload.get('id')}`, payload)
                
                commit(FRESH_TRANSLATION_ITEM, response.data.data)
            } else {
                const response = await $http.post(`${process.env.VUE_APP_ROOT_TEMPLATE}templates/${state.currentItem.id}/translations`, payload)
                
                commit(ADD_TRANSLATION_ITEM, response.data.data)
            }
        } catch (e) {
            throw e
        } finally {
            commit(UPDATE_TRANSLATION_LOADING, false)
        }
    },
    [GET_TEMPLATE_LIST]     : async ({ commit }, filter) => {
        //console.log("GET_TEMPLATE_LIST");
        
        try {
            commit(CHANGE_LIST_LOADING, true)
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_TEMPLATE}templates?include=country,status,translations,layout&filters[countries]=${state.filterCountries || ''}&filters[keywords]=${state.filterKeywords || ''}&filters[type]=${filter ? filter.type : ''}&filters[type_id]=${state.filterTypeId || ''}&limit=${state.limit}&skip=${state.skip}`)
            
            //console.log("SET_LIST2:");
            //console.log(response.data.data);
            //console.log("");
            
            commit(SET_LIST         , response.data.data)
            commit(SET_LIST_LENGTH  , response.data.meta.total)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LIST_LOADING, false)
        }
    }
}

const mutations = {
    [SET_LIST] (state, list) {
        state.list = list
    },
    [SET_LIST_EN] (state, list) {
        state.listEn = list
    },
    [SET_LIST_LENGTH] (state, length) {
        state.listLength = length
    },
    [SET_LIST_EN_LENGTH] (state, length) {
        state.listEnLength = length
    },
    SET_TEMPLATE_TYPES (state, list){
        state.templateTypes = list;
    },
    [SET_ITEM] (state, data) {
        state.currentItem = data
    },
    [REPLACE_LIST_ITEM] (state, data) {
        const index = state.list.findIndex(e => e.id === data.id)
        
        Vue.set(state.list, index, data)
    },
    [ADD_LIST_ITEM] (state, data) {
        state.list.unshift(data)
    },
    [CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
    [CHANGE_LIST_FILTER] (state, params) {
        switch (params.type) {
            case 'countries': {
                state.filterCountries = params.value;
                
                break;
            }
            case 'type_id': {
                state.filterTypeId = params.value;
                
                break;
            }
            case 'keywords': {
                state.filterKeywords = params.value;
                
                break;
            }
            case 'keywords_en': {
                state.filterKeywordsEn = params.value;
                
                break;
            }
            default: {
                state.filterKeywords = params.value;
            }
        }
    },
    [CHANGE_LIST_LOADING] (state, status) {
        state.isListLoading = status
    },
    [CHANGE_LIST_EN_LOADING] (state, status) {
        state.isListEnLoading = status
    },
    [CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [CHANGE_DIALOG_VIEW] (state, status) {
        state.openDialogView = status
    },
    [CHANGE_EDIT_ID] (state, id) {
    state.editId = id
    },
    [ADD_TRANSLATION_ITEM] (state, content) {
        state.currentItem.translations.data.push(content)
    },
    [FRESH_TRANSLATION_ITEM] (state, data) {
        const index = state.currentItem.translations.data.findIndex(e => e.id === data.id)
        
        Vue.set(state.currentItem.translations.data, index, data)
    },
    [REMOVE_LIST_ITEM] (state, data) {
        const index = state.list.findIndex(e => e.id === data.id)
        
        Vue.delete(state.list, index)
    },
    [UPDATE_TRANSLATION_LOADING] (state, status) {
        state.isTranslationLoading = status
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
