import { $http } from '@/utils/https'
import Vue from 'vue'

import { CREATE, DELETE, GET_ITEM, GET_LIST, LESSON_UPDATE_IMAGE, UPDATE, UPDATE_SORT } from './types/action-types'
import {
  CHANGE_EDIT_ID,
  DELETE_LIST_ITEM,
  FRESH_LOADING,
  ITEM_LOADING,
  LESSON_FRESH_IMAGE,
  LESSON_UPDATE_IMAGE_LOADING,
  LIST_LOADING,
  SET_ITEM,
  SET_LIST,
  SET_NEW_ITEM,
  UPDATE_LIST_ITEM
} from './types/mutation-types'

const state = {
  listLoading: false,
  list: null,
  lessonLoading: false,
  updateImageLoading: false,
  currentItem: null,
  loading: false,

  editId: ''
}

const getters = {
  list: state => state.list || [],
  listLoading: state => state.listLoading,
  lessonLoading: state => state.lessonLoading,
  updateImageLoading: state => state.updateImageLoading,

  editId: state => state.editId,
  currentItem: state => state.currentItem,
  loading: state => state.loading
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/materials?include=packages,status,image,image.seo`)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(LIST_LOADING, false)
    }
  },
  [GET_ITEM]: async ({ commit }, payload) => {
    try {
      commit(ITEM_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/lessons/${payload.id}?include=packages,status,speakers,image,image.seo,module`)
      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(ITEM_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/lessons/${payload.id}`)
      commit(DELETE_LIST_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/lessons?include=packages,status`, payload)
      commit(SET_NEW_ITEM, response.data.data)
      commit(CHANGE_EDIT_ID, response.data.data.id)
      dispatch(GET_ITEM, { ...response.data.data, ...payload })
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [UPDATE]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/lessons/${payload.id}?include=packages,status`, payload)
      commit(UPDATE_LIST_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [UPDATE_SORT]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/modules/${payload.module_id}/materials/sort?include=packages,status,image,image.seo`, payload)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [LESSON_UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(LESSON_UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/modules/${payload.get('module_id')}/lessons/${payload.get('lesson_id')}/images/${payload.get('id')}?include=seo`, payload)
        response.data.data.isEdit = true
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/modules/${payload.get('module_id')}/lessons/${payload.get('lesson_id')}/images?include=seo`, payload)
        response.data.data.isEdit = false
      }
      response.data.data.lessonId = payload.get('lesson_id')
      commit(LESSON_FRESH_IMAGE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(LESSON_UPDATE_IMAGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [LIST_LOADING] (state, status) {
    state.listLoading = status
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [ITEM_LOADING] (state, status) {
    state.loading = status
  },
  [SET_NEW_ITEM] (state, item) {
    state.list.push(item)
  },
  [UPDATE_LIST_ITEM] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.set(state.list, index, item)
  },
  [DELETE_LIST_ITEM] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.delete(state.list, index)
  },
  [CHANGE_EDIT_ID] (state, id) {
    state.editId = id
  },
  [FRESH_LOADING] (state, status) {
    state.lessonLoading = status
  },
  [LESSON_UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [LESSON_FRESH_IMAGE] (state, data) {
    if (data.hasOwnProperty('onlyList')) {
      const index = state.list.findIndex(e => e.id === parseInt(data.materialId))
      state.list[index].image.data = data
    } else {
      const index = state.list.findIndex(e => e.id === parseInt(data.lessonId))
      state.currentItem.image.data = data
      state.list[index].image.data = data
    }
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
