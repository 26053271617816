import { $http } from '@/utils/https'
import {
  UPLOAD_LOADING,
  SET_UPLOAD_FILE,
  GET_LAST_UPLOAD_FILES_LOADING,
  SET_LAST_UPLOAD_FILES
} from '../mutation-types'

import {
  UPLOAD,
  GET_LAST_UPLOAD_FILES
} from '../action-types'

const state = {
  list: [],
  isLoadingList: false,
  isLoadingUpload: false
}

const getters = {
  list: state => state.list,
  isLoadingList: state => state.isLoadingList,
  isLoadingUpload: state => state.isLoadingUpload,
}

const actions = {
  [UPLOAD]: async ({ commit }, payload) => {
    try {
      commit(UPLOAD_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_FILE}files`, payload)
      commit(SET_UPLOAD_FILE, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(UPLOAD_LOADING, false)
    }
  },
  [GET_LAST_UPLOAD_FILES]: async ({ commit }, payload) => {
    try {
      commit(GET_LAST_UPLOAD_FILES_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_FILE}files?filters[type]=${payload.typeId}`)
      commit(SET_LAST_UPLOAD_FILES, response.data.data)
      return response
    } catch (e) {
      throw e
    } finally {
      commit(GET_LAST_UPLOAD_FILES_LOADING, false)
    }
  },
}

const mutations = {
  [SET_LAST_UPLOAD_FILES] (state, list) {
    state.list = list
  },
  [SET_UPLOAD_FILE] (state, data) {
    state.list.unshift(data)
  },
  [GET_LAST_UPLOAD_FILES_LOADING] (state, status) {
    state.isLoadingList = status
  },
  [UPLOAD_LOADING] (state, status) {
    state.isLoadingUpload = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations,
}
