// course
export const GET_COURSES = 'GET_COURSES'
export const GET_COURSES_WITH_FORM = 'GET_COURSES_WITH_FORM'
export const COURSES_GET_SETTING = 'COURSES_GET_SETTING'
export const COURSES_GET_CATEGORIES = 'COURSES_GET_CATEGORIES'
export const COURSES_GET_TYPES = 'COURSES_GET_TYPES'
export const GET_COURSES_SIMPLE_RESPONSE = 'GET_COURSES_SIMPLE_RESPONSE'
export const GET_COURSE_USERS_IDS = 'GET_COURSE_USERS_IDS'
export const GET_LIST_ITEM = 'GET_LIST_ITEM'
export const GET_COURSE_MODULES = 'GET_COURSE_MODULES'
export const GET_COURSE_MATERIALS = 'GET_COURSE_MATERIALS'
export const DELETE_COURSE = 'DELETE_COURSE'
export const COPY_COURSE = 'COPY_COURSE'
export const UPDATE_COURSE = 'UPDATE_COURSE'

// info
export const INFO_GET_COURSE = 'INFO_GET_COURSE'
export const CREATE_COURSE = 'CREATE_COURSE'
export const INFO_COURSE_UPDATE = 'INFO_COURSE_UPDATE'
export const INFO_GET_SEO = 'INFO_GET_SEO'
export const INFO_UPDATE_SEO = 'INFO_UPDATE_SEO'
export const INFO_UPDATE_IMAGE = 'INFO_UPDATE_IMAGE'

// skills
export const UPDATE = 'UPDATE'

// packages
export const PACKAGES_GET_LIST = 'PACKAGES_GET_LIST'
export const PACKAGES_GET_SIMPLE_LIST = 'PACKAGES_GET_SIMPLE_LIST'
export const PACKAGES_CREATE = 'PACKAGES_CREATE'
export const PACKAGES_UPDATE = 'PACKAGES_UPDATE'
export const PACKAGES_DELETE = 'PACKAGES_DELETE'
export const PACKAGES_UPDATE_SORT = 'PACKAGES_UPDATE_SORT'

// sections
export const GET_LIST = 'GET_LIST'
export const UPDATE_PACKAGES = 'UPDATE_PACKAGES'
export const UPDATE_POSITIONS = 'UPDATE_POSITIONS'
export const CREATE = 'CREATE'
export const DELETE = 'DELETE'

// certificates
export const CERTIFICATES_GET_LIST = 'CERTIFICATES_GET_LIST'
export const CERTIFICATES_GET_SIMPLE_LIST = 'CERTIFICATES_GET_SIMPLE_LIST'
export const CERTIFICATES_CREATE = 'CERTIFICATES_CREATE'
export const CERTIFICATES_UPDATE = 'CERTIFICATES_UPDATE'
export const CERTIFICATES_DELETE = 'CERTIFICATES_DELETE'

// appearance
export const APPEARANCE_GET_STORE = 'APPEARANCE_GET_STORE'
export const APPEARANCE_UPDATE_STORE = 'APPEARANCE_UPDATE_STORE'
export const APPEARANCE_GET_HEADER = 'APPEARANCE_GET_HEADER'
export const APPEARANCE_UPDATE_HEADER = 'APPEARANCE_UPDATE_HEADER'
export const APPEARANCE_STORE_UPDATE_IMAGE = 'APPEARANCE_STORE_UPDATE_IMAGE'
export const APPEARANCE_HEADER_UPDATE_IMAGE = 'APPEARANCE_HEADER_UPDATE_IMAGE'

// graduate
export const GRADUATE_GET_CONTENT = 'GRADUATE_GET_CONTENT'
export const GRADUATE_UPDATE_CONTENT = 'GRADUATE_UPDATE_CONTENT'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'

// setting
export const GET_TIMETABLE_STARTING = 'GET_TIMETABLE_STARTING'
export const UPDATE_TIMETABLE_STARTING = 'UPDATE_TIMETABLE_STARTING'

// forms
export const COURSES_FORMS_GET_LIST = 'COURSES_FORMS_GET_LIST'
export const COURSES_FORMS_SAVE_FORM = 'COURSES_FORMS_SAVE_FORM'
export const COURSES_FORMS_DELETE_FORM = 'COURSES_FORMS_DELETE_FORM'
