import { $http } from '@/utils/https'
import { camelCase } from 'lodash'

import {
  GRADUATE_SET_CONTENT,
  GRADUATE_GET_CONTENT_LOADING,
  GRADUATE_UPDATE_CONTENT_LOADING,
  FRESH_IMAGE,
  UPDATE_IMAGE_LOADING
} from './types/mutation-types'

import {
  GRADUATE_GET_CONTENT,
  GRADUATE_UPDATE_CONTENT,
  UPDATE_IMAGE
} from './types/action-types'

const state = {
  content: null,
  contentLoading: false,
  updateContentLoading: false,
  updateImageLoading: false
}

const getters = {
  content: state => {
    const obj = {}
    for (const i in state.content) {
      obj[camelCase(i)] = state.content[i]
    }
    return obj
  },
  contentLoading: state => state.contentLoading,
  updateContentLoading: state => state.updateContentLoading,
  updateImageLoading: state => state.updateImageLoading
}

const actions = {
  [GRADUATE_GET_CONTENT]: async ({ commit }, payload) => {
    try {
      commit(GRADUATE_GET_CONTENT_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/graduate-work?include=status,packages,image`)
      commit(GRADUATE_SET_CONTENT, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GRADUATE_GET_CONTENT_LOADING, false)
    }
  },
  [GRADUATE_UPDATE_CONTENT]: async ({ commit }, payload) => {
    try {
      commit(GRADUATE_UPDATE_CONTENT_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/graduate-work?include=status,packages,image`, payload)
      commit(GRADUATE_SET_CONTENT, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GRADUATE_UPDATE_CONTENT_LOADING, false)
    }
  },
  [UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('coursesId')}/graduate-work/${payload.get('graduateWorkId')}/images/${payload.get('id')}?include=seo`, payload)
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('coursesId')}/graduate-work/${payload.get('graduateWorkId')}/images?include=seo`, payload)
      }
      commit(FRESH_IMAGE, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_IMAGE_LOADING, false)
    }
  }
}

const mutations = {
  [GRADUATE_SET_CONTENT] (state, content) {
    state.content = content
  },
  [GRADUATE_GET_CONTENT_LOADING] (state, status) {
    state.contentLoading = status
  },
  [GRADUATE_UPDATE_CONTENT_LOADING] (state, status) {
    state.updateContentLoading = status
  },
  [UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [FRESH_IMAGE] (state, data) {
    state.content.image.data = data
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
