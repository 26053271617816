import { $http } from '@/utils/https'
import Vue from 'vue'

import {
  GET_USER_COURSES_LOADING,
  SET_USER_COURSES,
  SET_USER_COURSES_LENGTH,
  USER_COURSES_CHANGE_SKIP,
  USER_COURSE_REMOVE_ITEM,
  CHANGE_LOADING
} from './types/mutation-types'

import {
  GET_USER_COURSES,
  DELETE,
  UPDATE,
  CREATE,
  UPDATE_PROGRESS
} from './types/action-types'

import {
  GET_COURSES,
  PACKAGES_GET_LIST
} from '@/store/modules/courses/types/action-types'
import {
  GET_COURSES_LOADING,
  SET_COURSES,
  PACKAGES_GET_LIST_LOADING,
  PACKAGES_SET_LIST
} from '@/store/modules/courses/types/mutation-types'

const state = {
  courses: [],
  isCoursesLoading: false,
  packagesCourse: [],
  isPackagesCourseLoading: false,

  userCourses: [],
  userCoursesLength: 0,
  isUserCoursesLoading: false,
  skip: 0,
  limit: 15,
  isLoading: false
}

const getters = {
  courses: state => state.courses,
  isCoursesLoading: state => state.isCoursesLoading,
  userCourses: state => state.userCourses,
  isUserCoursesLoading: state => state.isUserCoursesLoading,
  userCoursesLength: state => state.userCoursesLength,
  skip: state => state.skip,
  limit: state => state.limit,
  isLoading: state => state.isLoading,
  packagesCourse: state => state.packagesCourse,
  isPackagesCourseLoading: state => state.isPackagesCourseLoading
}

const actions = {
  [GET_COURSES]: async ({ commit }, payload) => {
    try {
      commit(GET_COURSES_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses?include=packages&filters[keywords]=${payload.keywords}`)
      commit(SET_COURSES, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_COURSES_LOADING, false)
    }
  },
  [PACKAGES_GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(PACKAGES_GET_LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/packages?include=status`)
      commit(PACKAGES_SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(PACKAGES_GET_LIST_LOADING, false)
    }
  },
  [GET_USER_COURSES]: async ({ commit }, payload) => {
    try {
      commit(GET_USER_COURSES_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}users/${payload.userId}/course-accesses?include=package,course,currentMaterial&limit=${state.limit}&skip=${state.skip}`)
      commit(SET_USER_COURSES, response.data.data)
      commit(SET_USER_COURSES_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(GET_USER_COURSES_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}users/${payload.userId}/course-accesses/${payload.id}`)
      commit(USER_COURSE_REMOVE_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/${payload.userId}/course-accesses/${payload.id}?include=package,course,currentUserLesson`, payload)
      dispatch(GET_USER_COURSES, { userId: payload.userId })
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  // UPDATE_COURSE_SUCCESS: async ({ dispatch, commit }, payload) => {
  //     try {
  //         commit(CHANGE_LOADING, true)
  //
  //         await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/${payload.userId}/courses/${payload.id}?include=package,course,currentUserLesson`, payload)
  //         dispatch(GET_USER_COURSES, { userId: payload.userId })
  //     } catch (e) {
  //         throw e
  //     } finally {
  //         commit(CHANGE_LOADING, false)
  //     }
  // },

  [UPDATE_PROGRESS]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/${payload.userId}/courses/${payload.courseUserId}/update-progress`, payload)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      await $http.post(`${process.env.VUE_APP_ROOT_COURSE}users/${payload.userId}/course-accesses?include=package,course,currentUserLesson`, payload)
      dispatch(GET_USER_COURSES, { userId: payload.userId })
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_COURSES] (state, list) {
    state.courses = list
  },
  [GET_COURSES_LOADING] (state, status) {
    state.isCoursesLoading = status
  },
  [PACKAGES_SET_LIST] (state, list) {
    state.packagesCourse = list
  },
  [PACKAGES_GET_LIST_LOADING] (state, status) {
    state.isPackagesCourseLoading = status
  },
  [SET_USER_COURSES] (state, list) {
    state.userCourses = list
  },
  [USER_COURSES_CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [GET_USER_COURSES_LOADING] (state, status) {
    state.isUserCoursesLoading = status
  },
  [SET_USER_COURSES_LENGTH] (state, length) {
    state.userCoursesLength = length
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [USER_COURSE_REMOVE_ITEM] (state, params) {
    const index = state.userCourses.findIndex(e => e.id === params.id)
    Vue.delete(state.userCourses, index)
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
