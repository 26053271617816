export const SET_LIST = 'SET_LIST'
export const CHANGE_LIST_LOADING = 'CHANGE_LIST_LOADING'
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH'
export const CHANGE_LIST_FILTER = 'CHANGE_LIST_FILTER'
export const CHANGE_SKIP = 'CHANGE_SKIP'
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const CHANGE_SHOW_HOME_WORK_DIALOG_LESSON = 'CHANGE_SHOW_HOME_WORK_DIALOG_LESSON'
export const SET_ITEM = 'SET_ITEM'
export const DELETE_LIST_ITEM = 'DELETE_LIST_ITEM'
export const ADD_LIST_ITEM = 'ADD_LIST_ITEM'
export const SET_UPDATED_LIST_ITEM = 'SET_UPDATED_LIST_ITEM'
export const REMOVE_LIST_ITEM = 'REMOVE_LIST_ITEM'
export const UPDATE_IMAGE_LOADING = 'UPDATE_IMAGE_LOADING'
export const FRESH_IMAGE = 'FRESH_IMAGE'

export const GET_COURSES_LOADING = 'GET_COURSES_LOADING'
export const SET_COURSES = 'SET_COURSES'
export const CHANGE_FILTER = 'CHANGE_FILTER'
export const GET_COURSE_LOADING = 'GET_COURSE_LOADING'
export const SET_COURSE = 'SET_COURSE'
export const REPLACE_LIST_ITEM = 'REPLACE_LIST_ITEM'
export const CHANGE_SORT = 'CHANGE_SORT'
export const REPLACE_CANCELED_ITEM_FROM_LIST = 'REPLACE_CANCELED_ITEM_FROM_LIST'

export const CHANGE_EDIT_ID = 'CHANGE_EDIT_ID'
export const LIST_LOADING = 'LIST_LOADING'
export const FRESH_LOADING = 'FRESH_LOADING'
export const SET_NEW_ITEM = 'SET_NEW_ITEM'
export const UPDATE_LIST_ITEM = 'UPDATE_LIST_ITEM'
export const CHANGE_PRACTIC_TYPE = 'CHANGE_PRACTIC_TYPE'
export const SET_COMMENTS = 'SET_COMMENTS'
export const SET_COMMENT = 'SET_COMMENT'
export const REMOVE_COMMENT = 'REMOVE_COMMENT'
export const UPDATE_SET_COMMENT = 'UPDATE_SET_COMMENT'
export const SET_HOME_WORKS = 'SET_HOME_WORKS'
export const SET_GRADUATE_WORK = 'SET_GRADUATE_WORK'
export const SET_COURSE_LIST = 'SET_COURSE_LIST'
