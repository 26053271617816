import { $http } from '@/utils/https'
import Vue from 'vue'
import store from '@/store'

import { CREATE, DELETE, GET_LIST, UPDATE, UPDATE_SORT } from './types/action-types'
import {
  CHANGE_EDIT_ID,
  CHANGE_VIDEO_CHAPTERS,
  DELETE_LIST_ITEM,
  FRESH_LOADING,
  LIST_LOADING,
  RESET,
  SET_LIST,
  SET_NEW_ITEM,
  UPDATE_LIST_ITEM
} from './types/mutation-types'

const state = {
  listLoading: false,
  list: [],
  loading: false,
  editId: ''
}

const getters = {
  list: state => state.list,
  listLoading: state => state.listLoading,
  loading: state => state.loading,

  editId: state => state.editId
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(LIST_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/${store.state.contentMaterials.selectedMaterialType}/${payload.material_id}/contents?include=packages,type,status,files,chapters`)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(LIST_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/${store.state.contentMaterials.selectedMaterialType}/${payload.material_id}/contents/${payload.id}`)
      commit(DELETE_LIST_ITEM, payload)
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [CREATE]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/${store.state.contentMaterials.selectedMaterialType}/${payload.get('material_id')}/contents?include=packages,status,type,chapters`, payload)
      commit(SET_NEW_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [UPDATE]: async ({ commit }, payload) => {
    try {
      commit(FRESH_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.get('course_id')}/${store.state.contentMaterials.selectedMaterialType}/${payload.get('material_id')}/contents/${payload.get('id')}?include=packages,status,type,chapters`, payload)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(FRESH_LOADING, false)
    }
  },
  [UPDATE_SORT]: async ({ commit }, payload) => {
    try {
      commit(LIST_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.course_id}/${store.state.contentMaterials.selectedMaterialType}/${payload.material_id}/contents/sort?include=packages,status,type`, payload)
      commit(SET_LIST, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(LIST_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [LIST_LOADING] (state, status) {
    state.listLoading = status
  },
  [SET_NEW_ITEM] (state, item) {
    state.list.push(item)
  },
  [UPDATE_LIST_ITEM] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.set(state.list, index, item)
  },
  [DELETE_LIST_ITEM] (state, item) {
    const index = state.list.findIndex(e => e.id === item.id)
    Vue.delete(state.list, index)
  },
  [CHANGE_EDIT_ID] (state, id) {
    state.editId = id
  },
  [CHANGE_VIDEO_CHAPTERS] (state, item) {
    const { type, ...data } = item
    const listIndex = state.list.findIndex(e => e.id === item.content_id)
    if (type === 'create') {
      state.list[listIndex].chapters.data.push(data)
    } else if (type === 'update') {
      const index = state.list[listIndex].chapters.data.findIndex(e => e.id === data.id)
      Vue.set(state.list[listIndex].chapters.data, index, data)
    } else {
      const index = state.list[listIndex].chapters.data.findIndex(e => e.id === data.id)
      Vue.delete(state.list[listIndex].chapters.data, index)
    }
  },
  [FRESH_LOADING] (state, status) {
    state.loading = status
  },
  [RESET] (state) {
    state.list = []
    state.editId = ''
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
