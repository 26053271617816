export const GET_LIST_LOADING = 'GET_LIST_LOADING'
export const SET_LIST = 'SET_LIST'
export const FILTER_CHANGE_SEARCH_INPUT = 'FILTER_CHANGE_SEARCH_INPUT'
export const SET_NEW_MODULE = 'SET_NEW_MODULE'
export const MODULE_LOADING = 'MODULE_LOADING'
export const DELETE_MODULES_ITEM = 'DELETE_MODULES_ITEM'
export const FRESH_MODULE = 'FRESH_MODULE'
export const MODULE_FRESH_IMAGE = 'MODULE_FRESH_IMAGE'
export const MODULE_UPDATE_IMAGE_LOADING = 'MODULE_UPDATE_IMAGE_LOADING'
export const SET_ITEM = 'SET_ITEM'
