export default [
  {
    path: 'forms',
    name: 'forms',
    component: () => import('@/modules/forms/main/index.vue'),
    meta: {}
  },
  {
    path: 'forms/:course_id/course',
    name: 'forms.view',
    component: () => import('@/modules/forms/view/index.vue'),
    meta: {},
  }
]
