export const FETCH_LIST = 'FETCH_LIST'
export const DELETE_USER = 'DELETE_USER'
export const CREATE_USER = 'CREATE_USER'
export const GET_LIST = 'GET_LIST'
export const GET_LIST_ITEM = 'GET_LIST_ITEM'
export const UPDATE_USER = 'UPDATE_USER'
export const STUDENTS_GET_COURSES = 'STUDENTS_GET_COURSES'
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD'
export const GET_SPEAKERS = 'GET_SPEAKERS'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'
export const GET_USER_COURSES = 'GET_USER_COURSES'
export const DELETE = 'DELETE'
export const UPDATE = 'UPDATE'
export const CREATE = 'CREATE'
export const GET_BASIC_USERS = 'GET_BASIC_USERS'
export const GET_TARIFFS = 'GET_TARIFFS'
export const GET_USER_TARIFFS = 'GET_USER_TARIFFS'
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES'
export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const UPDATE_ROLES = 'UPDATE_ROLES'
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS'
export const GET_USERS = 'GET_USERS'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
export const UPDATE_CONTACTS = 'UPDATE_CONTACTS'
export const GET_CHANGE_CONTACTS = 'GET_CHANGE_CONTACTS'
export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_USERS_SETTING = 'GET_USERS_SETTING'
export const SUMMARY = 'SUMMARY'
export const GET_USER_CARDS = 'GET_USER_CARDS'
export const GET_USER_TRANSACTIONS = 'GET_USER_TRANSACTIONS'
export const REVERSE = 'REVERSE'
