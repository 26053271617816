export default [
  {
    path: 'courses',
    name: 'courses',
    component: () => import('@/modules/courses/main/index.vue'),
    meta: {}
  },
  {
    path: 'courses/',
    name: 'courses.view',
    component: () => import('@/modules/courses/view/index.vue'),
    meta: {},
    children: [
      {
        path: 'create',
        name: 'courses.create',
        component: () => import('@/modules/courses/steps/info/index.vue'),
        meta: {}
      },
      {
        path: ':course_id/info',
        name: 'courses.info',
        component: () => import('@/modules/courses/steps/info/index.vue'),
        meta: {}
      },
      {
        path: ':course_id/packages',
        name: 'courses.packages',
        component: () => import('@/modules/courses/steps/packages/index.vue'),
        meta: {}
      },
      {
        path: ':course_id/appearance',
        name: 'courses.appearance',
        component: () => import('@/modules/courses/steps/appearance/index.vue'),
        meta: {}
      },
      {
        path: ':course_id/components',
        name: 'courses.components',
        component: () => import('@/modules/courses/steps/components/index.vue'),
        meta: {}
      },
      {
        path: ':course_id/contents',
        name: 'courses.contents',
        redirect: ':course_id/contents/studying/modules',
        component: () => import('@/modules/courses/steps/contents/index.vue'),
        meta: {},
        children: [
          {
            path: 'studying/modules',
            name: 'courses.contents.studying',
            component: () => import('@/modules/courses/steps/contents/pages/studying/modules/pages/main/index.vue'),
            meta: {}
          },
          {
            path: 'studying',
            redirect: 'studying/modules'
          },
          {
            path: 'timetable',
            name: 'courses.contents.timetable',
            component: () => import('@/modules/courses/steps/contents/pages/events/pages/main/index.vue'),
            meta: {}
          },
          {
            path: 'studying/modules/:module_id/lessons',
            name: 'courses.contents.lessons',
            component: () => import('@/modules/courses/steps/contents/pages/studying/lessons/pages/main/index.vue'),
            meta: {}
          },
          {
            path: 'streaming',
            name: 'courses.contents.streaming',
            component: () => import('@/modules/courses/steps/contents/pages/streaming/pages/main/index.vue'),
            meta: {}
          },
          {
            path: 'qa-sessions',
            name: 'courses.contents.qa-sessions',
            component: () => import('@/modules/courses/steps/contents/pages/materials/pages/main/index.vue'),
            meta: {}
          },
          {
            path: 'meetups',
            name: 'courses.contents.meetups',
            component: () => import('@/modules/courses/steps/contents/pages/materials/pages/main/index.vue'),
            meta: {}
          },
          {
            path: 'bonuses',
            name: 'courses.contents.bonuses',
            component: () => import('@/modules/courses/steps/contents/pages/materials/pages/main/index.vue'),
            meta: {}
          },
          {
            path: 'socials',
            name: 'courses.contents.socials',
            component: () => import('@/modules/courses/steps/contents/pages/socials/index.vue'),
            meta: {}
          }
        ]
      },
      {
        path: ':course_id/graduate-work',
        name: 'courses.graduate-work',
        component: () => import('@/modules/courses/steps/graduate-work/index.vue'),
        meta: {}
      },
      {
        path: ':course_id/settings',
        name: 'courses.settings',
        component: () => import('@/modules/courses/steps/settings/index.vue'),
        meta: {}
      }
    ]
  }
]
