import { $http } from '@/utils/https'
import Vue from 'vue'
import { selectedModule } from '@/mixins/index'

import {
  CONTENT_GET_DATA_LOADING,
  CONTENT_SET_DATA,
  CONTENT_UPDATE_DATA_LOADING,
  CONTENT_SET_NEW_ITEM,
  CONTENT_UPDATE_ITEM
} from './types/mutation-types'

import {
  CONTENT_GET_DATA,
  CONTENT_CREATE_DATA,
  CONTENT_UPDATE_DATA
} from './types/action-types'

const state = {
  content: null,
  contentLoading: false,
  contentUpdateLoading: false
}

const getters = {
  content: state => {
    if (state.content) {
      return {
        id: state.content.id,
        country: state.content.country_id,
        title: state.content.title,
        shortContent: state.content.short_content,
        defaultLocale: state.content.default_locale,
        content: state.content.contents.data.map(e => {
          return {
            id: e.id,
            content: e.content,
            title: e.title,
            status: e.status,
            locale: e.locale,
            shortContent: e.short_content
          }
        })
      }
    }
  },
  contentLoading: state => state.contentLoading,
  contentUpdateLoading: state => state.contentUpdateLoading
}

const actions = {
  [CONTENT_GET_DATA]: async ({ commit }, payload) => {
    try {
      commit(CONTENT_GET_DATA_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_NEWS}${selectedModule(payload)}/${payload.id}?include=contents`)
      commit(CONTENT_SET_DATA, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CONTENT_GET_DATA_LOADING, false)
    }
  },
  [CONTENT_UPDATE_DATA]: async ({ commit }, payload) => {
    try {
      commit(CONTENT_UPDATE_DATA_LOADING, true)

      const response = await $http.patch(`${process.env.VUE_APP_ROOT_NEWS}contents/${payload.id}`, payload)
      commit(CONTENT_UPDATE_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CONTENT_UPDATE_DATA_LOADING, false)
    }
  },
  [CONTENT_CREATE_DATA]: async ({ commit }, payload) => {
    try {
      commit(CONTENT_UPDATE_DATA_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_NEWS}contents`, payload)
      commit(CONTENT_SET_NEW_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CONTENT_UPDATE_DATA_LOADING, false)
    }
  }
}

const mutations = {
  [CONTENT_SET_DATA] (state, data) {
    state.content = data
  },
  [CONTENT_SET_NEW_ITEM] (state, data) {
    state.content.contents.data.push(data)
  },
  [CONTENT_UPDATE_ITEM] (state, data) {
    const index = state.content.contents.data.findIndex(e => e.locale === data.locale)
    Vue.set(state.content.contents.data, index, data)
  },
  [CONTENT_GET_DATA_LOADING] (state, status) {
    state.contentLoading = status
  },
  [CONTENT_UPDATE_DATA_LOADING] (state, status) {
    state.contentUpdateLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
