export const GET_CONFIG_LOADING = 'GET_CONFIG_LOADING'
export const SET_CONFIG = 'SET_CONFIG'
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_LANGUAGE_INTERFACE_LIST = 'SET_LANGUAGE_INTERFACE_LIST'
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS'
export const SET_COUNTRY = 'SET_COUNTRY'
export const CHANGE_COUNTRY_LOADING = 'CHANGE_COUNTRY_LOADING'
export const SET_BASIC_COUNTRIES = 'SET_BASIC_COUNTRIES'
export const CHANGE_DIALOG_VIEW = 'CHANGE_DIALOG_VIEW'
export const CHANGE_EDIT_ID = 'CHANGE_EDIT_ID'
export const UPDATE_COUNTRIES_LOADING = 'UPDATE_COUNTRIES_LOADING'
export const FRESH_COUNTRY = 'FRESH_COUNTRY'
export const ADD_COUNTRY = 'ADD_COUNTRY'

export const SET_LIST = 'SET_LIST'
export const CHANGE_LIST_LOADING = 'CHANGE_LIST_LOADING'
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH'
export const CHANGE_SKIP = 'CHANGE_SKIP'
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const CHANGE_SHOW_DIALOG = 'CHANGE_SHOW_DIALOG'
export const SET_ITEM = 'SET_ITEM'
export const FRESH_DATA = 'FRESH_DATA'
export const SET_GROUPS = 'SET_GROUPS'
export const GET_BASIC_LOADING = 'GET_BASIC_LOADING'
export const SET_BASIC_LOCALES = 'SET_BASIC_LOCALES'
export const REPLACE_LIST_ITEM = 'REPLACE_LIST_ITEM'
export const ADD_LIST_ITEM = 'ADD_LIST_ITEM'
export const UPDATE_SEO_LOADING = 'UPDATE_SEO_LOADING'
export const SEO_LOADING = 'SEO_LOADING'
export const SET_SEO = 'SET_SEO'
export const FRESH_SEO_ITEM = 'FRESH_SEO_ITEM'
export const ADD_SEO_ITEM = 'ADD_SEO_ITEM'
export const UPDATE_TRANSLATION_LOADING = 'UPDATE_TRANSLATION_LOADING'
export const FRESH_TRANSLATION_ITEM = 'FRESH_TRANSLATION_ITEM'
export const ADD_TRANSLATION_ITEM = 'ADD_TRANSLATION_ITEM'
export const REMOVE_LIST_ITEM = 'REMOVE_LIST_ITEM'
export const CHANGE_LAYOUTS_LIST_LOADING = 'CHANGE_LAYOUTS_LIST_LOADING'
export const SET_LAYOUTS_LIST = 'SET_LAYOUTS_LIST'
export const CHANGE_LIST_FILTER = 'CHANGE_LIST_FILTER'
