import { camelCase } from 'lodash'

export function transformerSeo (item) {
  if (!item) return
  return item.map(e => {
    const transformData = {}
    for (const i in e) {
      transformData[camelCase(i)] = e[i]
      if (i === 'open_graph_tags' || i === 'twitter_card_tags') {
        const arr = []
        for (const [key, value] of Object.entries(e[i])) {
          arr.push({
            key: key,
            value: value
          })
        }
        transformData[camelCase(i)] = arr
      }
    }
    return transformData
  })
}
