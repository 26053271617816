export default [
    {
        path: 'user-works/courses',
        name: 'user-works-courses',
        component: () => import('@/modules/user-works/pages/list-courses/index.vue')
    },
    {
        path: 'user-works/courses/:courseId',
        name: 'user-work-course',
        component: () => import('@/modules/user-works/pages/list-users/index.vue')
    },
    {
        path: 'user-works/home-works/:userHomeWorkId',
        name: 'user-home-work-details',
        component: () => import('@/modules/user-works/pages/details-home-work/index.vue')
    },
    {
        path: 'user-works/graduate-works/:userGraduateWorkId',
        name: 'user-graduate-work-details',
        component: () => import('@/modules/user-works/pages/details-graduate-work/index.vue')
    },
]