export default [
	{
		path: 'menus',
		name: 'administration.menus',
		component: () => import('@/modules/administration/menus/MenusIndex.vue'),
		meta: {}
	},
	{
		path: 'menus/:id',
		props: true,
		name: 'administration.menus.item',
		component: () => import('@/modules/administration/menus/MenuItemIndex.vue'),
		meta: {}
	},
	{
		path: 'pages',
		name: 'administration.pages',
		component: () => import('@/modules/administration/pages/PagesIndex.vue'),
		meta: {}
	}
]
