import Vue from 'vue'
import { $http } from '@/utils/https'

import {
    CHANGE_LOADING,
    CHANGE_SKIP,
    FRESH_LIST,
    SET_ITEM,
    SET_LIST,
    SET_LIST_LENGTH,
    SET_LIST_LOADING
} from './types/mutation-types'

import {
    CREATE,
    DELETE,
    GET_LIST, SET_COURSES_LIST,
    UPDATE
} from './types/action-types'

import {GET_COURSES} from "@/store/modules/courses/types/action-types";
import {MODULE_UPDATE_IMAGE} from "@/store/modules/modules/types/action-types";
import {MODULE_FRESH_IMAGE, MODULE_UPDATE_IMAGE_LOADING} from "@/store/modules/modules/types/mutation-types";

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
    list        : [],
    coursesList : [],
    isListLoading: false,
    isLoading   : false,
    currentItem : null,
    listLength  : 0,
    limit       : 200,
    skip        : 0
}

const getters = {
    list          : state => camelcaseObjectDeep(state.list),
    isListLoading : state => state.isListLoading,
    isLoading     : state => state.isLoading,
    listLength    : state => state.listLength,
    currentItem   : state => camelcaseObjectDeep(state.currentItem),
    limit         : state => state.limit,
    skip          : state => state.skip,
    coursesList   : state => state.coursesList
}

const actions = {
    [GET_COURSES]   : async ({ commit }, payload) => {
        try {
            commit(SET_LIST_LOADING, true)
            
            const data = {
                include             : 'type,image,status',
                limit               : state.limit,
                skip                : state.skip,
                'filters[is_trial]' : 0
            };

            for (let i = 0; i < payload.ids.length; i++) {
                data[`filters[id][${i}]`] = payload.ids[i]
            }
            
            const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}courses`, {params: new URLSearchParams(data)})
            
            if(response.data && 'data' in response.data && response.data.data){
                commit(SET_COURSES_LIST, response.data.data)
            }else{
                commit(SET_COURSES_LIST, [])
            }
            
        } catch (e) {
            throw e
        }finally {
            commit(SET_LIST_LOADING, false)
        }
    },
    [GET_LIST]      : async ({ commit }, payload) => {
        try {
            console.log('payload:');
            console.log(payload);
            
            commit(SET_LIST_LOADING, true)
            
            if(payload){
                if(!payload.tariffId && 'tariff_id' in payload && payload.tariff_id){
                    payload.tariffId = payload.tariff_id;
                }
            }else{
                commit(SET_LIST_LOADING, false)
                
                return [];
            }
            
            var url = `${process.env.VUE_APP_ROOT_EDUCATION}tariff-courses/${payload.tariffId}`;
            
            const response = await $http.get(url);
            
            if(response.data){
                if('data' in response.data && response.data.data){
                    commit(SET_LIST         , response.data.data);
                }else{
                    commit(SET_LIST         , []);
                }
                
                if('meta' in response.data && response.data.meta){
                    commit(SET_LIST_LENGTH  , response.data.meta.total);
                }else{
                    commit(SET_LIST_LENGTH  , 0);
                }
            }else{
                commit(SET_LIST         , []);
                commit(SET_LIST_LENGTH  , 0);
            }
            
            return response;
        } catch (e) {
            throw e
        } finally {
            commit(SET_LIST_LOADING, false)
        }
    },
    [DELETE]        : async ({ commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            await $http.delete(`${process.env.VUE_APP_ROOT_EDUCATION}tariff-courses/${payload.id}`)
            
            commit(FRESH_LIST, payload)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [UPDATE]        : async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const form = new FormData();
            
            form.append('course_id', payload.course_id);
            form.append('tariff_id', payload.tariff_id);
            
            for (let i = 0; i < payload.packages.length; i++) {
                form.append(`packages[${i}]`, payload.packages[i])
            }
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tariff-courses/update/${payload.id}`, form)
            
            commit(SET_ITEM, response.data.data)
            
            dispatch(GET_LIST, payload)
        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_LOADING, false)
        }
    },
    [CREATE]        : async ({ dispatch, commit }, payload) => {
        try {
            commit(CHANGE_LOADING, true)
            
            const form = new FormData();
            
            form.append('course_id', payload.course_id)
            form.append('tariff_id', payload.tariff_id)
            
            for (let i = 0; i < payload.packages.length; i++) {
                form.append(`packages[${i}]`, payload.packages[i])
            }
            
            const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tariff-courses/create`, form)
            
            commit(SET_ITEM, response.data.data)
            
            dispatch(GET_LIST)
        } finally {
            commit(CHANGE_LOADING, false)
        }
    }
}

const mutations = {
    [SET_LIST] (state, data) {
        state.list = data
    },
    [SET_LIST_LOADING] (state, status) {
        state.isListLoading = status
    },
    [FRESH_LIST] (state, params) {
        const index = state.list.findIndex(e => e.id === params.id)
        
        Vue.delete(state.list, index)
    },
    [SET_LIST_LENGTH] (state, length) {
        state.listLength = length
    },
    [CHANGE_LOADING] (state, status) {
        state.isLoading = status
    },
    [CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [SET_ITEM] (state, data) {
        state.currentItem = data
    },
    [CHANGE_SKIP] (state, count) {
        state.skip = count
    },
    [SET_COURSES_LIST] (state, list) {
        state.coursesList = list
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
