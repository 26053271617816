import { $http } from '@/utils/https'
import {
  CHANGE_LOADING,
  SET_DATA,
  FRESH_IMAGE,
  UPDATE_IMAGE_LOADING
} from './types/mutation-types'

import {
  CREATE,
  GENERAL_GET_DATA,
  UPDATE,
  UPDATE_IMAGE
} from './types/action-types'
const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  currentItem: null,
  isLoading: false,
  updateImageLoading: false
}

const getters = {
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  isLoading: state => state.isLoading,
  updateImageLoading: state => state.updateImageLoading
}

const actions = {
  [GENERAL_GET_DATA]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.get(`${process.env.VUE_APP_ROOT_EVENT}events/${payload.id}?include=country,picture,picture.seo,cover,cover.seo`)
      commit(SET_DATA, response.data.data)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EVENT}events/${payload.id}?include=country,picture,picture.seo,cover,cover.seo`, payload)
      commit(SET_DATA, response.data.data)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)

      const response = await $http.post(`${process.env.VUE_APP_ROOT_EVENT}events?include=country,picture,picture.seo,cover,cover.seo`, payload)
      commit(SET_DATA, response.data.data)
      return response.data.data
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_IMAGE]: async ({ commit }, payload) => {
    try {
      commit(UPDATE_IMAGE_LOADING, true)
      let response = ''
      if (payload.get('id')) {
        response = await $http.post(`${process.env.VUE_APP_ROOT_EVENT}events/${payload.get('event_id')}/images/${payload.get('id')}?include=seo`, payload)
      } else {
        response = await $http.post(`${process.env.VUE_APP_ROOT_EVENT}events/${payload.get('event_id')}/images?include=seo`, payload)
      }
      response.data.data.type_id = parseInt(payload.get('type_id'))
      commit(FRESH_IMAGE, response.data.data)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(UPDATE_IMAGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_DATA] (state, data) {
    state.currentItem = data
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [UPDATE_IMAGE_LOADING] (state, status) {
    state.updateImageLoading = status
  },
  [FRESH_IMAGE] (state, data) {
    if (data.type_id === 2) {
      state.currentItem.picture.data = data
    } else {
      state.currentItem.cover.data = data
    }
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
