export const GET_LIST = 'GET_LIST'
export const GET_LIST_ITEM = 'GET_LIST_ITEM'
export const CREATE = 'CREATE'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'
export const UPDATE_SORT = 'UPDATE_SORT'
export const CANCEL = 'CANCEL'
export const GET_COURSE = 'GET_COURSE'
export const ACCEPT_QUESTION = 'ACCEPT_QUESTION'
export const DECLINE_QUESTION = 'DECLINE_QUESTION'
export const COMPLETE_WORK = 'COMPLETE_WORK'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'
export const GET_COMMENTS_HOME_WORK = 'GET_COMMENTS_HOME_WORK'
export const GET_COMMENTS_GRADUATE_WORK = 'GET_COMMENTS_GRADUATE_WORK'
export const SEND_COMMENT_HOME_WORK = 'SEND_COMMENT_HOME_WORK'
export const SEND_COMMENT_GRADUATE_WORK = 'SEND_COMMENT_GRADUATE_WORK'
export const UPDATE_COMMENT_HOME_WORK = 'UPDATE_COMMENT_HOME_WORK'
export const UPDATE_COMMENT_GRADUATE_WORK = 'UPDATE_COMMENT_GRADUATE_WORK'
export const DELETE_COMMENT_HOME_WORK = 'DELETE_COMMENT_HOME_WORK'
export const DELETE_COMMENT_GRADUATE_WORK = 'DELETE_COMMENT_GRADUATE_WORK'
export const CLEAR_COMMENTS_LIST = 'CLEAR_COMMENTS_LIST'
export const GET_COURSE_MODULES = 'GET_COURSE_MODULES'
export const GET_COURSE_HOME_WORKS = 'GET_COURSE_HOME_WORKS'
export const GET_COURSE_GRADUATE_WORK = 'GET_COURSE_GRADUATE_WORK'
export const COURSE_LIST = 'COURSE_LIST'
