import { $http } from '@/utils/https'
import {
  CHANGE_EDIT_ID,
  CHANGE_FILTER,
  CHANGE_LIST_LOADING,
  CHANGE_LOADING,
  CHANGE_SHOW_DIALOG,
  CHANGE_SKIP,
  RESET_FILTER,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH
} from './types/mutation-types'

import { ACCEPT_QUESTION, CANCEL, COMPLETE_TEST, DECLINE_QUESTION, GET_LIST, GET_LIST_ITEM } from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: [],
  isListLoading: false,
  limit: 15,
  skip: 0,
  listLength: 0,
  isLoading: false,
  currentItem: null,
  editId: null,

  filterStatus: '',
  filterUserId: '',

  showDialogView: false
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  listLength: state => state.listLength,
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  limit: state => state.limit,
  skip: state => state.skip,

  showDialogView: state => state.showDialogView,
  editId: state => state.editId
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}users/tests?include=user,test&filters[user]=${state.filterUserId || ''}&filters[status]=${state.filterStatus || ''}&filters[test]=${payload.testId}&limit=${state.limit}&skip=${state.skip}`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}users/tests/${state.editId}?include=user,questions`)

      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CANCEL]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}users/tests/${payload.get('userTestId')}/cancel`, payload)
      dispatch(GET_LIST, { testId: payload.get('testId') })
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [COMPLETE_TEST]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}users/tests/${payload.get('userTestId')}/complete?include=user,questions`, payload)
      dispatch(GET_LIST, { testId: payload.get('testId') })
      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [ACCEPT_QUESTION]: async ({ commit }, payload) => {
    try {
      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}users/tests/${payload.get('testId')}/questions/${payload.get('id')}/accept?include=user,questions`, payload)
      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    }
  },
  [DECLINE_QUESTION]: async ({ commit }, payload) => {
    try {
      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}users/tests/${payload.get('testId')}/questions/${payload.get('id')}/decline?include=user,questions`, payload)
      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [CHANGE_EDIT_ID] (state, id) {
    state.editId = id
  },
  [CHANGE_FILTER] (state, params) {
    switch (params.type) {
      case 'status': {
        state.filterStatus = params.value
        break
      }
      case 'user': {
        state.filterUserId = params.value
        break
      }
    }
  },
  [RESET_FILTER] (state) {
    state.filterStatus = ''
    state.filterUserId = ''
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [CHANGE_SHOW_DIALOG] (state, status) {
    state.showDialogView = status
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}
