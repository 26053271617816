import { $http } from '@/utils/https'
import {
  CHANGE_LOADING,
  SET_GROUPS
} from './types/mutation-types'

import {
  GET_GROUPS
} from './types/action-types'
const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  groups: [],
  isLoading: false
}

const getters = {
  groups: state => camelcaseObjectDeep(state.groups),
  isLoading: state => state.isLoading
}

const actions = {
  [GET_GROUPS]: async ({ commit }) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_SETTING}translation-groups`)

      commit(SET_GROUPS, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_GROUPS] (state, list) {
    state.groups = list
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
