export default [
  {
    path: 'events',
    name: 'news.events',
    component: () => import('@/modules/events/pages/main/index.vue'),
    meta: {}
  },
  {
    path: 'news/list',
    name: 'news.list',
    component: () => import('@/modules/news/pages/main/index.vue'),
    meta: {}
  },
  {
    path: 'news/categories',
    name: 'news.categories',
    component: () => import('@/modules/news/pages/categories/index.vue'),
    meta: {}
  },
  {
    path: 'news/tags',
    name: 'news.tags',
    component: () => import('@/modules/news/pages/tags/index.vue'),
    meta: {}
  },
  {
    path: 'news/',
    name: 'news.view',
    component: () => import('@/modules/news/pages/view/index.vue'),
    meta: {},
    children: [
      {
        path: 'create',
        name: 'news.create',
        component: () => import('@/modules/news/pages/general/index.vue'),
        meta: {}
      },
      {
        path: 'tags/create',
        name: 'news.tags.create',
        component: () => import('@/modules/news/pages/general/index.vue'),
        meta: {}
      },
      {
        path: ':id/general',
        name: 'news.general',
        component: () => import('@/modules/news/pages/general/index.vue'),
        meta: {}
      },
      {
        path: ':id/seo',
        name: 'news.seo',
        component: () => import('@/modules/news/pages/seo/index.vue'),
        meta: {}
      },
      {
        path: 'tags/:id/seo',
        name: 'news.tags.seo',
        component: () => import('@/modules/news/pages/seo/index.vue'),
        meta: {}
      },
      {
        path: ':id/content',
        name: 'news.content',
        component: () => import('@/modules/news/pages/content/index.vue'),
        meta: {}
      },
      {
        path: 'tags/:id/content',
        name: 'news.tags.content',
        component: () => import('@/modules/news/pages/content/index.vue'),
        meta: {}
      }
    ]
  }
]
