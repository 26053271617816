import { $http } from '@/utils/https'
import Vue from 'vue'
import {
  ADD_LIST_ITEM,
  CHANGE_LIST_LOADING,
  CHANGE_LOADING,
  CHANGE_SKIP,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH,
  SET_UPDATED_LIST_ITEM
} from './types/mutation-types'

import { CREATE, DELETE, GET_LIST, GET_LIST_ITEM, UPDATE, UPDATE_SORT } from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: null,
  currentItem: null,
  isLoading: false,
  isListLoading: false,
  limit: 20,
  skip: 0
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  limit: state => state.limit,
  skip: state => state.skip
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}tests/${payload.id}/questions?include=type,answerVariants`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_EDUCATION}tests/${payload.testId}/questions/${payload.id}?include=answerVariants,type`)

      commit(SET_ITEM, response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tests/${payload.get('testId')}/questions?include=answerVariants,type`, payload)
      commit(ADD_LIST_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tests/${payload.get('testId')}/questions/${payload.get('id')}?include=answerVariants,type`, payload)
      commit(SET_UPDATED_LIST_ITEM, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.delete(`${process.env.VUE_APP_ROOT_EDUCATION}tests/${payload.testId}/questions/${payload.id}`)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [UPDATE_SORT]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_EDUCATION}tests/${payload.get('testId')}/questions/sort?include=answerVariants,type`, payload)

      commit(SET_LIST, response.data.data)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [ADD_LIST_ITEM] (state, data) {
    state.list.push(data)
  },
  [SET_UPDATED_LIST_ITEM] (state, data) {
    const index = state.list.find(e => e.id === data.id)
    Vue.set(state.list, index, data)
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [CHANGE_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
