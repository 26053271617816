// auth
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

// profile
export const FETCH_USER_DATA = 'FETCH_USER_DATA'
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'

// directions
export const GET_COURSES_TYPES = 'GET_COURSES_TYPES'
export const GET_COURSES_CATEGORIES = 'GET_COURSES_CATEGORIES'
export const GET_MODULES = 'GET_MODULES'

// files
export const UPLOAD = 'UPLOAD'
export const GET_LAST_UPLOAD_FILES = 'GET_LAST_UPLOAD_FILES'
