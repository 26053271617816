import Vue from 'vue'
import { uniq } from 'lodash'
import { $http } from '@/utils/https'
import {
  CHANGE_DIALOG_VIEW,
  CHANGE_LOADING,
  CHANGE_SKIP,
  CHANGE_USER_PERMISSIONS,
  GET_LIST_LOADING,
  REMOVE_LIST_ITEM,
  RESET_USER_PERMISSIONS,
  SET_CURRENT_SERVICE_PERMISSIONS,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH,
  SET_USER_PERMISSIONS
} from './types/mutation-types'

import { CREATE, DELETE, GET_LIST, GET_LIST_ITEM, UPDATE } from './types/action-types'

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: [],
  isListLoading: false,
  currentItem: null,
  isLoading: false,
  limit: 10,
  skip: 0,
  listLength: 0,
  userPermissions: {},

  openDialogView: false
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  isLoading: state => state.isLoading,
  isListLoading: state => state.isListLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  skip: state => state.skip,

  openDialogView: state => state.openDialogView,
  userPermissions: state => state.userPermissions,
}

const actions = {
  [GET_LIST]: async ({ commit }) => {
    try {
      commit(GET_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}roles?include=permissions&limit=${state.limit}&skip=${state.skip}`)

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
    } catch (e) {
      throw e
    } finally {
      commit(GET_LIST_LOADING, false)
    }
  },
  [GET_LIST_ITEM]: async ({ commit }, payload) => {
    try {
      commit(GET_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_USER}roles/${payload.id}?include=permissions`)

      commit(SET_ITEM, response.data.data)
      commit(SET_USER_PERMISSIONS, response.data.data.permissions.data)
    } catch (e) {
      throw e
    } finally {
      commit(GET_LIST_LOADING, false)
    }
  },
  [UPDATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}roles/${payload.id}?include=permissions`, payload)
      dispatch(GET_LIST)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [CREATE]: async ({ dispatch, commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      const response = await $http.post(`${process.env.VUE_APP_ROOT_USER}roles?include=permissions`, payload)
      dispatch(GET_LIST)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [DELETE]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LOADING, true)
      await $http.delete(`${process.env.VUE_APP_ROOT_USER}roles/${payload.id}`)
      commit(REMOVE_LIST_ITEM, payload)
      commit()
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  }
}

const mutations = {
  [SET_LIST] (state, list) {
    state.list = list
  },
  [CHANGE_LOADING] (state, status) {
    state.isLoading = status
  },
  [GET_LIST_LOADING] (state, status) {
    state.isListLoading = status
  },
  [SET_ITEM] (state, data) {
    state.currentItem = data
  },
  [SET_LIST_LENGTH] (state, length) {
    state.listLength = length
  },
  [REMOVE_LIST_ITEM] (state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  },
  [CHANGE_SKIP] (state, count) {
    state.skip = count
  },
  [RESET_USER_PERMISSIONS] (state, count) {
    state.userPermissions = {}
  },
  [SET_CURRENT_SERVICE_PERMISSIONS] (state, data) {
    if (data.key === 'add') {
      if (!state.userPermissions.hasOwnProperty(data.microservice)) {
        state.userPermissions[data.microservice] = data.ids
      } else {
        const arr = [...state.userPermissions[data.microservice], ...data.ids]
        const uniqArr = uniq(arr)
        Vue.set(state.userPermissions, data.microservice, uniqArr)
      }
    } else {
      const filteredArr = state.userPermissions[data.microservice].filter(e => data.ids.indexOf(e) < 0)
      Vue.set(state.userPermissions, data.microservice, filteredArr)
    }
  },
  [SET_USER_PERMISSIONS] (state, list) {
    list.forEach(data => {
      if (state.userPermissions.hasOwnProperty(data.microservice)) {
        state.userPermissions[data.microservice].push(data.id)
      } else {
        state.userPermissions[data.microservice] = [data.id]
      }
    })
  },
  [CHANGE_USER_PERMISSIONS] (state, data) {
    if (state.userPermissions.hasOwnProperty(data.microservice)) {
      if (data.isAddItem) {
        state.userPermissions[data.microservice].push(data.id)
      } else {
        const index = state.userPermissions[data.microservice].findIndex(e => e === data.id)
        Vue.delete(state.userPermissions[data.microservice], index)
      }
    } else {
      state.userPermissions[data.microservice] = [data.id]
    }
  },
  [CHANGE_DIALOG_VIEW] (state, status) {
    state.openDialogView = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
