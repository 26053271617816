import Vue from 'vue'
import { assign } from 'lodash';
import { $http } from '@/utils/https'
import {
  CHANGE_DIALOG_VIEW,
  CHANGE_LIST_LOADING,
  CHANGE_LOADING,
  CHANGE_SKIP,
  REMOVE_LIST_ITEM,
  SET_ITEM,
  SET_LIST,
  SET_LIST_LENGTH
} from './types/mutation-types'

import { CREATE, DELETE, GET_LIST, UPDATE } from './types/action-types';

const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  list: [],
  isLoading: false,
  isListLoading: false,
  currentItem: null,
  limit: 999,
  skip: 0,
  listLength: 0,
  openDialogView: false
}

const getters = {
  list: state => camelcaseObjectDeep(state.list),
  isLoading: state => state.isLoading,
  currentItem: state => camelcaseObjectDeep(state.currentItem),
  isListLoading: state => state.isListLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  skip: state => state.skip,
  openDialogView: state => state.openDialogView
}

const actions = {
  [GET_LIST]: async ({ commit }, payload) => {
    try {
      commit(CHANGE_LIST_LOADING, true)
      const response = await $http.get(`${process.env.VUE_APP_ROOT_COURSE}notifications/events?includes=notifications.template.translations`);

      commit(SET_LIST, response.data.data)
      commit(SET_LIST_LENGTH, response.data.meta.total)
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LIST_LOADING, false)
    }
  },

  [CREATE]: async ({ dispatch, commit }, formData) => {
    try {
      commit(CHANGE_LOADING, true);
      const response = await $http.post(`${process.env.VUE_APP_ROOT_TEMPLATE}templates`, formData);
      let resp = response.data.data;

      commit(SET_ITEM, resp);
      dispatch('SET_TEMPLATE_TO_COURSE', {
        courseId: formData.get('course_id'),
        formData: {
          template_id: resp.id,
          event: formData.get('key_origin')
        }
      });

      commit(CHANGE_LOADING, false);
      return resp;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },

  [UPDATE]: async ({ dispatch, commit }, formData) => {
    try {
      commit(CHANGE_LOADING, true);
      const response = await $http.post(`${process.env.VUE_APP_ROOT_TEMPLATE}templates/${formData.get('template_id')}`, formData);

      commit(CHANGE_LOADING, false);
      dispatch('coursesInfo/INFO_GET_COURSE', { id: formData.get('course_id') }, { root: true });
      commit(CHANGE_DIALOG_VIEW, false);
      return response;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },

  CREATE_TRANSLATIONS: async ({ dispatch, commit }, formData) => {
    try {
      commit(CHANGE_LOADING, true);
      const response = await $http.post(`${process.env.VUE_APP_ROOT_TEMPLATE}templates/${formData.get('template_id')}/translations`, formData);
      dispatch('coursesInfo/INFO_GET_COURSE', { id: formData.get('course_id') }, { root: true });
      commit(CHANGE_DIALOG_VIEW, false);
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },

  UPDATE_TRANSLATIONS: async ({ dispatch, commit }, formData) => {
    try {
      commit(CHANGE_LOADING, true);
      const response = await $http.post(`${process.env.VUE_APP_ROOT_TEMPLATE}templates/${formData.get('template_id')}/translations/${formData.get('id')}`, formData);
      dispatch('coursesInfo/INFO_GET_COURSE', { id: formData.get('course_id') }, { root: true });
      commit(CHANGE_DIALOG_VIEW, false);
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },

  SET_TEMPLATE_TO_COURSE: async ({ dispatch, commit }, { courseId, formData }) => {
    try {
      commit(CHANGE_LOADING, true);
      const response = await $http.post(`${process.env.VUE_APP_ROOT_COURSE}courses/${courseId}/notifications?include=template,notifications.template.translations`, formData);

      commit('SET_ITEM', assign(response.data.data, {
        isCustom: true,
      }));
      dispatch('coursesInfo/INFO_GET_COURSE', { id: courseId }, { root: true });
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },

  [DELETE]: async ({ commit, dispatch }, payload) => {
    try {
      commit(CHANGE_LOADING, true);

      await $http.delete(`${process.env.VUE_APP_ROOT_COURSE}courses/${payload.courseId}/notifications/${payload.id}`);
      dispatch('coursesInfo/INFO_GET_COURSE', { id: payload.courseId }, { root: true });
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  DELETE_EMAIL_TEMPLATE: async (payload) => {
    try {
      await $http.post(`${process.env.VUE_APP_ROOT_TEMPLATE}templates/${payload.template_id}`, {
        '_method': 'delete'
      });
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e
    } finally {
    }
  }
}

const mutations = {
  [SET_LIST](state, list) {
    state.list = list
  },
  [SET_ITEM](state, data) {
    state.currentItem = data
  },
  [CHANGE_LOADING](state, status) {
    state.isLoading = status
  },
  [CHANGE_LIST_LOADING](state, status) {
    state.isListLoading = status
  },
  [SET_LIST_LENGTH](state, length) {
    state.listLength = length
  },
  [CHANGE_SKIP](state, count) {
    state.skip = count
  },
  [CHANGE_DIALOG_VIEW](state, status) {
    state.openDialogView = status
  },
  [REMOVE_LIST_ITEM](state, params) {
    const index = state.list.findIndex(e => e.id === params.id)
    Vue.delete(state.list, index)
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
